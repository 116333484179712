import React, { useState, FormEvent, useEffect } from 'react';
import { HiOutlineMail, HiLockClosed, HiEye, HiEyeOff } from 'react-icons/hi';
import Logo from '../../images/logo.jpg';
import { useNavigate } from 'react-router-dom';
import { AxiosInstance } from '../../common/Loader/axios.config';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginResponse, setLoginResponse] = useState<any>(null); 
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loginError, setLoginError] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [accessError, setAccessError] = useState('');
  const navigate = useNavigate();

  const [providers, setProviders] = useState([]);
  const [providerEmails, setProviderEmails] = useState([]);

  const allowedUsers = [
    "noelmagulu4@gmail.com",
    "lkamangeni@icloud.com",
    "kamangenichikondi@gmail.com"
  ];
  
  // Combine emails and associate with user types
  const getUserType = (email) => {
    return allowedUsers.includes(email) ? "admin" : "provider";
  };
  
  useEffect(() => {
    AxiosInstance.get("/content-providers")
      .then((response) => {
        const allProviders = response.data;
  
        const sortedProviders = allProviders.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
  
        setProviders(sortedProviders);
  
        const providerEmails = sortedProviders
          .map((provider) => provider.email)
          .filter((email) => email);
  
        // Combine and deduplicate the emails
        const combinedEmails = [...new Set([...allowedUsers, ...providerEmails])];
        setProviderEmails(combinedEmails);
      })
      .catch((error) => {
        console.error("Error fetching providers:", error);
      });
  }, []);

  const goToHomePage = () => {
    navigate('/home');
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!providerEmails.includes(email)) {
      console.log("Unauthorized User")
      setAccessError("Your Account Is Not Authorized to Access This Site")
    } else {

      try {
        const response = await AxiosInstance.post(
          '/auth/login',
          {
            userid: email,
            password: password,
            // header: {},
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'x-user-type': 'admin'
            },
          }
        );

        console.log("Login Response", response);

        const data = response.data;

        if (response.status === 201 && data.status === "success") {
          let providerData = providers.find((p) => p.email === email);

          // If it's a predefined email, create a basic object
          if (!providerData) {
            providerData = { email, userType: "admin" };
          } else {
            providerData = { ...providerData, userType: "provider" };
          }

          console.log("Logged In User", providerData);
        
          // Save the user data with the userType to localStorage
          localStorage.setItem("provider", JSON.stringify(providerData));

          localStorage.setItem("accessToken", data.message.tk.accessToken);
          setAccessToken(data.message.tk.accessToken);
          setRefreshToken(data.message.tk.refresh_token);
          goToHomePage();
        } else {
          // If login fails, display error message
          setLoginError(data.message);
          console.error(data);
        }
      } catch (error) {
        console.error('Error:', error);
        setLoginError('An error occurred while logging in.');
      }

    }

    setIsLoading(false);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-black">
      <div className="m-auto w-full rounded-md bg-white p-6 lg:max-w-xl">
        <img src={Logo} alt="Company Logo" className="mx-auto mb-6 h-40 w-40" />
        <h1 className="text-purple-700 text-center text-xl font-semibold">
          {/* Sign in */}
        </h1>

        {accessError && (
          <div
            className={'mb-4 text-center text-danger'}
          >
            {accessError}
          </div>
        )}

        {loginError && (
          <div
            className={'mb-4 text-center text-danger'}
          >
            {loginError}
          </div>
        )}

        {/* Display login response */}
        {loginResponse && (
          <div
            className={`mb-4 ${loginResponse.error ? 'text-danger' : 'text-success'
              }`}
          >
            {loginResponse.error
              ? 'Login failed: ' + loginResponse.message
              : 'Login failed'}
          </div>
        )}

        {/* Login form */}
        <form className="mt-6" onSubmit={handleSubmit}>
          <div className="relative mb-2">
            <label
              htmlFor="email"
              className="text-gray-800 block text-sm font-semibold"
            >
              Email
            </label>
            <span className="text-gray-400 absolute left-3 top-8">
              <HiOutlineMail />
            </span>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="text-purple-700 focus:border-purple-400 focus:ring-purple-300 block w-full rounded-md border bg-white py-2 pl-10 pr-4 focus:outline-none focus:ring focus:ring-opacity-40"
              placeholder="Enter your email"
            />
          </div>

          <div className="relative mb-2">
            <label
              htmlFor="password"
              className="text-gray-800 block text-sm font-semibold"
            >
              Password
            </label>
            <span className="text-gray-400 absolute left-3 top-8">
              <HiLockClosed />
            </span>
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="text-purple-700 focus:border-purple-400 focus:ring-purple-300 block w-full rounded-md border bg-white py-2 pl-10 pr-12 focus:outline-none focus:ring focus:ring-opacity-40"
              placeholder="Enter password"
            />
            <span
              onClick={toggleShowPassword}
              className="text-purple-700 absolute right-4 top-8 cursor-pointer"
            >
              {showPassword ? <HiEye /> : <HiEyeOff />}
            </span>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className={`bg-purple-700 w-full transform rounded-md bg-black px-4 py-2 tracking-wide text-white transition-colors duration-200 ${isLoading
                ? 'cursor-not-allowed opacity-50'
                : 'hover:bg-purple-600 focus:bg-purple-600 focus:outline-none'
                }`}
              disabled={isLoading}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
