import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UploadState {
  thumbnail: string | null;
  uploadedFile: File | null;
  videoInfo: {
    name: string;
    description: string;
    category: string;
  };
  uploadProgress: number;
  screamKey: string | null;
  videoId: string; // New field for video id
  isLive: boolean; // New field for is live
  isTrending : boolean;
}

const initialState: UploadState = {
  thumbnail: null,
  uploadedFile: null,
  videoInfo: {
    name: '',
    description: '',
    category: '',
  },
  uploadProgress: 0,
  screamKey: null,
  videoId: '', // Initialize videoId as an empty string
  isLive: false, // Initialize isLive as false
  isTrending: false
};

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setThumbnail: (state, action: PayloadAction<string>) => {
      state.thumbnail = action.payload;
    },
    setUploadedFile: (state, action: PayloadAction<File | null>) => {
      state.uploadedFile = action.payload;
    },
    setVideoInfo: (
      state,
      action: PayloadAction<{
        name: string;
        description: string;
        category: string;
      }>
    ) => {
      state.videoInfo = action.payload;
    },
    setUploadProgress: (state, action: PayloadAction<number>) => {
      state.uploadProgress = action.payload;
    },
    setScreamKey: (state, action: PayloadAction<string | null>) => {
      state.screamKey = action.payload;
    },
    setVideoId: (state, action: PayloadAction<string>) => {
      state.videoId = action.payload;
    },
    setIsLive: (state, action: PayloadAction<boolean>) => {
      state.isLive = action.payload;
    },
    setisTrending: (state, action: PayloadAction<boolean>) => {
      state.isTrending = action.payload;
    },
    resetUploadState: (state) => {
      // Reset all fields to their initial values
      return initialState;
    },
  },
});

export const {
  setThumbnail,
  setUploadedFile,
  setVideoInfo,
  setUploadProgress,
  setScreamKey,
  setVideoId,
  setIsLive,
  setisTrending,
  resetUploadState, // Add the new action to reset the state
} = uploadSlice.actions;

export default uploadSlice.reducer;


// // uploadSlice.ts

// import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface UploadState {
//   thumbnailFile: File | null; // Store the File object
//   thumbnailUrl: string | null; // Store the URL for display
//   videoInfo: {
//     name: string;
//     description: string;
//     category: string;
//   };
//   uploadProgress: number;
// }

// const initialState: UploadState = {
//   thumbnailFile: null,
//   thumbnailUrl: null,
//   videoInfo: {
//     name: '',
//     description: '',
//     category: '',
//   },
//   uploadProgress: 0,
// };

// const uploadSlice = createSlice({
//   name: 'upload',
//   initialState,
//   reducers: {
//     setThumbnailFile: (state, action: PayloadAction<File>) => {
//       state.thumbnailFile = action.payload;
//       state.thumbnailUrl = null; // Clear the URL when setting a new File
//     },
//     setThumbnailUrl: (state, action: PayloadAction<string>) => {
//       state.thumbnailUrl = action.payload;
//       state.thumbnailFile = null; // Clear the File when setting a new URL
//     },
//     setVideoInfo: (
//       state,
//       action: PayloadAction<{
//         name: string;
//         description: string;
//         category: string;
//       }>
//     ) => {
//       state.videoInfo = action.payload;
//     },
//     setUploadProgress: (state, action: PayloadAction<number>) => {
//       state.uploadProgress = action.payload;
//     },
//   },
// });

// export const {
//   setThumbnailFile,
//   setThumbnailUrl,
//   setVideoInfo,
//   setUploadProgress,
// } = uploadSlice.actions;

// export default uploadSlice.reducer;




// import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface UploadState {
//   thumbnailFile: File | null;
//   thumbnailUrl: string | null;
//   videoInfo: {
//     name: string;
//     description: string;
//     category: string;
//   };
//   uploadProgress: number;
// }

// const initialState: UploadState = {
//   thumbnailFile: null,
//   thumbnailUrl: null,
//   videoInfo: {
//     name: '',
//     description: '',
//     category: '',
//   },
//   uploadProgress: 0,
// };

// const uploadSlice = createSlice({
//   name: 'upload',
//   initialState,
//   reducers: {
//     setThumbnailFile: (state, action: PayloadAction<File>) => {
//       state.thumbnailFile = action.payload;
//       state.thumbnailUrl = null; // Reset the URL when a new file is set
//     },
//     setThumbnailUrl: (state, action: PayloadAction<string>) => {
//       state.thumbnailUrl = action.payload;
//       state.thumbnailFile = null; // Reset the file when a new URL is set
//     },
//     setVideoInfo: (
//       state,
//       action: PayloadAction<{
//         name: string;
//         description: string;
//         category: string;
//       }>
//     ) => {
//       state.videoInfo = action.payload;
//     },
//     setUploadProgress: (state, action: PayloadAction<number>) => {
//       state.uploadProgress = action.payload;
//     },
//   },
// });

// export const {
//   setThumbnailFile,
//   setThumbnailUrl,
//   setVideoInfo,
//   setUploadProgress,
// } = uploadSlice.actions;

// export default uploadSlice.reducer;
