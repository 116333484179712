import React, { useEffect } from 'react';
import { useState } from 'react';
import { AxiosInstance } from '../../../common/Loader/axios.config';
import Swal from 'sweetalert2';
import Select from 'react-select';
import UserService from '../../../services/user-service';

const CreateProviderModal = ({ setIsCreating, getProviders }) => {
    const [name, setProviderName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [logo, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (e) => {
        try {
            setLoading(true);
            e.preventDefault();

            // Filter out undefined/null values
            const createdProvider = Object.fromEntries(
                Object.entries({
                    name,
                    email,
                    // phone,
                    logo,
                }).filter(([_, value]) => value !== undefined && value !== null)
            );

            // Create FormData and append values
            const formData = new FormData();
            for (const [key, value] of Object.entries(createdProvider)) {
                if (key === 'logo' && value instanceof File) {
                    formData.append(key, value);
                } else {
                    formData.append(key, value);
                }
            }

            if(phone){
                formData.append('phone', phone);
            }

            // console.log("Form Data:", [...formData.entries()]);

            const response = await AxiosInstance.post('/content-provider', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', 
                },
            });

            if (response.status === 201) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Provider created Successfully!',
                    showConfirmButton: false,
                    timer: 2000,
                });
                setIsCreating(false)
                getProviders()
            }

        } catch (error) {
            console.error("Error submitting form:", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const [selectedEmail, setSelectedEmail] = useState('')
    const [selectedAccount, setSelectedAccount] = useState('')
    const [users, setUsers] = useState([])

    useEffect(() => {
        async function fetchUsers() {
          try {
            const response = await UserService.getAllUsers();
            setUsers(response);
          } catch (error) {
            console.error("Error fetching users:", error);
          }
        }
      
        fetchUsers();
      }, []); 

      const accountOptions = users.map((user) => ({
        value: user.email,
        label: user.email,
      }));
      


    const handleAccountChange = (option) => {
        setSelectedAccount(option);
        setEmail(option.value);
        setSelectedEmail(option.value);
    };


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="flex flex-col gap-4 items-center justify-center bg-boxdark p-6 rounded-lg text-white shadow-xl" style={{ width: '600px' }}>
                <p>Create Provider</p>
                <form>
                    <div className="flex flex-col gap-x-4 w-full py-2">


                        <div className="flex gap-x-3 items-center justify-start">

                            <div className="bg-black rounded-xl p-10">
                                <input
                                    type="file"
                                    className="opacity-0 absolute"
                                    onChange={handleFileChange}
                                />
                                <span>Logo</span>
                            </div>

                            <div className="w-[40vh] flex flex-col items-center">
                                <input
                                    type="text"
                                    name="Name"
                                    placeholder="Provider Name"
                                    className="my-2 w-full rounded-lg border border-white bg-transparent px-4 py-2"
                                    value={name}
                                    onChange={(e) => setProviderName(e.target.value)}
                                />

                                <div className="w-full text-black text-sm border border-brand-stroke-color rounded-lg p-2" id="select">

                                    <Select
                                        value={selectedAccount}
                                        onChange={handleAccountChange}
                                        options={accountOptions}
                                        placeholder="Select Account..."
                                    />
                                </div>

                                {/* <input
                                    type="text"
                                    name="email"
                                    placeholder="E-mail"
                                    className="my-2 w-full rounded-lg border border-white bg-transparent px-4 py-2"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                /> */}
                            </div>
                        </div>
                        <br />
                    </div>
                    <div className="grid grid-cols-2 gap-4">

                        <div className="col-span-2">
                            <input
                                type="text"
                                name="price"
                                placeholder="Phone Number"
                                className="my-2 w-full rounded-lg border border-white bg-transparent px-4 py-2 text-md font-normal"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                    </div>
                    <hr className="border-gray-300 my-4 border-t-2 border-dashed" />

                    <div className="flex items-center justify-end">

                        <div className='flex items-center justify-center gap-x-4'>
                            <div className='cursor-pointer hover:scale-105' onClick={() => setIsCreating(false)}>Cancel</div>

                            <button className="border-[2px] border-brand-stroke-color bg-brand-red px-4 py-2 my-4 text-white rounded-xl px-4 items-center justify-center"
                                onClick={handleSubmit}>
                                {loading ? 'Creating...' : 'Create'}
                            </button>
                        </div>


                    </div>


                </form>
            </div>
        </div>
    )
}

export default CreateProviderModal