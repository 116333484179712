import { useState, useEffect } from 'react'
import { AxiosInstance } from '../../common/Loader/axios.config';
import EventService from '../../services/event-service';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export function ShowLiveEvents({ setCreateLive, setManageLive, setShowEvents, setSelectedEvent }) {

    const [liveEvents, setLiveEvents] = useState([])
    const [loading, setLoading] = useState(false)
    const category = "events";

    const getLiveEvents = async () => {
        setLoading(true);
        try {
            const fetchedEvents = await EventService.getEvents();
            const liveEvents = fetchedEvents.filter(event => event.status.live === true);

            setLiveEvents(liveEvents)

            setLoading(false)


        } catch (error) {
            setLoading(false);
        } finally {

        }
    }

    useEffect(() => {
        getLiveEvents();
    }, []);

    return (
        <div className='w-full flex flex-col mx-auto bg-brand-background py-4 px-8 rounded-xl'>
            <div className='w-full flex flex-col mx-auto rounded-xl bg-transparent p-4 border border-brand-stroke-color my-2'>
                <p className='text-lg text-white text-center py-2'>Find Your Other Live Events Here</p>

                <div className='flex items-center justify-between gap-x-4 overflow-x-auto'>

                    {loading ? (
                        <div className='flex flex-col justify-center items-center mx-auto my-4 gap-y-2'>
                            <CircularProgress />
                            <p className='text-center'>Getting Events...</p>
                        </div>
                    )

                        : (
                            liveEvents.length > 0 ? (
                                liveEvents.map((event) => (
                                    <div className='w-1/2 flex flex-col mx-auto p-3 rounded-xl border border-brand-stroke-color'>
                                        <div className='relative w-full bg-transparent border border-brand-stroke-color rounded-xl h-64'>
                                            <img
                                                src={event.thumb_nail}
                                                alt="Video"
                                                className='w-full h-full object-cover rounded-lg'
                                            />

                                            <div className='absolute top-2 right-2 bg-white bg-opacity-80 rounded-full p-2'>
                                                <img
                                                    src="/live.png"
                                                    alt="Live"
                                                    className='w-8 h-8'  
                                                />
                                                {/* <p>Live</p> */}
                                            </div>
                                        </div>


                                        <p className='text-lg text-white text-center py-2'>{event.name}</p>
                                        <p className='text-sm text-white text-center'>{event.description}</p>
                                        <p className='text-sm text-white text-center'>MWK {event.price}</p>

                                        <button className='p-2 bg-white text-black text-md rounded-full mx-auto hover:scale-105 mt-3 mb-6'
                                            onClick={() => {
                                                setSelectedEvent(event)
                                                setManageLive(true);
                                                setShowEvents(false);
                                            }}>
                                            Manage Event
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p className='text-lg text-brand-red text-center py-2 mx-auto'>No live events at the moment.</p>
                            )
                        )

                    }



                    {/* <div className='w-1/2 flex flex-col mx-auto p-3 rounded-xl border border-brand-stroke-color'>
                        <div className='w-full bg-transparent border border-brand-stroke-color rounded-xl h-64'></div>
                        <p className='text-lg text-white text-center py-2'>Event Name</p>

                        <button className='p-2 bg-white text-black text-md rounded-full mx-auto hover:scale-105 mb-6'>
                            Manage Event
                        </button>
                    </div> */}
                </div>

            </div>

            <button className='p-2 bg-white text-black text-md rounded-full mx-auto hover:scale-105 my-4'
                onClick={() => {
                    setCreateLive(true)
                    setShowEvents(false)
                }}>
                Create New Live Event
            </button>
        </div>
    )
}