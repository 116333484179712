import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import VodService from "../../services/vods-service";
import EventService from "../../services/event-service";
import Select from 'react-select';
import dateTime from 'date-and-time';
import Swal from "sweetalert2";
import { AxiosInstance } from "../../common/Loader/axios.config";

const ExistingUpload = forwardRef((props, ref) => {

    const {setUploadStatus} = props;
    const {setProgress} = props;

    interface Content {
        _id: string;
        name: string;
        description?: string;
        price: number;
        video_provider: string;
        project_name: string;
        city: string;
        region: string;
        category: string;
        created_at: Date;
        views: number;
        thumb_nail: string;
        video_url: string;
        trailer: string;
        start_time: string;

    }
    const [allContent, setAllContent] = useState([])
    const [selectedContent, setSelectedContent] = useState<Content | null>(null);
    const [selectedVideo, setSelectedVideo] = useState('')
    const [thumbnail, setThumbnail] = useState<File | null>(null);

    useEffect(() => {
        async function fetchAllContent() {
            try {
                const [fetchedVods, fetchedEvents] = await Promise.all([
                    VodService.getVods(),
                    EventService.getEvents(),
                ]);

                // Combine the results as needed and set them to `allContent`
                setAllContent([...fetchedVods, ...fetchedEvents]);
            } catch (error) {
                console.error("Error fetching content:", error);
            }
        }

        fetchAllContent();
    }, []);

    const options = allContent.map((content) => ({
        value: content,
        label: content.name,
    }));

    const handleChange = (selectedOption: { value: Content; label: string }) => {
        setSelectedContent(selectedOption.value);
        setSelectedVideo(selectedOption.value.name)
        console.log("Selected Video Name", selectedOption.value.name)
    };

    useImperativeHandle(ref, () => ({
        handleSubmit,
    }));

    const handleSubmit = async (file) => { 
        // e.preventDefault();
        if (selectedContent) {

            if (!file || !thumbnail) {
                Swal.fire({
                  icon: 'error',
                  title: 'Missing Files',
                  text: 'Please upload both a video file and a thumbnail before submitting.',
                });
                return; 
              }

            setUploadStatus("inProgress");

            const formData = new FormData();

            // Append form fields
            formData.append("name", selectedContent.name);
            formData.append("region", selectedContent.region);
            formData.append("description", selectedContent.description);
            formData.append("category", selectedContent.category);
            formData.append("video_provider", selectedContent.video_provider);
            formData.append("project_name", selectedContent.project_name);
            formData.append("price", selectedContent.price);

            if (selectedContent.start_time) {
                formData.append("start_time", selectedContent.start_time);
            }

            if (selectedContent.city) {
                formData.append("start_time", selectedContent.city);
            }

            // Append files from state
            if (file) {
                formData.append("video", file);
            }

            if (thumbnail) {
                formData.append("thumbnail", thumbnail);
            }

            if (selectedContent.trailer) {
                formData.append("trailer", selectedContent.trailer);
            }

            for (const [key, value] of formData.entries()) {
                if (value instanceof File) {
                    console.log(`${key}:`, value.name, value.size, value.type);
                } else {
                    console.log(`${key}: ${value}`);
                }
            }

            try {
              const response = await AxiosInstance.post("/cf-upload", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(percentCompleted);
                },
              });

              console.log("Existing Response", response);

              if (response.status === 201) {
                if (response.data.status === "success") {
                  setUploadStatus("success");
                  console.log("Upload successful:", response.data.message);
                  Swal.fire({
                    icon: 'success',
                    title: '!',
                    showConfirmButton: false,
                    timer: 2000,
                  });
                } else {
                  setUploadStatus("failure");
                  console.error("Upload failed:", response.data.message);
                }
              } else {
                setUploadStatus("failure");
                console.error("Upload failed with status:", response.status);
              }
            } catch (error) {
              console.error("Error uploading data:", error);
            }
        } else {
            console.log("No content selected");
            Swal.fire({
                icon: 'error',
                title: 'Missing Content',
                text: 'Please Select an existing Video before submitting.',
              });
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
    
        if (selectedFile && selectedContent?.name) {
          const fileExtension = selectedFile.name.split('.').pop();
          const newFileName = `${selectedContent?.name}.${fileExtension}`;
          const renamedFile = new File([selectedFile], newFileName, {
            type: selectedFile.type,
          });
    
          setThumbnail(renamedFile);
        }
      };

    return (
        <div>
            <div className="text-black">
                <p className="text-white">Existing Video</p>
                <Select
                    value={selectedVideo}
                    onChange={handleChange}
                    options={options}
                    placeholder="Select Video..."
                />
            </div>

            {selectedContent && (
                <div className="my-3 overflow-y-scroll h-[620px]">
                    <p className="text-white my-2">Video Details</p>
                    <div>
                        <div className='w-full h-[330px] rounded-lg border border-brand-stroke-color'>

                            <img src={selectedContent?.thumb_nail} alt="Video" className='w-full h-full object-cover rounded-lg' />
                        </div>

                        <div className='p-4'>
                      <p className='text-lg text-white py-2'>Thumbnail</p>
                      <input
                        className='w-[75%] rounded-lg p-4 bg-transparent border border-brand-red my-2'
                        type='file'
                        id='thumbnailInput'
                        onChange={(e) => {
                          handleFileChange(e);
                        }}
                      />
                    </div>


                        <div className="flex items-center justify-between gap-x-4 my-2">
                            <div className="w-1/2">
                                <p>Name:</p>
                                <input type="text"
                                    name="name"
                                    className="w-full bg-transparent border border-stroke-color rounded-xl p-2"
                                    value={selectedContent?.name}
                                    readOnly
                                    disabled
                                />
                            </div>
                            <div className="w-1/2">
                                <p>Price:</p>
                                <input type="text"
                                    name="name"
                                    className="w-full bg-transparent border border-stroke-color rounded-xl p-2"
                                    value={selectedContent?.price}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="w-full mb-2">
                            <p>Description:</p>
                            <input type="text"
                                name="name"
                                className="w-full bg-transparent border border-stroke-color rounded-xl p-2"
                                value={selectedContent?.description}
                                readOnly
                                disabled
                            />
                        </div>

                        <div className="flex items-center justify-between gap-x-4 mb-2">
                            <div className="w-1/2">
                                <p>Video Provider:</p>
                                <input
                                    type="text"
                                    name="name"
                                    className="w-full bg-transparent border border-stroke-color rounded-xl p-2"
                                    value={selectedContent?.video_provider}
                                    readOnly
                                    disabled
                                />
                            </div>
                            <div className="w-1/2">
                                <p>Project Name:</p>
                                <input type="text"
                                    name="name"
                                    className="w-full bg-transparent border border-stroke-color rounded-xl p-2"
                                    value={selectedContent?.project_name}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between gap-x-4 mb-2">
                            <div className="w-1/2">
                                <p>Region:</p>
                                <input type="text"
                                    name="name"
                                    className="w-full bg-transparent border border-stroke-color rounded-xl p-2"
                                    value={selectedContent?.region}
                                    readOnly
                                    disabled
                                />
                            </div>

                            <div className="w-1/2">
                                <p>Created:</p>
                                <input type="text"
                                    name="name"
                                    className="w-full bg-transparent border border-stroke-color rounded-xl p-2"
                                    value={dateTime.format(new Date(Date.parse(selectedContent?.created_at)), 'DD/MM/YY HH:mm')}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between gap-x-4 mb-2">
                            <div className="w-1/2">
                                <p>Category:</p>
                                <input type="text"
                                    name="name"
                                    className="w-full bg-transparent border border-stroke-color rounded-xl p-2"
                                    value={selectedContent?.category}
                                    readOnly
                                    disabled
                                />
                            </div>
                            <div className="w-1/2">
                                <p>Views:</p>
                                <input type="text"
                                    name="name"
                                    className="w-full bg-transparent border border-stroke-color rounded-xl p-2"
                                    value={selectedContent?.views}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <p>Video Url:</p>
                            <input type="text"
                                name="name"
                                className="w-full bg-transparent border border-stroke-color rounded-xl p-2"
                                value={selectedContent?.video_url}
                                readOnly
                                disabled
                            />
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}
)

export default ExistingUpload;