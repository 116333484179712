import API_BASE_URL from './apiConfig';
import authHeader from './auth-header';
import TV from './interfaces/tv-interface';
import { AxiosInstance } from '../common/Loader/axios.config';

class TVsService {
  private static getHeaders(): Headers {
    const headers = new Headers();
    const token = localStorage.getItem('accessToken');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    console.log(headers);
    return headers;
  }

  private static async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    }

    return response.text();
  }

  static async getAllTVs(): Promise<TV[]> {
    const headers = this.getHeaders();
    const options: RequestInit = { method: 'GET', headers };
    // const response = await fetch(`${API_BASE_URL}/tvs`, options);
    const response = await AxiosInstance.get('/tvs');
    console.log('TVS Response', response);
    const responseData = response.data;
    return responseData;
  }

  static async getTV(tvId: string): Promise<TV> {
    const headers = this.getHeaders();
    const options: RequestInit = { method: 'GET', headers };
    const response = await fetch(`${API_BASE_URL}/tvs/${tvId}`, options);
    const responseData = await this.handleResponse(response);
    return responseData;
  }

  static async createTV(newTV: TV): Promise<TV> {
    const headers = this.getHeaders();
    const options: RequestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify(newTV),
    };
    const response = await fetch(`${API_BASE_URL}/tvs`, options);
    const responseData = await this.handleResponse(response);
    return responseData;
  }

  static async updateTV(tvId: string, updatedTV: TV): Promise<TV> {
    const headers = this.getHeaders();
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(updatedTV),
    };
    const response = await fetch(`${API_BASE_URL}/tvs/${tvId}`, options);
    const responseData = await this.handleResponse(response);
    return responseData;
  }

  static async deleteTV(tvId: string): Promise<void> {
    const headers = this.getHeaders();
    const options: RequestInit = { method: 'DELETE', headers };
    const response = await fetch(`${API_BASE_URL}/tvs/${tvId}`, options);
    await this.handleResponse(response);
  }
}

export default TVsService;
