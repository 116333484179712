import authHeader from './auth-header';
import Vod from './interfaces/vods-interface';
import { AxiosInstance } from '../common/Loader/axios.config';

const token = authHeader();

class VodService {
  // private static readonly API_BASE_URL = 'https://kanemaonline.com/clone/';
  private static readonly API_BASE_URL = 'https://kanema.staticcomputersinc.com/api';
  private static readonly ACCESS_TOKEN = localStorage.getItem('accessToken');

  private static async fetchWithAuthorization<T>(
    url: string,
    options?: RequestInit
  ): Promise<T> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      ...(options?.headers || {}),
    };

    console.log('Fetching data from:', `${this.API_BASE_URL}${url}`);

    // const response = await fetch(`${this.API_BASE_URL}${url}`, {
    //   ...options,
    //   headers,
    // });

    const response = await AxiosInstance.get(`${url}`);

    // console.log("New Endpoint",response.data)

    if (response.status != 200) {
      console.error('Request failed with status:', response.status); // Log error if request fails
      throw new Error(`Request failed with status ${response.status}`);
    }

    return response.data as Promise<T>;
  }

  static async getVods(): Promise<Vod[]> {
    try {
      console.log('Fetching VODs...'); // Log before fetching VODs
      return this.fetchWithAuthorization<Vod[]>('/vods/');
    } catch (error) {
      console.error('Error fetching VODs:', error); // Log error if fetching VODs fails
      throw error;
    }
  }

  static async createVod(vodData: Vod): Promise<Vod> {
    try {
      console.log('Creating VOD...'); // Log before creating VOD
      return this.fetchWithAuthorization<Vod>('/vods/', {
        method: 'POST',
        body: JSON.stringify(vodData),
      });
    } catch (error) {
      console.error('Error creating VOD:', error); // Log error if creating VOD fails
      throw error;
    }
  }

  static async updateVod(vodId: string, vodData: Partial<Vod>): Promise<Vod> {
    try {
      console.log('Updating VOD...'); // Log before updating VOD
      return this.fetchWithAuthorization<Vod>(`/vods/${vodId}`, {
        method: 'PUT',
        body: JSON.stringify(vodData),
      });
    } catch (error) {
      console.error('Error updating VOD:', error); // Log error if updating VOD fails
      throw error;
    }
  }

  static async deleteVod(vodId: string): Promise<void> {
    try {
      console.log('Deleting VOD...'); // Log before deleting VOD
      await this.fetchWithAuthorization<void>(`/vods/${vodId}`, {
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Error deleting VOD:', error); // Log error if deleting VOD fails
      throw error;
    }
  }
}

export default VodService;
