import { useEffect, useState, useRef } from "react";
import { AxiosInstance } from "../../../common/Loader/axios.config";
import dateTime from 'date-and-time';
import Select from 'react-select';
import { FaArrowCircleRight } from "react-icons/fa";
import PaymentModal from "./PaymentModal";
import { set } from "react-hook-form";
import { CiExport } from "react-icons/ci";
import * as XLSX from 'xlsx';
import ExportModal from "./ExportModal";
import CircularProgress from '@mui/material/CircularProgress';
import PaymentFields from "./payment_fields";

const TABLE_HEAD = [
    'Date',
    'User Name',
    'User ID',
    'Reference',
    'Package',
    'Amount',
    'Gateway',
    'Operator',
    'Status',
    ''
];

interface Payment {
    created_at: string;
    username: string;
    userid: string;
    mobile_operator: string;
    phone: string;
    email: string;
    _id: string;
    product: string;
    amount: number;
    currency: string;
    payment_gateway: string;
    status: string;
    failure_reason: string;
}

interface PaymentResponse {
    data: Payment[];
}
const PaymentSummary = ({ loggedInProvider, tvs }) => {

    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [paymentList, setPaymentList] = useState<Payment[]>([]);
    const [products, setProducts] = useState<string[]>([]);
    const [gateway, setGateway] = useState<string[]>([]);
    const [status, setStatus] = useState<string[]>([]);
    const [operator, setOperator] = useState<string[]>([]);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [selectedGateway, setSelectedGateway] = useState<string | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [selectedOperator, setSelectedOperator] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [filteredPayments, setFilteredPayments] = useState<Payment[]>([]);
    const [allPayments, setAllPayments] = useState(0);
    const [paymentCount, setPaymentCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [rejectedCount, setRejectedCount] = useState(0);
    const [closedCount, setClosedCount] = useState(0);
    const [totalSum, setTotalSum] = useState(0);

    const [exportModal, setExportModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');


    const handleArrowClick = (payment) => {
        setSelectedPayment(payment);
        setModalOpen(true);
    };

    const [isAdmin, setIsAdmin] = useState(false)
    const [isTV, setIsTV] = useState(false);

    const getAdminStatus = () => {
        if (loggedInProvider) {
            // Check if the provider is an admin
            if (loggedInProvider.userType === "admin") {
                setIsAdmin(true);
            } else if (loggedInProvider.id === "8b1fa3") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
    
            // Check if the user is a provider or has a matching TV
            if (loggedInProvider.userType === "provider" || loggedInProvider.id !== "8b1fa3") {
                // Make sure content exists and is an array before calling .some()
                if (loggedInProvider.content && Array.isArray(loggedInProvider.content)) {
                    const hasMatchingTV = loggedInProvider.content.some((contentItem) =>
                        tvs.some((tv) => tv.name === contentItem)
                    );
                    setIsTV(hasMatchingTV);
                } else {
                    setIsTV(false); // Set to false if content is undefined or not an array
                }
            }
        }
    };
    



    const getPayments = () => {

        console.log("Provider in Summary", loggedInProvider);
        console.log("Admin in Summary", isAdmin);
        console.log("TV in Summary", isTV);

        setLoading(true);
        AxiosInstance.get<PaymentResponse>('/billing/payments')
            .then((response) => {
                const allPayments = response.data;
                const sortedPayments = allPayments.sort((a, b) => {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });

                setAllPayments(sortedPayments.length);

                const successfulPayments: Array<any> = sortedPayments.filter(
                    (e) => e["status"] == "COMPLETED"
                );

                const failedPayments: Array<any> = sortedPayments.filter(
                    (e) => e["status"] == "FAILED"
                );

                const pendingPayments: Array<any> = sortedPayments.filter(
                    (e) => e["status"] == "PENDING"
                );

                const rejectedPayments: Array<any> = sortedPayments.filter(
                    (e) => e["status"] == "REJECTED"
                );

                const closedPayments: Array<any> = sortedPayments.filter(
                    (e) => e["status"] == "CLOSED"
                );

                const totalSum = sortedPayments.reduce((acc, pay) => acc + parseFloat(pay.amount), 0);

                let basePayments;

                if (isAdmin) {
                   basePayments = sortedPayments;
                } else if (loggedInProvider && loggedInProvider?.content) {
                    basePayments = sortedPayments.filter((payment) =>
                        loggedInProvider.content.includes(payment.product))

                    if (isTV) {
                        const kiliyekiliyePayments = sortedPayments.filter(
                            (payment) => payment.product === "KiliyeKiliye"
                        );
                        basePayments = [...basePayments, ...kiliyekiliyePayments];
                    }
                    setLoading(false);
                } else {
                    basePayments = [];
                }

                const sortBasePayments = basePayments.sort((a, b) => {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                })

                setPaymentList(sortBasePayments);
                setPayments(sortBasePayments);

                setPaymentCount(successfulPayments.length);
                setFailedCount(failedPayments.length);
                setPendingCount(pendingPayments.length);
                setRejectedCount(rejectedPayments.length);
                setClosedCount(closedPayments.length);

                // setPayments(sortedPayments);
                setFilteredPayments(sortedPayments);

                const uniqueProducts = [...new Set(sortedPayments.map((payment) => payment.product))];
                setProducts(uniqueProducts);

                const uniqueGateway = [...new Set(sortedPayments.map((payment) => payment.payment_gateway))];
                setGateway(uniqueGateway);

                const uniqueStatus = [...new Set(sortedPayments.map((payment) => payment.status))];
                setStatus(uniqueStatus);

                const uniqueOperator = [...new Set(sortedPayments.map((payment) => payment.mobile_operator))];
                setOperator(uniqueOperator);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getAdminStatus();
    }, [loggedInProvider, tvs]); 
    
    useEffect(() => {

            getPayments();
        
    }, [isAdmin, isTV]);  

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredPayments(payments);
        } else {
            const filtered = payments.filter((payment) => {
                return payment.payment_reference.toLowerCase().includes(searchTerm.toLowerCase());
            });
            setFilteredPayments(filtered);
        }
    }, [searchTerm, payments]);

    const options = [
        { value: 'all', label: 'All' },
        ...products.map((product) => ({
            value: product,
            label: product,
        })),
    ];

    const gatewayOptions = [
        { value: 'all', label: 'All' },
        ...gateway.map((gateway) => ({
            value: gateway,
            label: gateway,
        })),
    ];

    const statusOptions = [
        { value: 'all', label: 'All' },
        ...status.map((status) => ({
            value: status,
            label: status,
        })),
    ];

    const operatorOptions = [
        { value: 'all', label: 'All' },
        ...operator.map((operator) => ({
            value: operator,
            label: operator,
        })),
    ];


    const handleChange = (option) => {
        setSelectedOption(option);
        if (option.value === 'all') {
            setFilteredPayments(payments);
            setPayments(paymentList);
        } else {
            const filtered = payments.filter((payment) => payment.product === option.value);
            setPayments(filtered);
            setFilteredPayments(filtered);
        }
    };

    const handleGatewayChange = (option) => {
        setSelectedGateway(option);
        if (option.value === 'all') {
            setFilteredPayments(payments);
            setPayments(paymentList);
        } else {
            const filtered = payments.filter((payment) => payment.payment_gateway === option.value);
            setPayments(filtered);
            setFilteredPayments(filtered);
        }
    };

    const handleStatusChange = (option) => {
        setSelectedStatus(option);
        if (option.value === 'all') {
            setFilteredPayments(payments);
            setPayments(paymentList);
        } else {
            const filtered = payments.filter((payment) => payment.status === option.value);
            setPayments(filtered);
            setFilteredPayments(filtered);
        }
    };

    const handleOperatorChange = (option) => {
        setSelectedOperator(option);
        if (option.value === 'all') {
            setFilteredPayments(payments);
            setPayments(paymentList);
        } else {
            const filtered = payments.filter((payment) => payment.mobile_operator === option.value);
            setPayments(filtered);
            setFilteredPayments(filtered);
        }
    };

    const [columnWidths, setColumnWidths] = useState({
        Date: 70,
        User_Name: 150,
        Payer_ID: 150,
        Payment_Reference: 150,
        Package: 50,
        Amount: 50,
        Gateway: 50,
        Status: 50,
        UserID: 150,
        Subscription_reference: 150,
        Operator: 50,
        Action: 50,
    });

    const resizeHandleRef = useRef(null);

    const [selectedFields, setSelectedFields] = useState([
        'Date',
        'User_Name',
        'Payer_ID',
        'Payment_Reference',
        'Package',
        'Amount',
        'Gateway',
        'Status',
    ]);

    // Handle mousedown on the resize handle
    const onMouseDown = (e, columnName) => {
        e.preventDefault();
        const startX = e.clientX;
        const startWidth = columnWidths[columnName];

        let animationFrameId;

        const onMouseMove = (e) => {
            // Cancel the previous frame
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }

            // Request a new animation frame
            animationFrameId = requestAnimationFrame(() => {
                const newWidth = Math.max(50, startWidth + (e.clientX - startX));
                setColumnWidths((prevWidths) => ({
                    ...prevWidths,
                    [columnName]: newWidth,
                }));
            });
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            // Cancel any pending animation frame
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const handleFieldChange = (updatedFields) => {
        setSelectedFields(updatedFields);
    };

    return (
        <div className="w-full ">
            {/* <div className='flex justify-end items-center gap-x-2'>
                <p className='text-sm text-white'>Check Payment Status: </p>
                <input type='text' name='search' placeholder='Enter Payment Reference' className='rounded-md text-sm border border-stroke bg-transparent px-4 py-3 shadow-default dark:border-strokedark dark:bg-black w-64' />
            </div> */}

            <div className="flex justify-between items-center my-3">
                <div className="text-black border border-brand-stroke-color rounded-lg p-1" id="select">

                    <PaymentFields
                        options={Object.keys(columnWidths).filter((key) => key !== 'Action')}
                        label="Select Fields"
                        selectedOptions={selectedFields}
                        onChange={handleFieldChange}
                    />
                </div>

                <div className='flex items-center gap-x-2'>
                    <p className='text-sm text-white'>Search Payment: </p>
                    <input
                        type='text'
                        name='search'
                        placeholder='Enter Payment Reference'
                        className='rounded-md text-sm border border-stroke bg-transparent px-4 py-3 shadow-default dark:border-strokedark dark:bg-black w-64'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>

            <div className="w-full mb-3 rounded-md border border-stroke bg-white dark:bg-brand-background px-5 pb-6 pt-6 shadow-default dark:border-strokedark dark:bg-black">
                <div>
                    <p className="text-sm">Search Filters</p>
                </div>
                <div className="flex flex-row gap-4">
                    <div className="w-full text-black text-sm border border-brand-stroke-color rounded-lg p-2" id="select">

                        <Select
                            value={selectedOption}
                            onChange={handleChange}
                            options={options}
                            placeholder="Select Package..."
                        />
                    </div>
                    <div className="w-full text-black text-sm border border-brand-stroke-color rounded-lg p-2" id="select">
                        <Select
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            options={statusOptions}
                            placeholder="Select Status..."
                        />
                    </div>

                    <div className="w-full text-black text-sm border border-brand-stroke-color rounded-lg p-2" id="select">

                        <Select
                            value={selectedGateway}
                            onChange={handleGatewayChange}
                            options={gatewayOptions}
                            placeholder="Select Gateway..."
                        />
                    </div>

                    <div className="w-full text-black text-sm border border-brand-stroke-color rounded-lg p-2" id="select">

                        <Select
                            value={selectedOperator}
                            onChange={handleOperatorChange}
                            options={operatorOptions}
                            placeholder="Select Operator..."
                        />
                    </div>

                </div>
            </div>
            <div className="flex items-center justify-between">
                <div className='flex items-center gap-x-4'>
                    <div className='text-white text-xs rounded border border-white px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                        onClick={() => handleStatusChange({ value: 'all' })}>All: {allPayments}</div>
                    <div className='text-success text-xs rounded border border-success px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                        onClick={() => handleStatusChange({ value: 'COMPLETED' })}>Completed: {paymentCount}</div>
                    <div className='text-warning text-xs rounded border border-warning px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                        onClick={() => handleStatusChange({ value: 'PENDING' })}>Pending: {pendingCount}</div>
                    <div className='text-warning text-xs rounded border border-warning px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                        onClick={() => handleStatusChange({ value: 'ACCEPTED' })}>Accepted: {pendingCount}</div>
                    <div className='text-danger text-xs rounded border border-danger px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                        onClick={() => handleStatusChange({ value: 'FAILED' })}>Failed: {failedCount}</div>
                    <div className='text-danger text-xs rounded border border-danger px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                        onClick={() => handleStatusChange({ value: 'REJECTED' })}>Rejected: {rejectedCount}</div>
                    <div className='text-danger text-xs rounded border border-danger px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                        onClick={() => handleStatusChange({ value: 'CLOSED' })}>Closed: {closedCount}</div>

                </div>
                <div className='flex gap-x-2 items-center justify-center rounded bg-white px-4 py-2 rounded-lg w-fit my-2 cursor-pointer hover:scale-105'
                    onClick={() => setExportModal(true)}>
                    <CiExport size={20} color="black" />
                    <p className="text-black text-md">Export</p>
                </div>

            </div>

            <div className="overflow-x-auto">

                <table className="table-auto w-full bg-brand-background shadow-md rounded-lg">
                    <thead>
                        <tr className="text-white text-sm text-center">
                            {selectedFields.map((columnName) => (
                                <th
                                    key={columnName}
                                    className="p-4 relative border-r border-brand-stroke-color"
                                    style={{ width: `${columnWidths[columnName]}px` }}
                                >
                                    {columnName}
                                    <div
                                        ref={resizeHandleRef}
                                        className="resize-handle absolute right-0 top-0 h-full w-2 cursor-col-resize"
                                        onMouseDown={(e) => onMouseDown(e, columnName)}
                                    />
                                </th>
                            ))}
                            <th
                                key="Action"
                                className="p-4 relative border-r border-brand-stroke-color"
                                style={{ width: `${columnWidths['Action']}px` }}
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan={8} className="py-4 text-center">
                                    <CircularProgress />
                                </td>
                            </tr>
                        ) : (
                            filteredPayments.map((payment, index) => (

                                <tr key={payment._id} className="hover:bg-gray-100 text-white text-xs text-center">
                                    {selectedFields.map((columnName) => (
                                        <td key={columnName} className="py-4 px-1">
                                            {columnName === 'Date' &&
                                                dateTime.format(new Date(Date.parse(payment.created_at)), 'DD/MM/YY HH:mm ')}
                                            {columnName === 'User_Name' && payment.username}
                                            {columnName === 'Payer_ID' && payment.payerid}
                                            {columnName === 'Payment_Reference' && payment.payment_reference}
                                            {columnName === 'Subscription_reference' && payment.subscription_reference}
                                            {columnName === 'Package' && payment.product}
                                            {columnName === 'Amount' && payment.currency + " " + payment.amount}
                                            {columnName === 'Gateway' && payment.payment_gateway}
                                            {columnName === 'Operator' && payment.mobile_operator}
                                            {columnName === 'UserID' && payment.userid}
                                            {columnName === 'Status' && (
                                                <p
                                                    className={
                                                        payment.status === 'COMPLETED'
                                                            ? 'text-success'
                                                            : payment.status === 'PENDING' || payment.status === 'ACCEPTED'
                                                                ? 'text-warning'
                                                                : payment.status === 'FAILED' || payment.status === 'CLOSED' || payment.status === 'REJECTED'
                                                                    ? 'text-brand-red'
                                                                    : 'text-white'
                                                    }
                                                >
                                                    {payment.status}
                                                </p>
                                            )}

                                        </td>

                                    ))}
                                    <td key="Action" className="py-4 px-1" onClick={() => handleArrowClick(payment)}>
                                        <FaArrowCircleRight className="ml-6" size={18} />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>

            </div>




            {
                modalOpen && (
                    <PaymentModal isOpen={modalOpen} payment={selectedPayment} setIsOpen={setModalOpen} />
                )
            }

            {exportModal && (
                <ExportModal filteredPayments={filteredPayments} setExportModal={setExportModal} setFilteredPayments={setFilteredPayments} />
            )}
        </div >
    )
}


export default PaymentSummary;