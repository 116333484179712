import type { AxiosResponse } from 'axios';
import { AxiosInstance } from '../../common/Loader/axios.config';
import { RingLoader } from 'react-spinners';
import '../../styles/LiveComponent.css';
import Select from 'react-select';
import { FaRegCopy } from "react-icons/fa6";
import ReactPlayer from 'react-player';
import { IoMdArrowRoundBack } from "react-icons/io";
import { ShowLiveEvents } from './ShowLiveEvents';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';


export function ManageLive({ setManageLive, setShowEvents, selectedEvent }) {
    var videoBaseUrl = 'https://customer-ghyc6vhia0xmwm0q.cloudflarestream.com/';
    const [streamKey, setStreamKey] = useState('')
    const [streamUrl, setStreamUrl] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [projectName, setProjectName] = useState('')
    const [trailer, setTrailer] = useState('')
    const [price, setPrice] = useState('')
    const [previewVideoUID, setPreviewVideoUid] = useState('')
    const [isLive, setIsLive] = useState(false)
    const [providers, setProviders] = useState([]);
    const [video_provider, setVideoProvider] = useState('');
    const [liveId, setliveId] = useState('');
    const [isEndingLive, setIsEndingLive] = useState(false)
    const [goLiveLoading, setGoLiveLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState('');
    const [fileName, setFile] = useState<File | null>(null);

    useEffect(() => {
        if (selectedEvent) {
            setTitle(selectedEvent.name);
            setDescription(selectedEvent.description);
            setProjectName(selectedEvent.project_name);
            setTrailer(selectedEvent.trailer);
            setPrice(selectedEvent.price);
            setStreamKey(selectedEvent.stream_key);
            setStreamUrl(selectedEvent.stream_url);
            setIsLive(selectedEvent.status.live);
            setPreviewVideoUid(selectedEvent.video_url);
            setliveId(selectedEvent._id);
            console.log("Seleceted Event", selectedEvent);
        }
    }, [selectedEvent]);


    useEffect(() => {
        AxiosInstance.get("/content-providers")
            .then((response) => {
                const allProviders = response.data;

                console.log("All Providers", allProviders);

                const sortedProviders = allProviders.sort((a, b) => {
                    return (
                        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                    );
                });

                setProviders(sortedProviders)
            })
            .catch((error) => {
            });
    }, []);

    const providerOptions = providers.map((provider) => ({
        value: provider.id,
        label: provider.name,
    }));

    const [selectedProvider, setSelectedProvider] = useState(null);

    const handleProviderChange = (option) => {
        setSelectedProvider(option);
        setVideoProvider(option.value);
    };

    const goLive = async () => {
        setGoLiveLoading(true);
        try {
            await AxiosInstance.put(`/${liveId}/content`, {
                'status.live': true,
                // 'status.visibility': true,
            }).then((response: AxiosResponse) => {
                console.log("Live Status", response.data.status);
                if (response.data.status == 'success') {
                    setGoLiveLoading(false);
                    setIsLive(true);

                    Swal.fire({
                        icon: 'success',
                        title: 'Event is now Live!',
                        showConfirmButton: false,
                        timer: 2000,
                      });

                    const name = response.data.message.name;
                    const content = response.data.message._id;

                    // sendNotification(name, content);

                } else if (response.data.status == 'unsuccesful') {
                    setGoLiveLoading(false);
                    //   setGoingLiveSubmitMessage(
                    //     'Event failed to go Live. Please try again'
                    //   );
                    alert(`Failed to go live: ${response.data.message}`);
                } else {
                    setGoLiveLoading(false);
                    //   setGoingLiveSubmitMessage(
                    //     'Event failed to go Live. Please try again'
                    //   );
                    alert(`Failed to go live: ${response.data.message}`);
                }
            });
        } catch (err: any) {
            alert(err.message);
        }
    };

    const sendNotification = async (name, contentId) => {
        const body = {
            title: `${name}`,
            body: `Watch ${name} Live Now!`,
            contentid: `${contentId}`
        }
        // console.log("Notification Data", body);

        const response = await AxiosInstance.post('/unified-messaging/fcm', body);

        if (response.status == 200) {
            console.log("Notification Sent", response)
            Swal.fire({
                icon: 'success',
                title: 'Notification Sent to app',
                showConfirmButton: false,
                timer: 2000,
            });
        } else {
            console.log("Failed to send notification", response)
        }
    }

    const endLive = async () => {
        setIsEndingLive(true);

        try {
            await AxiosInstance.put(`/${liveId}/content`, {
                'status.live': false,
            }).then((response: AxiosResponse) => {
                if (response.data.status == 'success') {
                    setIsEndingLive(false);
                    setIsLive(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Live Event Ended!',
                        showConfirmButton: false,
                        timer: 2000,
                      });
                } else if (response.data.status == 'unsuccesful') {
                    setIsEndingLive(false);
                    alert(`Failed to end live: ${response.data.message}`);
                }
            });
        } catch (err: any) {
            setIsEndingLive(false);
            alert(`Failed to end live: ${err.message}`);
        }
    };

    const [titleError, setTitleError] = useState("");

    const handleTitleChange = (e) => {
        const value = e.target.value;

        if (value.length > 21) {
            setTitleError("Title Cannot Exceed 22 Characters");

        } else {
            setTitle(e.target.value);

        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile && formData.title) {
            const fileExtension = selectedFile.name.split('.').pop();
            const newFileName = `${formData.title}.${fileExtension}`;
            const renamedFile = new File([selectedFile], newFileName, {
                type: selectedFile.type,
            });

            setFile(renamedFile);
        }
    };

    return (
        <div className="content-wrapper">
            <div className="stream-container">
                <IoMdArrowRoundBack className='my-3 cursor-pointer hover:scale-110'
                    onClick={() => {
                        setManageLive(false)
                        setShowEvents(true)
                    }}
                    size={20}
                    color='white' />
                <div className="preview ">
                    {streamKey == '' ? (
                        <p className="py-20">Connect streaming software to go live</p>
                    ) : (
                        // <iframe
                        //   title="Video Preview"
                        //   src={`${videoBaseUrl}${previewVideoUID ?? streamKey}/watch`}
                        //   frameBorder={0}
                        // ></iframe>
                        <ReactPlayer
                            url={`${videoBaseUrl}${previewVideoUID ?? streamKey}/watch`}
                            controls
                            playing
                            width="100%"
                            height="100%"
                        />
                    )}
                </div>

                <div className="my-3 rounded-lg border-[1px] border-brand-stroke-color px-5 py-3 text-start">
                    <p className='text-sm'>Stream Key</p>
                    <div className='flex items-center justify-center'>
                        <input
                            type="text"
                            placeholder="Stream Key"
                            disabled
                            value={streamKey}
                            readOnly
                        />
                        <button
                            className="copy-button"
                            onClick={() => {
                                navigator.clipboard.writeText(streamKey);
                                alert('Copied to Clipboard');
                            }}
                        >
                            {' '}
                            <FaRegCopy />
                            {/* <img src="/svg/copy.svg" alt="" /> */}
                        </button>
                    </div>
                </div>
                {/* <p className="py-3 text-sm text-brand-inactive-color">Stream Url</p> */}

                <div className="my-2 rounded-lg border-[1px] border-brand-stroke-color px-5 py-3 text-start">
                    <p className='text-sm'>Stream Url</p>
                    <div className='flex items-center justify-center'>
                        <input
                            type="text"
                            placeholder="Stream Url"
                            disabled
                            value={streamUrl}
                            readOnly
                        />
                        <button
                            className="copy-button"
                            onClick={() => {
                                navigator.clipboard.writeText(streamUrl);
                                alert('Copied to Clipboard');
                            }}
                        >
                            {' '}
                            <FaRegCopy />
                            {/* <img src="/svg/copy.svg" alt="" /> */}
                        </button>
                    </div>
                </div>

                <div className="action-buttons ">
                    {isLive ? (
                        <button className="rounded-full border-[2px] border-white px-10 py-2">
                            Edit Details
                        </button>
                    ) : (
                        <button className="rounded-full border-[2px] border-white px-10 py-2">
                            Previous
                        </button>
                    )}

                    {isLive ? (
                        <button
                            className="go-live active rounded-full border-[2px] border-white px-10 py-2"
                            onClick={endLive}
                        >
                            {' '}
                            {isEndingLive ? 'Ending Live ...' : 'End Live'}
                        </button>
                    ) : (
                        <button
                            className={`go-live rounded-full border-[2px] border-white px-10 py-2 ${streamKey ? 'active' : ''
                                }`}
                            onClick={goLive}
                            disabled={streamKey == ''}
                        >
                            {' '}
                            {goLiveLoading ? 'Going Live ...' : 'Go Live'}
                        </button>
                    )}
                </div>
            </div>

            <div className="details-container ">
                {loading ? (
                    <div className="flex h-full items-center justify-center">
                        <div className="text-center">
                            {/* <Jumper size="60" color="#FF3E00" unit="px" duration="1s" /> */}
                            <RingLoader size={70} color={'#36D7B7'} loading={loading} />

                            <p className="text-sm font-medium opacity-75">Submiting</p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h1 className='my-3'>Details</h1>

                        <div className="other-details-container ">
                            <div>
                                <p>Title</p>
                                <div className="field-input-container">
                                    <input
                                        type="text"
                                        name=""
                                        id=""
                                        className=" w-full bg-transparent px-2 py-3 text-white placeholder:text-sm placeholder:text-brand-inactive-color focus:border-none active:border-none"
                                        placeholder="Title"
                                        value={title}
                                        maxLength={22}
                                        onChange={(e) => handleTitleChange(e)}
                                    // onChange={(e) =>
                                    //   setFormData({ ...formData, title: e.target.value })
                                    // }
                                    />
                                    {titleError && <p className="error text-brand-red py-1">{titleError}</p>}
                                </div>
                            </div>

                            <div>
                                <p>Description</p>
                                <div className="field-input-container">
                                    <input
                                        type="text"
                                        className=" w-full bg-transparent px-2 py-3 text-white placeholder:text-sm placeholder:text-brand-inactive-color focus:border-none active:border-none"
                                        name=""
                                        id=""
                                        placeholder="Description"
                                        value={description}
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div className='flex items-center justify-between gap-x-2'>

                                <div className='w-1/2'>
                                    <p>Package Name</p>
                                    <div className="field-input-container text-black py-1">
                                        {/* <Select
                              value={selectedPackage}
                              onChange={handlePackageChange}
                              options={packageOptions}
                              placeholder="Select Package..."
                            /> */}
                                    </div>
                                </div>

                                <div className='w-1/2'>
                                    <p>Video Provider</p>
                                    <div className="w-full text-black border border-brand-stroke-color rounded-lg p-2 my-2" id="select">
                                        <Select
                                            value={selectedProvider}
                                            onChange={handleProviderChange}
                                            options={providerOptions}
                                            placeholder="Select Provider..."
                                        />
                                    </div>
                                </div>

                            </div>

                            <div>
                                <p>Project Name</p>
                                <div className="field-input-container">
                                    <input
                                        type="text"
                                        name=""
                                        id=""
                                        className=" w-full bg-transparent px-2 py-3 text-white placeholder:text-sm placeholder:text-brand-inactive-color focus:border-none active:border-none"
                                        placeholder="Project Name"
                                        value={projectName}
                                        onChange={(e) =>
                                            setProjectName(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div>
                                <p>Trailer</p>
                                <div className="field-input-container">
                                    <input
                                        type="text"
                                        name=""
                                        id=""
                                        className=" w-full bg-transparent px-2 py-3 text-white placeholder:text-sm placeholder:text-brand-inactive-color focus:border-none active:border-none"
                                        placeholder="Trailer"
                                        value={trailer}
                                        onChange={(e) =>
                                            setTrailer(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="flex items-center gap-x-2 justify-between rounded-lg border-2 border-brand-stroke-color px-3 py-3 ">
                                <div className="flex flex-col gap-y-1 thumbnail w-1/3">
                                    <p>Thumbnail</p>
                                    <input
                                        id="file-upload"
                                        name="file-upload"
                                        type="file"
                                        className=""
                                        placeholder="Upload File"
                                        onChange={(e) => {
                                            handleFileChange(e);
                                        }}
                                    />
                                </div>

                                <div className='text-white w-1/3'>
                                    <p>Publish Date</p>
                                    <div className="">
                                        <input
                                            type="datetime-local"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            className="w-full p-2 rounded bg-transparent border border-brand-stroke-color text-white"
                                        />
                                    </div>
                                </div>

                                {/*  */}
                                <div className="rounded-lg border-[1.5px] border-brand-stroke-color py-3 text-center">
                                    <span>Price</span>
                                    <div className="price-selector flex h-[80%] items-center justify-between gap-x-2">
                                        <input
                                            type="number"
                                            className="w-[80px] bg-transparent text-center"
                                            name=""
                                            id=""
                                            placeholder="Price"
                                            value={price}
                                            onChange={(e) => {
                                                setPrice(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                {/*  */}
                            </div>
                        </div>

                        <div className="my-3"></div>
                        <div className="flex justify-center">
                            <button
                                className="upload-button align-middle"
                            // onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}