import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Rectangle from '../../../src/images/Rectangle 1354.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { resetUploadState, setIsLive } from '../../store/uploadSlice';
import ManageLiveEventService from './ManageLiveEventService';
import Swal from 'sweetalert2'; // Import SweetAlert
import VideoPlayer from './VideoPlayer';
import { useState } from 'react';
import { isLoggedIn } from '../../services/isLoggedInService';

const Live: React.FC = () => {
  const navigate = useNavigate();

  const [isPlaying, setIsPlaying] = useState(false);
  // Replace with your video URL

  React.useEffect(() => {
    const userIsLoggedIn = isLoggedIn();

    if (!userIsLoggedIn) {
      // If the user is not logged in, navigate to the sign-in page
      navigate('/auth/signin');
    }
  }, [navigate]);

  const handlePlayVideo = () => {
    setIsPlaying(!isPlaying);
  };

  const videoInfo = useSelector((state: RootState) => state.upload.videoInfo);
  const thumbnailUrl = useSelector(
    (state: RootState) => state.upload.thumbnail
  );

  const [isCopied, setIsCopied] = React.useState(false);
  const [streamKey, setStreamKey] = React.useState('');

  const manageLiveEventService = new ManageLiveEventService();
  const dispatch = useDispatch(); // Get the dispatch function from React-Redux

  // Retrieve the videoId from the Redux store
  const videoId = useSelector((state: RootState) => state.upload.videoId);
  const isLive = useSelector((state: RootState) => state.upload.isLive);
  const isTrending = useSelector((state: RootState) => state.upload.isTrending);

  // Get the screamKey outside the useEffect
  const retrievedStreamKey = useSelector(
    (state: RootState) => state.upload.screamKey
  );

  React.useEffect(() => {
    if (retrievedStreamKey) {
      const parts = retrievedStreamKey.split('/');
      const lastPart = parts[parts.length - 1];
      const streamKey2 = lastPart.replace('.m3u8', '');
      setStreamKey(streamKey2);
    }
  }, [retrievedStreamKey]); // Add retrievedStreamKey to the dependencies array

  const handleCopy = () => {
    // Create a text area element to copy the text
    const textArea = document.createElement('textarea');
    textArea.value = streamKey;

    // Append it to the document
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    document.execCommand('copy');

    // Clean up and show "Copied!" message
    document.body.removeChild(textArea);
    setIsCopied(true);

    // Reset "Copied!" message after a while (optional)
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleEndEvent = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to end the live event?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked "Yes," proceed with ending the event
        console.log('Event ended  successfully.');
        // Set isLive to false in the Redux store
        dispatch(setIsLive(false));
        // Perform any additional actions or update your UI as needed.
        dispatch(resetUploadState());
        Swal.fire({
          icon: 'success',
          title: 'Event ended  successfully',
          showConfirmButton: false,
          timer: 2000, // Auto-close after 2 seconds
        });
        navigate('/GoLive');
      }
      // If the user clicked "Cancel," no action is taken.
    });
  };

  const handlePromoteToTrending = async () => {
    try {
      await manageLiveEventService.promoteToTrending(videoId);

      // You can perform additional actions or updates after a successful promotion.
    } catch (error) {
      // Handle any errors if the promotion fails.
    }
  };

  const handleDePromoteFromTrending = async () => {
    try {
      await manageLiveEventService.depromoteToTrending(videoId);

      // You can perform additional actions or updates after a successful promotion.
    } catch (error) {
      // Handle any errors if the promotion fails.
    }
  };

  return (
    <>
      <div className="h-full py-20 dark:bg-[#233040]">
        <div className="p-10">
          <center>
            <span
              className="m-10 text-white"
              style={{ fontSize: '24px', fontWeight: 'bold' }}
            >
              Live Page
            </span>
          </center>
        </div>
        <div className="flex justify-center">
          <form className="mx-auto p-10">
            <div className="space-y-12">
              <div className="pb-12">
                <div className="flex grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <div className="">
                      <div className="rounded-xl border border-black px-2 py-2 dark:border-gray">
                        {isPlaying && isLive && retrievedStreamKey ? (
                          <VideoPlayer videoUrl={retrievedStreamKey} />
                        ) : thumbnailUrl !== null ? (
                          <img
                            className=""
                            src={thumbnailUrl}
                            alt="Thumbnail"
                          />
                        ) : null}
                      </div>
                      <div className="grid grid-cols-2 gap-4 p-10">
                        <button
                          onClick={() => handleEndEvent()}
                          type="button"
                          className={`bg-transparant rounded-full border ${
                            isLive
                              ? 'border-danger text-black hover:bg-danger'
                              : 'border-gray-300 cursor-not-allowed text-gray'
                          } px-8 py-2 ${isLive ? '' : 'dark:text-gray'}`}
                          disabled={!isLive}
                        >
                          <span className={isLive ? 'text-gray' : 'text-gray'}>
                            {isLive ? 'End Live' : 'There is no Live Event'}
                          </span>
                        </button>

                        {/* Add the play button */}
                        {isLive ? (
                          <button
                            onClick={() => handlePlayVideo()}
                            type="button"
                            className={`bg-transparant border-green rounded-full border px-8 py-2 text-white hover:bg-black hover:text-white`}
                          >
                            {isPlaying ? 'Pause' : 'Play'}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-center gap-6">
                    <div className="container mb-2 rounded-md border border-black px-4 py-4 dark:border-gray">
                      <p className="text-xl font-medium text-black dark:text-white">
                        Visibility & Access
                      </p>
                      <p className="mt-4 text-sm leading-5 text-black dark:text-white">
                        Name: {videoInfo.name}
                      </p>
                      <p className="mt-4 text-sm leading-5 text-black dark:text-white">
                        Description: {videoInfo.description}
                      </p>
                      <p className="mt-4 text-sm leading-5 text-black dark:text-white">
                        Category: {videoInfo.category}
                      </p>
                      <p>
                        <div className="w-7/8 relative my-6">
                          <label className="text-green dark:text-green mb-2.5 block">
                            STREAM KEY
                          </label>
                          <div className="flex items-center">
                            <input
                              type="text"
                              placeholder="Stream@kanemaonline.com"
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent px-2 py-3 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                              value={streamKey}
                              onChange={(e) => setStreamKey(e.target.value)}
                            />

                            <button
                              type="button"
                              className="hover:text-primary-dark absolute right-2 top-3/4 -translate-y-1/2 transform cursor-pointer text-white focus:outline-none"
                              onClick={handleCopy}
                            >
                              {isCopied ? (
                                <>
                                  <svg
                                    className="mr-1 inline h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M5 13l4 4L19 7"
                                    />
                                  </svg>
                                  Copied!
                                </>
                              ) : (
                                <svg
                                  className="inline h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 5h6a2 2 0 012 2v10a2 2 0 01-2 2H9a2 2 0 01-2-2V7a2 2 0 012-2z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 3h4a2 2 0 012 2v4M10 14L21 3"
                                  />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </p>
                    </div>
                    {isLive && (
                      <>
                        <button
                          onClick={
                            isTrending
                              ? handleDePromoteFromTrending
                              : handlePromoteToTrending
                          }
                          type="button"
                          className=" bg-transparant mt-4 rounded-full border border-success px-8 py-2 text-black hover:bg-success dark:text-white"
                        >
                          {isTrending
                            ? 'Depromote from Trending'
                            : 'Promote to Trending'}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Live;
