import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Live from './GoLiveManage';
import { setIsLive, setScreamKey, setVideoId } from '../../store/uploadSlice';

interface Metadata {
  [key: string]: string;
}

const LiveStreamElement: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const videoInfo = useSelector((state: RootState) => state.upload.videoInfo);
  const thumbnailUrl = useSelector(
    (state: RootState) => state.upload.thumbnail
  );
  const uploadedFile = useSelector(
    (state: RootState) => state.upload.uploadedFile
  );
  const targetDiv = document.getElementById('my-div-two');
  const bodyClass = document.getElementById('body-class');
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [count, setCount] = useState(10);

  const hundleback = async () => {
    if (bodyClass) {
      bodyClass.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      // Append the Redux dispatched data to the form data
      formData.append('name', videoInfo.name);
      // formData.append('description', videoInfo.description);

      if (uploadedFile) {
        formData.append('files', uploadedFile);
      }

      const response = await axios.post(
        'https://kanemaonline.com/events/new-live-event',
        formData
      );

      console.log('Event created:', response.data);
      var streamKey = response.data.message.stream_key;
      var videoId = response.data.message._id;

      // console.log('response.data:', streamKey);
      // console.log('videoId:', videoId);

      dispatch(setScreamKey(streamKey));
      dispatch(setVideoId(videoId));
      dispatch(setIsLive(true));

      // const response = await axios.post('https://kanemaonline.com/events/new-live-event', formData);
      // console.log('Event created:', response.data);
      // var streamKey = response.data.message.stream_key;
      // console.log('response.data:', streamKey);
      // localStorage.setItem('streamKey', streamKey);

      // navigate('/Live');

      const result = await Swal.fire({
        title: 'Event Created',
        html: 'Your event has been successfully created!<br>Going live in <b id="countdown">10</b> seconds...',
        timer: 10000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
          const countdownElement = document.getElementById(
            'countdown'
          ) as HTMLElement;
          if (countdownElement) {
            const interval = setInterval(() => {
              const countdown = parseInt(countdownElement.textContent!, 10); // Type assertion here
              if (countdown > 1) {
                countdownElement.textContent = (countdown - 1).toString();
              } else {
                clearInterval(interval);
                Swal.close(); // Close the alert after the countdown
                if (targetDiv) {
                  targetDiv.scrollIntoView({ behavior: 'smooth' });
                }
              }
            }, 1000);
          }
        },
      });
    } catch (error) {
      console.error('Error uploading data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (showCounter) {
      interval = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }

    return () => clearInterval(interval); // Clean up the interval on unmount or when showCounter changes
  }, [showCounter]);

  useEffect(() => {
    if (count === 0) {
      setShowCounter(false);
      Swal.fire({
        icon: 'success',
        title: 'We are now live!',
        text: 'Your live stream is up and running.',
        timer: 3000, // Auto-close after 3 seconds
      });
    }
  }, [count]);

  console.log(videoInfo);
  console.log(thumbnailUrl);
  console.log(uploadedFile);

  const handleCopy = () => {
    const inputElement = document.querySelector(
      'input[type="url"]'
    ) as HTMLInputElement;
    if (inputElement) {
      inputElement.select();
      document.execCommand('copy');
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset copy status after 2 seconds
    }
  };

  return (
    <>
      <div className=" h-full py-10 dark:bg-[#233040]" id="body-class">
        <div className="p-10">
          <center>
            <span
              className="m-10 text-white"
              style={{ fontSize: '24px', fontWeight: 'bold' }}
            >
              Live Event details preview
            </span>
          </center>
        </div>
        <div className="flex justify-center">
          <form className="mx-auto p-10">
            <div className="space-y-12">
              <div className="pb-12">
                <div className="flex grid grid-cols-2 gap-4">
                  <div className=" flex flex-col gap-6">
                    <div className="w-3/4">
                      <label className="mb-2.5 block text-black dark:text-white">
                        Event name
                      </label>
                      <input
                        value={videoInfo.name}
                        type="text"
                        placeholder="Video name"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>

                    <div className="w-3/4">
                      <label className="mb-2.5 block text-black dark:text-white">
                        Event details
                      </label>
                      <input
                        value={videoInfo.description}
                        type="text"
                        placeholder="Event details"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>

                    <div className="relative w-3/4">
                      <label className="mb-2.5 block text-black dark:text-white">
                        Stream url
                      </label>
                      <div className="flex items-center">
                        <input
                          type="url"
                          placeholder=""
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent px-2 py-3 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                          value={'rtmp://kanemaonline.com:1935/live'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <span className="my-20 text-white">Event picture </span>
                    <div className="w-110 col-span-3 h-70 rounded border-[1.5px] border-stroke bg-transparent   px-5 py-3">
                      {thumbnailUrl ? (
                        <img
                          src={thumbnailUrl}
                          alt="Thumbnail Preview"
                          className=" w-108 h-60 rounded-lg"
                        />
                      ) : (
                        <>
                          <label
                            htmlFor="file-upload"
                            className="focus-within:ring-indigo-600 relative cursor-pointer rounded-md font-semibold text-black focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 hover:text-success dark:text-white"
                          >
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                        </>
                      )}
                    </div>

                    <div className="mt-2 flex items-center justify-center">
                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="col-span-1 items-center justify-center ">
                          <div className="mt-4 flex flex-row items-center justify-center gap-4 ">
                            <Link to="/GoLive">
                              <div className="flex items-center justify-center">
                                <button
                                  onClick={hundleback}
                                  type="submit"
                                  className="mt-4 flex w-full items-center justify-between rounded-full border bg-black px-8 py-2 hover:bg-success"
                                >
                                  <span className="px-2"> Previous </span>
                                  {/* <svg
                                    data-name="1.Arrow Up"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    className="ml-2 h-6 w-6 bg-white" // Apply bg-white class to set white background
                                    height="20px"
                                    width="24px"
                                    fill="black" // Set fill property to black
                                  >
                                    <path d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z" />
                                    <path d="M15.293 11.707 12 8.414l-3.293 3.293-1.414-1.414L12 5.586l4.707 4.707-1.414 1.414z" />
                                    <path d="M11 7h2v11h-2z" />
                                  </svg> */}
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.11727 10.5246C6.693 10.8782 6.63567 11.5088 6.98924 11.933C7.3428 12.3573 7.97336 12.4146 8.39764 12.0611L7.11727 10.5246ZM12.0001 7.7573L12.6403 6.98908C12.2695 6.68004 11.7308 6.68004 11.3599 6.98908L12.0001 7.7573ZM15.6026 12.0611C16.0269 12.4146 16.6575 12.3573 17.011 11.933C17.3646 11.5088 17.3073 10.8782 16.883 10.5246L15.6026 12.0611ZM11.0001 16.2426C11.0001 16.7949 11.4478 17.2426 12.0001 17.2426C12.5524 17.2426 13.0001 16.7949 13.0001 16.2426L11.0001 16.2426ZM8.39764 12.0611L12.6403 8.52552L11.3599 6.98908L7.11727 10.5246L8.39764 12.0611ZM11.3599 8.52552L15.6026 12.0611L16.883 10.5246L12.6403 6.98908L11.3599 8.52552ZM11.0001 7.7573L11.0001 16.2426L13.0001 16.2426L13.0001 7.7573L11.0001 7.7573ZM5.91898 5.91888C9.27749 2.56037 14.7227 2.56037 18.0812 5.91888L19.4954 4.50467C15.3559 0.365111 8.64432 0.365112 4.50477 4.50467L5.91898 5.91888ZM18.0812 5.91888C21.4397 9.27739 21.4397 14.7226 18.0812 18.0811L19.4954 19.4953C23.635 15.3558 23.635 8.64422 19.4954 4.50467L18.0812 5.91888ZM18.0812 18.0811C14.7227 21.4396 9.27749 21.4396 5.91898 18.0811L4.50477 19.4953C8.64432 23.6349 15.3559 23.6349 19.4954 19.4953L18.0812 18.0811ZM5.91898 18.0811C2.56047 14.7226 2.56047 9.27739 5.91898 5.91888L4.50477 4.50467C0.365208 8.64423 0.365209 15.3558 4.50477 19.4953L5.91898 18.0811Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </Link>
                            {/* <Link to="/Live"> */}
                            <div className="flex items-center justify-center">
                              <button
                                onClick={onSubmit} // Pass the reference to onSubmit without invoking it
                                type="button"
                                className="mt-4 w-50 rounded-full border bg-danger px-4 py-2 text-white hover:bg-success"

                                // className="w-full bg-black border border-danger text-white px-8 py-2 mt-4 rounded-full hover:bg-danger"
                              >
                                Go Live
                              </button>
                            </div>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div id="my-div-two">
          <Live />
        </div>
      </div>
      {showCounter &&
        Swal.fire({
          title: 'Going Live Soon',
          html: `
            <div class="dark:bg-[#233040] bg-white rounded-xl">
              <div class="text-center text-2xl font-bold mt-6">
                Going live in ${count} seconds...
              </div>
            </div>
          `,
          showConfirmButton: false,
        })}
    </>
  );
};

export default LiveStreamElement;
