import { useState, useEffect } from 'react';
import { AxiosInstance } from '../common/Loader/axios.config';
import { margin } from '@mui/system';
import VodService from '../services/vods-service';
import EventService from '../services/event-service';
import TVsService from '../services/tvs-service';
import Select from 'react-select';
import { set } from 'react-hook-form';


const Notifications = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    body: '',
    contentId: '',
    deviceId: '',
  });

  const [allContent, setAllContent] = useState([]);
  const [selectedContent, setSelectedContent] = useState<string | null>(null);


  interface NotificationResponse {
    message: string;
  }

  useEffect(() => {
    async function fetchAllContent() {
      try {
        const [fetchedVods, fetchedEvents, fetchedTVs] = await Promise.all([
          VodService.getVods(),
          EventService.getEvents(),
          TVsService.getAllTVs(),
        ]);

        // Combine the results as needed and set them to `allContent`
        setAllContent([...fetchedVods, ...fetchedEvents, ...fetchedTVs]);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    }

    fetchAllContent();
  }, []);

  const options = allContent.map((content) => ({
    value: content._id,
    label: content.name,
  }));

  const handleChange = (option) => {
    setFormData({ ...formData, contentId: option.value });
    console.log(option);
};

  const sendNotification = async ({
    title,
    body,
    contentId,
    deviceId,
  }: {
    title: string;
    body: string;
    contentId: string;
    deviceId: string;
  }) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('body', body);
    formData.append('contentid', contentId);
    formData.append('deviceId', deviceId);

    console.log('Form Data');
for (const [key, value] of formData.entries()) {
  console.log(`${key}: ${value}`);
}

    const response = await AxiosInstance.post<NotificationResponse>(
      '/unified-messaging/fcm',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    // console.log('Response', response.data.message);

    if (response.status == 201 && response.data.message == 'success') {
      return { status: 'success' };
    } else {
      return { status: 'error' };
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await sendNotification({
        title: formData.title,
        body: formData.body,
        contentId: formData.contentId,
        deviceId: formData.deviceId,
      });
      setLoading(false);
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  return (
    <main className="py-10 flex items-center justify-center">
      <div className="w-full max-w-[900px] rounded-lg border-[1.5px] border-brand-stroke-color bg-brand-background p-10">
        <h2 className="text-md px-8 py-4 font-semibold text-white">
          Send Notifications
        </h2>
        <div className="w-full px-10">
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <input
                type="text"
                name="title"
                id="title"
                className="block w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-2 py-2 placeholder:text-sm placeholder:text-brand-inactive-color"
                placeholder="Title"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
              <input
                type="text"
                name="message"
                id="message"
                className="block w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-2 py-2 placeholder:text-sm placeholder:text-brand-inactive-color"
                placeholder="Message"
                value={formData.body}
                onChange={(e) =>
                  setFormData({ ...formData, body: e.target.value })
                }
              />
              {/* <input
                type="text"
                name="contentId"
                id="contentId"
                className="block w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-2 py-2 placeholder:text-sm placeholder:text-brand-inactive-color"
                placeholder="Content Id"
                value={formData.contentId}
                onChange={(e) =>
                  setFormData({ ...formData, contentId: e.target.value })
                }
              /> */}

              <Select
                value={formData.contentId}
                onChange={handleChange}
                options={options}
                placeholder="Select Content..."
              />


              <input
                type="text"
                name="deviceId"
                id="deviceId"
                className="block w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-2 py-2 placeholder:text-sm placeholder:text-brand-inactive-color"
                placeholder="Device Id"
                value={formData.deviceId}
                onChange={(e) =>
                  setFormData({ ...formData, deviceId: e.target.value })
                }
              />
              <button className="my-2 rounded-lg bg-brand-red px-4 py-2 text-white">
                {loading ? 'Sending...' : 'Send Notification'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>


  );
};

export default Notifications;
