import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LiveEvent from '../components/Videos/LiveEvent';
import Video from '../components/Videos/Video';
import TVs from '../components/Videos/TVs';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../services/isLoggedInService';
import { useEffect, useState } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Videos() {
  const [value, setValue] = React.useState(0);

   const navigate = useNavigate();

   React.useEffect(() => {
     const userIsLoggedIn = isLoggedIn();

     if (!userIsLoggedIn) {
       // If the user is not logged in, navigate to the sign-in page
       navigate('/auth/signin');
     }
   }, [navigate]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [loggedInProvider, setLoggedInProvider] = useState(null);

  useEffect(() => {
    const storedProvider = JSON.parse(localStorage.getItem("provider"));
    if (storedProvider) {
      setLoggedInProvider(storedProvider);
    }
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs textColor='inherit' value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Live Events" {...a11yProps(0)} />
          <Tab label="Videos" {...a11yProps(1)} />
          <Tab label="TVs" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="flex flex-col">
            <LiveEvent loggedInProvider={loggedInProvider} />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Video loggedInProvider={loggedInProvider} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <TVs loggedInProvider={loggedInProvider} />
      </CustomTabPanel>
    </Box>
  );
}