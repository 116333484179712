import * as XLSX from 'xlsx';
import dateTime from 'date-and-time';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const SubscriptionModal = ({ }) => {


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="flex flex-col gap-4 justify-center bg-boxdark p-6 rounded-lg text-white shadow-xl" style={{ width: '700px' }}>
                {/* Modal Header */}
                <div className="text-center text-xl font-semibold mb-4">Add Subscription</div>

                <div className='flex items-center justify-center'>
                    {/* Input Fields Column */}
                    <div className='flex flex-col items-center justify-center'>
                        <div className='w-full'>
                            <label className="block mb-2 text-sm">Phone:</label>
                            <input
                                type="text"
                                // value={recordCount}
                                // onChange={(e) => setRecordCount(e.target.value)}
                                className="px-2 py-1 rounded bg-transparent border-[1.5px] border-stroke text-black"
                                placeholder="Enter Phone Number"
                            />
                        </div>
                    </div>

                    {/* Input Fields Column */}
                    <div className='flex flex-col items-center justify-center'>
                    <div className='w-full'>
                            <label className="block mb-2 text-sm">Phone:</label>
                            <input
                                type="text"
                                // value={recordCount}
                                // onChange={(e) => setRecordCount(e.target.value)}
                                className="px-2 py-1 rounded bg-transparent border-[1.5px] border-stroke text-black"
                                placeholder="Enter Phone Number"
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SubscriptionModal;
