import UserAnayltics from "../components/Home/UserAnalytics.tsx";
import SubscribedEvents from "../components/Home/SubscribedEvents.tsx";
import SubscribedMovies from "../components/Home/SubscribedMovies.tsx";
import VideosCard from "../components/Home/VideosCard.tsx";
import TrendingVideos from "../components/Home/TrendingVideo.tsx";
import { useNavigate } from "react-router-dom"; // Assuming you are using React Router
import { isLoggedIn } from "../services/isLoggedInService.ts";
import React, { useState, useEffect } from "react";

import { AxiosInstance } from "../common/Loader/axios.config.ts";

interface SubscriptionResponse {
  data: string;
}

const Home = () => {
  const userIsLoggedIn = isLoggedIn();
  const navigate = useNavigate();
  const [stats, setStats] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [reloadOnce, setReloadOnce] = useState<boolean>(true);

  console.log("user is Logged in ", userIsLoggedIn);

  useEffect(() => {
    // const userIsLoggedIn = isLoggedIn();
    const userIsLoggedIn = isLoggedIn;

    if (!userIsLoggedIn) {
      // If the user is not logged in, navigate to the sign-in page
      navigate("/auth/signin");
    }
  }, [navigate]);

  useEffect(() => {
    // Use the 'beforeunload' event to trigger a page reload when navigating to it
    const handleBeforeUnload = () => {
      if (reloadOnce) {
        window.location.reload();
        setReloadOnce(false); // Set reloadOnce to false after the first reload
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [reloadOnce]);

  const [topPackage, setTopPackage] = useState("");
  const [topPPV, setTopPPV] = useState("");
  const [topPackageCount, setTopPackageCount] = useState(0);
  const [topPPVCount, setTopPPVCount] = useState(0);
  const [totalSubs, setTotalSubs] = useState(0);

  useEffect(() => {
    AxiosInstance.get<SubscriptionResponse>("/billing/subscriptions")
      .then((response) => {
        const allSubscriptions = response.data;

        const paidSubscriptions: Array<any> = allSubscriptions.filter(
          (e) => e["status"] == "paid"
        );

        console.log("Paid Saubscriptions", paidSubscriptions);

        setTotalSubs(paidSubscriptions.length);
        var allPPV: Array<any> = paidSubscriptions.filter(
          (e) => e["ppv"] == true
        );

        var packageSubs: Array<any> = paidSubscriptions.filter(
          (e) => e["ppv"] == false
        );

        console.log("All PPV's", allPPV);
        console.log("All Packages", packageSubs);

        // Create an object to count occurrences of each package_name
        const packageCounts = packageSubs.reduce((acc, subscription) => {
          const packageName = subscription.package_name;
          acc[packageName] = (acc[packageName] || 0) + 1;
          return acc;
        }, {});

        const PPVCounts = allPPV.reduce((acc, subscription) => {
          const packageName = subscription.package_name;
          acc[packageName] = (acc[packageName] || 0) + 1;
          return acc;
        }, {});

        // Find the package_name with the highest count
        const mostFrequentPackage = Object.keys(packageCounts).reduce((a, b) =>
          packageCounts[a] > packageCounts[b] ? a : b
        );

        const mostFrequentPPV = Object.keys(PPVCounts).reduce((a, b) =>
          PPVCounts[a] > PPVCounts[b] ? a : b
        );

        setTopPackage(mostFrequentPackage);

        setTopPackageCount(packageCounts[mostFrequentPackage]);

        setTopPPV(mostFrequentPPV);

        setTopPPVCount(PPVCounts[mostFrequentPPV]);

        console.log("Most Frequent Package:", mostFrequentPackage);
        console.log("Subscription Count:", packageCounts[mostFrequentPackage]);
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error);
      });
  }, []);

  return (
    <>
      <div className="grid grid-cols-1 gap-4 bg-brand-red md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        {/* <MonthlyOverview/>
        <PaymentOverview/> */}
      </div>

      <div className="mt-1 grid  w-[1200px] grid-cols-12 gap-2 p-4 md:mt-2 md:gap-2 2xl:mt-3.5 2xl:gap-3.5">
        <div className="col-span-12 xl:col-span-6">
          <UserAnayltics />
        </div>
        <div className="col-span-12 mr-4 xl:col-span-6">
          <div className="col-span-12 xl:col-span-6">
            <div className="grid grid-cols-2 gap-2">
              <SubscribedEvents
                topPackage={topPackage}
                packageCount={topPackageCount}
                totalSubs={totalSubs}
              />
              <SubscribedMovies
                topPPV={topPPV}
                PPVCount={topPPVCount}
                totalSubs={totalSubs}
              />
            </div>
          </div>
          <div className="col-span-12 xl:col-span-6">
            <div className="mt-2 grid flex-grow grid-cols-6 gap-2">
              <VideosCard />
              <TrendingVideos />
            </div>
          </div>
        </div>

        {/* <div className="col-span-12 xl:col-span-4">
          <PlatformAnalytics />
        </div> */}

        {/* <div className="col-span-12 xl:col-span-8">
          <Payments />
        </div>
        <div className="col-span-12 xl:col-span-4">
          <PlatformAnalytics />
        </div>

        <div className="col-span-6">
          <Subscription />
        </div>
        <div className="col-span-6">
          <OverView />
        </div> */}
      </div>
    </>
  );
};

export default Home;
