import { useEffect, useState } from "react";
import { AxiosInstance } from "../../common/Loader/axios.config";
import { RingLoader } from "react-spinners";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaRegCircleCheck } from "react-icons/fa6";
import { MdChevronRight } from "react-icons/md";
import EditPackageForm from "./packages/edit_package_form";
import CreatePackageForm from "./packages/create_package_form";

interface PackageResponse {
  data: string;
}

const ManagePackages = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);

  const [packageName, setPackageName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("");
  const [duration, setDuration] = useState(0);
  const [videoId, setVideoId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const handleCreatePackage = async (e) => {
    e.preventDefault();
    const packageData = {
      packageName,
      description,
      price,
      type,
      duration,
      // videoId,
    };

    try {
      const response = await AxiosInstance.post("/create-package", packageData);

      if (response.status === 200) {

        // Clear form after successful submission
        setPackageName("");
        setDescription("");
        setPrice(0);
        setType("");
        setDuration(0);
        setVideoId("");
      } else {

      }
    } catch (error) {

    }
  };

  const handleSavePackage = async (updatedPackage) => {
    if (selectedPackage) {
      try {
        // Create FormData object
        const formData = new FormData();
        
        // Append all fields from updatedPackage to formData
        Object.entries(updatedPackage).forEach(([key, value]) => {
          if (key === "content" && Array.isArray(value)) {
              value.forEach(item => {
                  formData.append('content[]', item); // Append each value separately
              });
          } else {
              formData.append(key, value);
          }
      });
        // Conditionally add the file if it exists
        // if (updatedPackage.file) {
        //     formData.append("file", updatedPackage.file);
        // }

        // Send the form data
        const response = await AxiosInstance.post(
          `/billing/update-package`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // fetchPackages(); 
        setIsEditing(false);
      } catch (error) {
        console.error("Error updating package:", error);
      }
    }
  };

  const handleUpdatePackage = async (e, packageId) => {
    e.preventDefault();

    const updatedPackageData = {
      packageName,
      description,
      price,
      type,
      duration,
      videoId,
    };

    try {
      const response = await AxiosInstance.put(
        `/update-package/${packageId}`,
        updatedPackageData
      );

      if (response.status === 200) {

        // Clear form or handle success as needed
      } else {

      }
    } catch (error) {
    }
  };

  useEffect(() => {
    setLoading(true);
    AxiosInstance.get<PackageResponse>("/billing/packages")
      .then((response) => {
        const allPackages = response.data;

        const sortedPackages = allPackages.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });

        setPackages(sortedPackages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="flex justify-end py-3">
        <div className="text-md sha flex w-fit items-center justify-center space-x-2 rounded-full bg-brand-background p-2 text-white shadow-lg cursor-pointer hover:scale-105" 
          onClick={() => {
            isEditing && setIsEditing(false)
            setIsCreating(true)
          }
          }>
          <IoMdAddCircleOutline size={24} />
          <p className="px-0.5"> Create Package </p>
        </div>
      </div>

      <div className="flex gap-x-5 justify-start items-start">
        <div className="mx-auto grid grid-cols-2 gap-x-5 gap-y-4 text-white flex items-start justify-start">
          {packages.map((singlePackage: any) => {
            return (
              <div className="rounded-lg border-[1.3px] border-brand-stroke-color bg-brand-background px-5 py-5">
                <div className="flex gap-x-5 items-start justify-start">

                  {/* package image */}

                  <div
                    className="h-[50px] w-[50px] rounded-lg bg-black bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${singlePackage["thumb_nail"]})`,
                    }}
                  ></div>

                  {/* Package data */}
                  <div className="w-[70%]">

                    <div className="flex gap-x-5 items-center justify-between">

                      <p className="text-base font-bold"> {singlePackage.name}</p>
                      {/* Edit Button */}
                      <MdChevronRight
                        onClick={() => {
                          isCreating && setIsCreating(false);
                          setSelectedPackage(singlePackage);
                          setIsEditing(true);
                        }} size={24} />

                    </div>
                    <div>
                      <p className="text-xs opacity-45">
                        {" "}
                        {singlePackage.description}
                      </p>
                      <p className="text-xs">MK {singlePackage.price}</p>
                    </div>


                  </div>




                </div>





              </div>
            );
          })}

          {/* {JSON.stringify(packages, undefined, 2)} */}
        </div>

        {isEditing && (
          <div className="w-[500px] rounded-lg border-[1.5px] border-brand-stroke-color bg-brand-background px-4">
            {/* <h2>Edit Package</h2> */}
            <EditPackageForm packageData={selectedPackage} handleSavePackage={handleSavePackage} />
          </div>
        )}

        {isCreating && (
          <div className="w-[500px] rounded-lg border-[1.5px] border-brand-stroke-color bg-brand-background px-4">
            {/* <h2>Edit Package</h2> */}
            <CreatePackageForm />
          </div>
        )}

        {/* <div className="w-[500px] rounded-lg border-[1.5px] border-brand-stroke-color bg-brand-background px-4">
          <div className="flex items-center gap-x-4 w-full">
            <div>
              <div className="h-[60px] w-[60px] rounded-lg bg-black border-[1.5px] border-brand-stroke-color"></div>
            </div>
            <div>
              <input
                type="text"
                name="packageName"
                placeholder="Package Name"
                className="my-2 rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-4 py-2"
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
              />
              <br />
              <input
                type="text"
                name="description"
                placeholder="Details/Description"
                className="my-2 rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-4 py-2"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <br />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">

            <div className="col-span-2">
              <input
                type="number"
                name="price"
                placeholder="Package Price"
                className="my-2 w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-4 py-4 text-2xl font-bold"
                value={price}
                onChange={(e) => setPrice(parseInt(e.target.value, 10))}
              />
            </div>

            <div>
              <input
                type="text"
                name="type"
                placeholder="Subscription Type"
                className="my-2 w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-4 py-4"
                value={type}
                onChange={(e) => setType(e.target.value)}
              />
            </div>

            <div>
              <input
                type="number"
                name="duration"
                placeholder="Duration"
                className="my-2 w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-4 py-4"
                value={duration}
                onChange={(e) => setDuration(parseInt(e.target.value, 10))}
              />
            </div>
          </div>
          <hr className="border-gray-300 my-4 border-t-2 border-dashed" />

          <div className="flex flex-col my-4 gap-y-2">
            <div className="flex items-center gap-x-2">
              <FaRegCircleCheck size={24} />
              <p className="text-white text-lg">Live TV's</p>
            </div>
            <div className="flex items-center gap-x-2">
              <FaRegCircleCheck size={24} />
              <p className="text-white text-lg">Live Events</p>
            </div>
            <div className="flex items-center gap-x-2">
              <FaRegCircleCheck size={24} />
              <p className="text-white text-lg">Videos</p>
            </div>
          </div>



          <button className="border-[2px] border-brand-stroke-color bg-brand-background px-4 py-2 text-white rounded-xl px-4 items-center justify-center">Save Package</button>


        </div> */}
      </div>
    </div>
  );
};

export default ManagePackages;
