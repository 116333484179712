import UpdateVisibilityOptions from './interfaces/visibilityOptions';
import { AxiosInstance } from '../common/Loader/axios.config';
export const updateVisibility = async (
  eventId: string,
  category: string,

  data: any
): Promise<void> => {
  // const url = `https://admin.kanemaonline.com/${category}/${eventId}`;

  // const headers = {
  //     Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  //     'Content-Type': 'application/json',
  // };

  // const body: { status: { visibility?: boolean; publish?: boolean } } = {
  //   status: {},
  // };

  // if (options.visibility !== null && options.visibility !== undefined) {
  //   body.status.visibility = options.visibility;
  // }

  // if (options.publish !== null && options.publish !== undefined) {
  //   body.status.publish = options.publish;
  // }
  //
  //
  const body = {
    status: {
      visibility: data.visibility,
      publish: data.publish,
    },
  };

  // const body = {
  //   status: {
  //     ...(data.visibility && { visibility: data.visibility }),
  //     ...(data.publish && { publish: data.publish }),
  //   },
  // };

  console.log("Visibility body",body);



  try {
    const response = await AxiosInstance.put(`/${eventId}/content`, body);

    if (response.status != 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.data;

    console.log(
      `Visibility and/or publish updated successfully for video ${eventId}`,
      result
    );
  } catch (error) {
    console.error(
      `Error updating visibility and/or publish for video ${eventId}:`,
      error
    );

    throw error;
  }
};
