import * as React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ManageVideos from '../components/Manage/ManageVideos';
import ManageUsers from '../components/Manage/ManageUsers';
import ManagePayments from '../components/Manage/ManagePayments';
import ManageFeedback from '../components/Manage/ManageFeedback';
import ManageSubscriptions from '../components/Manage/ManageSubscriptions';
import ManagePackages from '../components/Manage/ManagePackages';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Manage() {
  const location = useLocation();
  const tabQueryParam = new URLSearchParams(location.search).get('tab');
  const initialTab = tabQueryParam ? parseInt(tabQueryParam, 10) : 0;
  const [value, setValue] = React.useState(initialTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          textColor="inherit"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Content"
            {...a11yProps(0)}
            component={Link}
            to="/manage?tab=0"
          />
          <Tab
            label="Users"
            {...a11yProps(1)}
            component={Link}
            to="/manage?tab=1"
          />
          <Tab
            label="Payments"
            {...a11yProps(2)}
            component={Link}
            to="/manage?tab=2"
          />
          <Tab
            label="Feedback"
            {...a11yProps(3)}
            component={Link}
            to="/manage?tab=3"
          />
          <Tab
            label="Suscriptions"
            {...a11yProps(4)}
            component={Link}
            to="/manage?tab=4"
          />
          {/* <Tab
            label="Packages"
            {...a11yProps(5)}
            component={Link}
            to="/manage?tab=5"
          /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ManageVideos />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ManageUsers />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ManagePayments />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ManageFeedback />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <ManageSubscriptions />
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={5}>
        <ManagePackages />
      </CustomTabPanel> */}
    </Box>
  );
}
