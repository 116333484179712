import API_BASE_URL from "./apiConfig";
import authHeader from "./auth-header";
import User from "./interfaces/user-interface";

import { AxiosInstance } from '../common/Loader/axios.config';

const USERS_ENDPOINT = '/users';


interface PaginatedUsersResponse {
    users: User[];
    total: number;
}

class UserService {

    private static getHeaders(): Headers {
        const headers = new Headers();

        // const token = authHeader();
        const token = localStorage.getItem('accessToken')

        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', 'application/json');
        return headers;
    }

    private static async handleResponse(response: Response): Promise<any> {
        if (!response.ok) {
            throw new Error(`Request failed with status: ${response.status}`);
        }

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return response.json();
        }

        return response.text();
    }

    static async getAllUsers(): Promise<User[]> {
        const headers = this.getHeaders();
        const allUsers: User[] = [];
        const limit = 1000; 
        let skip = 0;
    
        try {
            while (true) {
                const response = await AxiosInstance.get(`${USERS_ENDPOINT}`, {
                    params: { limit, skip }
                });
    
                const users: User[] = Array.isArray(response.data)
                    ? response.data
                    : response.data?.message || response.data?.items || [];
    
                if (users.length === 0) break;
    
                allUsers.push(...users);

                skip += limit;
            }
    
            console.log("All Users:", allUsers);
            return allUsers;
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error;
        }
    }
    
    

    static async getUser(userId: string): Promise<User> {
        const headers = this.getHeaders();
        const options: RequestInit = { method: 'GET', headers };
        // const response = await fetch(`${API_BASE_URL}${USERS_ENDPOINT}/${userId}`, options);
        const response = await AxiosInstance.get(`/users/${userId}`);
        console.log("User Response",response.data);

        return response.data;
    }

    static async createUser(newUser: User): Promise<User> {
        const headers = this.getHeaders();
        const options: RequestInit = {
            method: 'POST',
            headers,
            body: JSON.stringify(newUser),
        };
        const response = await fetch(`${API_BASE_URL}${USERS_ENDPOINT}`, options);
        return this.handleResponse(response);
    }

    static async updateUser(userId: string, updatedUser: User): Promise<User> {
        const headers = this.getHeaders();
        const options: RequestInit = {
            method: 'PUT',
            headers,
            body: JSON.stringify(updatedUser),
        };
        const response = await fetch(`${API_BASE_URL}${USERS_ENDPOINT}/${userId}`, options);
        return this.handleResponse(response);
    }

    static async deleteUser(userId: string): Promise<void> {
        const headers = this.getHeaders();
        const options: RequestInit = { method: 'DELETE', headers };
        const response = await fetch(`${API_BASE_URL}${USERS_ENDPOINT}/${userId}`, options);
        return this.handleResponse(response);
    }

    static async countUsers(): Promise<number> {
        const headers = this.getHeaders();
        const options: RequestInit = { method: 'GET', headers };
        const response = await fetch(`${API_BASE_URL}${USERS_ENDPOINT}/count`, options);
        const countResult = await this.handleResponse(response);
        console.log(countResult.count)
        return countResult.count;
    }

    static async getUserStatistics(): Promise<any> {
        const headers = this.getHeaders();
        const options: RequestInit = { method: 'GET', headers };
        const response = await fetch(`${API_BASE_URL}${USERS_ENDPOINT}/12345/stats`, options);
        return this.handleResponse(response);
    }

    static async getUsersByPagination(skip: number, limit: number): Promise<User[]> {
        const headers = this.getHeaders();
        const options: RequestInit = { method: 'GET', headers };
        const response = await fetch(`${API_BASE_URL}${USERS_ENDPOINT}?skip=${skip}&limit=${limit}`, options);

        const responseData = await this.handleResponse(response);

        console.log("Users by Pagination Response", responseData)

        // Assuming responseData.results contains the array of users
        return responseData.message;
    }

}

export default UserService;

  // static async getUsersByPagination(skip: number, limit: number): Promise<User[]> {
    //     const headers = this.getHeaders();
    //     const options: RequestInit = { method: 'GET', headers };
    //     const response = await fetch(`${API_BASE_URL}${USERS_ENDPOINT}?skip=${skip}&limit=${limit}`, options);
    //     return this.handleResponse(response);
    // }




    // static async getUsers(): Promise<User[]> {
    //     const headers = this.getHeaders();
    //     const options: RequestInit = { method: 'GET', headers };
    //     const response = await fetch(`${API_BASE_URL}${USERS_ENDPOINT}`, options);

    //     return this.handleResponse(response);
    // }
    // static async getUsers(): Promise<User[]> {
    //     const headers = this.getHeaders();
    //     const options: RequestInit = { method: 'GET', headers };

    //     const response = await fetch(`${API_BASE_URL}${USERS_ENDPOINT}`, options);
    //     const responseData = await this.handleResponse(response);

    //     return responseData as User[]; // Assuming the response data is an array of User objects
    // }