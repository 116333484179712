import * as React from 'react';
import { AxiosInstance } from '../../common/Loader/axios.config';
import { useEffect, useState, useRef } from 'react';
import { Card, Typography } from '@material-tailwind/react';
import dateTime from 'date-and-time';
import { RingLoader } from 'react-spinners';
import { CiExport } from "react-icons/ci";
import SubscriptionsModal from './Subscriptions/export_modal';
import User from '../../pages/User';
import { MdMoreVert } from "react-icons/md";
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import { set } from 'react-hook-form';
import { Tabs, Tab, TextField } from '@mui/material';
import UnpaidSubscriptions from './Subscriptions/unpaid_subscriptions';
import PaidSubFields from './Subscriptions/paid_sub_fields';
import Swal from 'sweetalert2';
import TVsService from '../../services/tvs-service';




const TABLE_HEAD = ['Start Date', 'Renewal Date', 'User', 'User ID', 'Package', 'Period', 'Expiry Date', 'Amount', 'Status'];

interface SubscriptionResponse {
  data: string;
}

const ManageSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [transactionCount, setTransactionCount] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [loading, setLoading] = useState(false);

  const [exportModal, setExportModal] = useState(false);

  const [unpaidSubscriptions, setUnpaidSubscriptions] = useState([]);
  const [allUnpaid, setAllUnpaid] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    setLoading(true);
    AxiosInstance.get<SubscriptionResponse>('/billing/subscriptions')
      .then((response) => {
        const allSubscriptions = response.data;

        const paidSubscriptions: Array<any> = allSubscriptions.filter(
          (e) => e["status"] == "paid"
        );

        const unpaidSubscriptions: Array<any> = allSubscriptions.filter(
          (e) => e["status"] == "unpaid"
        );

        const sortedSubs = paidSubscriptions.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });

        const sortedUnpaidSubs = unpaidSubscriptions.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });

        const totalSum = sortedSubs.reduce((acc, sub) => acc + parseFloat(sub.price), 0);

        setSubscriptions(sortedSubs);
        setAllSubscriptions(sortedSubs)
        setUnpaidSubscriptions(sortedUnpaidSubs);
        setAllUnpaid(sortedUnpaidSubs)
        setTransactionCount(sortedSubs.length);
        setTotalSum(totalSum);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching subscriptions:', error);
      });
  }, []);


  const [columnWidths, setColumnWidths] = useState({
    Start_Date: 70,
    User_Name: 150,
    PayerID: 150,
    Payment_Reference: 150,
    Package: 50,
    Period: 50,
    Expiry_Date: 50,
    Amount: 50,
    Status: 50,
    Action: 50,
    Renewal_Date: 150,
    UserID: 150,


  });

  const resizeHandleRef = useRef(null);
  const [selectedFields, setSelectedFields] = useState([
    'Start_Date',
    'Expiry_Date',
    'User_Name',
    'PayerID',
    'Payment_Reference',
    'Package',
    'Period',
    'Amount',
    'Status',
  ]);

  // Handle mousedown on the resize handle
  const onMouseDown = (e, columnName) => {
    e.preventDefault();

    if (columnName === 'Action') return;

    const startX = e.clientX;
    const startWidth = columnWidths[columnName];

    let animationFrameId;

    const onMouseMove = (e) => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }

      // Request animation frame to limit the updates
      animationFrameId = requestAnimationFrame(() => {
        const newWidth = Math.max(50, startWidth + (e.clientX - startX));
        if (newWidth !== columnWidths[columnName]) { // only update if width actually changes
          setColumnWidths((prevWidths) => ({
            ...prevWidths,
            [columnName]: newWidth,
          }));
        }
      });
    };


    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      // Cancel any pending animation frame
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };


  const [openMenuId, setOpenMenuId] = useState(null);
  const menuRef = useRef(null);

  // Toggles the menu visibility
  const toggleMenu = (id) => {
    setOpenMenuId((prevId) => (prevId === id ? null : id));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside of the menu
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    // Add the event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDeactivate = async (sub_id) => {

    const body = {
      status: "unpaid"
    }

    setOpenMenuId(null);

    const response = await AxiosInstance.put(`billing/${sub_id}/update-subscriptions`, body);

    if (response.status == 200) {
      Swal.fire({
        icon: 'success',
        title: 'Subscription Deactivated Successfully!',
        showConfirmButton: false,
        timer: 2000,
      });

      // setActivating(false);

      setOpenMenuId(null);

      window.location.reload();

    } else {

    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }
  

  const handleFieldChange = (updatedFields) => {
    setSelectedFields(updatedFields);
  };


  const [tvs, setTVs] = useState([]);
  const [loggedInProvider, setLoggedInProvider] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTV, setIsTV] = useState(false);

  useEffect(() => {
    async function fetchTVs() {
      try {
        setLoading(true);
        const fetchedTVs = await TVsService.getAllTVs();
        setTVs(fetchedTVs);
      } catch (error) {
        console.error("Error fetching TVs:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchTVs();
  }, []);

  useEffect(() => {
    const storedProvider = JSON.parse(localStorage.getItem("provider"));
    if (storedProvider) {
      setIsAdmin(storedProvider.userType === "admin" || storedProvider.id === "8b1fa3");
      setLoggedInProvider(storedProvider);
    }
  }, []);

  useEffect(() => {
    if (loggedInProvider && loggedInProvider.content && tvs.length > 0) {
      const hasMatchingTV = loggedInProvider.content.some((contentItem) =>
        tvs.some((tv) => tv.name === contentItem)
      );
      setIsTV(hasMatchingTV);
    }
  }, [loggedInProvider, tvs]);


  useEffect(() => {
    let baseSubscriptions;
  
    if (isAdmin) {
      baseSubscriptions = allSubscriptions;
    } else if (loggedInProvider?.content) {
      baseSubscriptions = allSubscriptions.filter((sub) =>
        loggedInProvider.content.includes(sub.package_name)
      );
  
      if (isTV) {
        const kiliyeSubscriptions = allSubscriptions.filter(
          (sub) => sub.package_name === "KiliyeKiliye"
        );
        baseSubscriptions = [...baseSubscriptions, ...kiliyeSubscriptions];
      }
    } else {
      baseSubscriptions = [];
    }

    const sortedBaseSubs = baseSubscriptions.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  })
  
    if (searchTerm === '') {
      setSubscriptions(sortedBaseSubs);
    } else {
      const filtered = sortedBaseSubs.filter((subscription) =>
        subscription.username?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSubscriptions(filtered);
    }
  }, [searchTerm, allSubscriptions, loggedInProvider, isTV]);

  return (
    <div className="mx-auto w-[1200px]">

      <div className='flex items-center justify-between my-2'>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {['Paid', 'Unpaid'].map((label, index) => (
            <div
              key={index}
              className={`tab-item px-3 text-sm rounded-full bg-brand-background py-2 mx-2 border-[1.3px] border-brand-stroke-color shadow-lg cursor-pointer ${value === index ? 'py-2 border border-white rounded-full bg-transparent' : ''}`}
              onClick={() => handleChange(null, index)}
            >
              <span>{label}</span>
            </div>
          ))}
        </Tabs>
      </div>

      <div className='flex items-center justify-between my-3'>

        {value === 0 && (
          <div className="text-black border border-brand-stroke-color rounded-lg p-1" id="select">

            <PaidSubFields
              options={Object.keys(columnWidths).filter((key) => key !== 'Action')}
              label="Select Fields"
              selectedOptions={selectedFields}
              onChange={handleFieldChange}
            />
          </div>
        )}

        {value === 1 && (
          <div>

            {/* <PaidSubFields
              options={Object.keys(columnWidths).filter((key) => key !== 'Action')}
              label="Select Fields"
              selectedOptions={selectedFields}
              onChange={handleFieldChange}
            /> */}
          </div>
        )}

        <input
          type='text'
          name='search'
          placeholder='Search Subscription'
          className='rounded-md text-sm border border-stroke bg-transparent px-4 py-3 shadow-default dark:border-strokedark dark:bg-black w-64'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

      </div>

      {value === 0 && (
        <div className='flex flex-col'>

          <div className='flex justify-between items-center'>
            <div className='text-white rounded bg-brand-background px-4 py-2 rounded-lg w-fit font-bold my-2'>Total Subscriptions: {transactionCount}</div>

            <div className='flex gap-x-2 items-center'>
              <div className='text-white rounded bg-brand-background px-4 py-2 rounded-lg w-fit font-bold my-2'>
                Total Amount: MWK {totalSum.toLocaleString({ currency: 'MWK' })}
              </div>
              <div className='flex gap-x-2 items-center justify-center rounded bg-white px-4 py-2 rounded-lg w-fit my-2 cursor-pointer hover:scale-105'
                onClick={() => setExportModal(true)}>
                <CiExport size={20} color="black" />
                <p className="text-black text-md">Export</p>
              </div>
            </div>
          </div>
          {/* <table className="w-full min-w-max table-auto bg-white text-center shadow-default dark:bg-brand-background">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-gray bg-white p-4 dark:bg-brand-background"
              >
                {head}
              </th>
            ))}
          </tr>
        </thead>

        {loading ? (
          <div className="mx-auto flex justify-center">
            <RingLoader size={70} color={'#36D7B7'} loading={loading} />
          </div>
        ) : (
          <tbody>
            {subscriptions.map((subscription, index) => (
              <tr key={index} className='border border-brand-stroke-color '>
                <td className="p-4 text-sm">
                  {dateTime.format(
                    new Date(Date.parse(subscription.created_at)),
                    'DD MMM, YYYY'
                  )}
                </td>

                <td className="p-4 text-sm">
                  {dateTime.format(
                    new Date(Date.parse(subscription.renewed_at)),
                    'DD MMM, YYYY'
                  )}
                </td>

                <td className="p-4 text-sm">
                  {subscription.username}
                </td>
                <td className="p-4 text-sm">
                  {subscription.userid}
                </td>
                <td className="p-4 text-sm">
                  {subscription.package_name}
                </td>
                <td className="p-4 text-sm">
                  {subscription.duration}
                </td>
                <td className="p-4 text-sm">
                  {dateTime.format(
                    new Date(Date.parse(subscription.created_at) + (subscription.duration * 24 * 60 * 60 * 1000)),
                    'DD MMM, YYYY'
                  )}

                </td>
                <td className="p-4 text-sm">
                  {subscription.price}
                </td>
                <td className="p-4 text-sm">
                  {Date.parse(subscription.created_at) + (subscription.duration * 24 * 60 * 60 * 1000) < Date.now() ? (<p className="text-danger">Expired</p>) : (<p className="text-brand-green">
                    Active
                  </p>)}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table> */}

          <table className="table-auto w-full bg-brand-background shadow-md rounded-lg">
            <thead>
              <tr className="text-white text-sm text-center">
                {selectedFields.map((columnName) => (
                  <th
                    key={columnName}
                    className="p-4 relative border-r border-brand-stroke-color"
                    style={{ width: `${columnWidths[columnName]}px` }}
                  >
                    {columnName}
                    <div
                      ref={resizeHandleRef}
                      className="resize-handle absolute right-0 top-0 h-full w-2 cursor-col-resize"
                      onMouseDown={(e) => onMouseDown(e, columnName)}
                    />
                  </th>
                ))}
                <th
                  key="Action"
                  className="p-4 relative border-r border-brand-stroke-color"
                  style={{ width: `${columnWidths['Action']}px` }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={8} className="py-4 text-center">
                    <CircularProgress />
                  </td>
                </tr>
              ) : (
                subscriptions.map((subscription, index) => (

                  <tr key={subscription._id} className="hover:bg-gray-100 text-white text-xs text-center">
                    {selectedFields.map((columnName) => {
                      let content = null;

                      if (columnName === 'Start_Date') {
                        content = dateTime.format(new Date(Date.parse(subscription.created_at)), 'DD/MM/YY HH:mm ');
                      } else if (columnName === 'Renewal_Date') {
                        content = dateTime.format(new Date(Date.parse(subscription.renewed_at)), 'DD/MM/YY HH:mm ');
                      } else if (columnName === 'User_Name') {
                        content = subscription.username;
                      } else if (columnName === 'UserID') {
                        content = subscription.userid;
                      } else if (columnName === 'PayerID') {
                        content = subscription.payerid;
                      } else if (columnName === 'Payment_Reference') {
                        content = subscription.payment_reference;
                      } else if (columnName === 'Package') {
                        content = subscription.package_name;
                      } else if (columnName === 'Period') {
                        content = subscription.duration;
                      } else if (columnName === 'Expiry_Date') {
                        content = dateTime.format(
                          new Date(Date.parse(subscription.created_at) + subscription.duration * 24 * 60 * 60 * 1000),
                          'DD MMM, YYYY'
                        );
                      } else if (columnName === 'Amount') {
                        content = subscription.price;
                      } else if (columnName === 'Status') {
                        content =
                          Date.parse(subscription.created_at) + subscription.duration * 24 * 60 * 60 * 1000 < Date.now() ? (
                            <p className="text-danger">Expired</p>
                          ) : (
                            <p className="text-brand-green">Active</p>
                          );
                      }

                      return (
                        <td key={columnName} className="py-4 px-1">
                          {content}
                        </td>
                      );
                    })}
                    <td key="Action" className="py-4 px-1 relative">
                      <MdMoreVert
                        className="ml-6"
                        size={18}
                        onClick={() => toggleMenu(subscription._id)}
                      />

                      {openMenuId === subscription._id && (
                        <div
                          ref={menuRef}
                          className="absolute top-full right-0 w-32 bg-gray-800 border border-gray-600 rounded shadow-lg z-10"
                        >
                          <button
                            onClick={() => {
                              handleDeactivate(subscription._id);
                              setOpenMenuId(null);
                            }}
                            className="block px-3 py-1 w-full text-center text-lg cursor-pointer bg-white text-black"
                          >
                            Deactivate
                          </button>
                        </div>
                      )}
                    </td>

                  </tr>
                ))
              )}
            </tbody>
          </table>

          {exportModal && (
            <SubscriptionsModal subscriptions={subscriptions} setExportModal={setExportModal} />
          )}
        </div>
      )}

      {value === 1 &&
        <UnpaidSubscriptions
          unpaidSubscriptions={unpaidSubscriptions}
          setUnpaidSubscriptions={setUnpaidSubscriptions}
          searchTerm={searchTerm}
          allUnpaid={allUnpaid}
          loggedInProvider={loggedInProvider}
          isAdmin={isAdmin}
          isTV={isTV}
        />}

    </div>
  );
};

export default ManageSubscriptions;
