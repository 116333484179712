import * as XLSX from 'xlsx';
import dateTime from 'date-and-time';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const SubscriptionsModal = ({ subscriptions, setExportModal }) => {
    const [activeTab, setActiveTab] = useState('All');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const [recordCount, setRecordCount] = useState('');
    const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });

    const [exportSubscriptions, setExportSubscriptions] = useState([]);
    const [packageOptions, setPackageOptions] = useState([]);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        const uniquePackages = [...new Set(subscriptions.map((subscription) => subscription.package_name))];
        setPackageOptions(uniquePackages);
        setExportSubscriptions(subscriptions);
    }, [subscriptions]);

    useEffect(() => {
        if (recordCount) {
            const limitedRecords = subscriptions.slice(0, Number(recordCount));
            setExportSubscriptions(limitedRecords);
        } else {
            setExportSubscriptions(subscriptions);
        }
    }, [recordCount, subscriptions]);

    useEffect(() => {
        const filterByDateRange = () => {
            if (!dateRange.startDate && !dateRange.endDate) return subscriptions;

            // Validate the date range
            const startDate = dateRange.startDate ? new Date(dateRange.startDate) : null;
            const endDate = dateRange.endDate ? new Date(dateRange.endDate) : null;

            if (startDate && endDate && startDate > endDate) {
                alert("Start date cannot be later than end date.");
                return []; 
            }

            return subscriptions.filter((subscription) => {
                const paymentDate = new Date(subscription.created_at);

                // Set hours for comparison
                if (startDate) {
                    startDate.setHours(0, 0, 0, 0);
                }
                if (endDate) {
                    endDate.setHours(23, 59, 59, 999);
                }

                return (
                    (!startDate || paymentDate >= startDate) &&
                    (!endDate || paymentDate <= endDate)
                );
            });
        };

        let updatedSubs = filterByDateRange();
        setExportSubscriptions(updatedSubs);
    }, [dateRange, subscriptions]);





    const options = [
        { value: 'ACTIVE', label: 'Active' },
        { value: 'EXPIRED', label: 'Expired' }
    ];
    
    const handleChange = (option) => {
        setSelectedOption(option);
    
        const filtered = subscriptions.filter((subscription) => {
            const createdAt = new Date(subscription.created_at);
            const expiryDate = new Date(createdAt.getTime() + (subscription.duration * 24 * 60 * 60 * 1000));
            const isActive = expiryDate > new Date();
    
            return option.value === 'ACTIVE' ? isActive : !isActive;
        });
    
        setExportSubscriptions(filtered);
    };

    const packages = [
        ...packageOptions.map((product) => ({
            value: product,
            label: product,
        })),
    ];

    const handlePackageChange = (option) => {
        setSelectedPackage(option);
        const filtered = exportSubscriptions.filter((subscription) => subscription.package_name === option.value);
        setExportSubscriptions(filtered);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(exportSubscriptions.map(subscription => {
            const creationDate = new Date(Date.parse(subscription.created_at));
            const expirationDate = new Date(creationDate.getTime() + (subscription.duration * 24 * 60 * 60 * 1000));
            const isActive = expirationDate > new Date();
    
            return {
                Date: dateTime.format(creationDate, 'DD/MM/YY HH:mm'),
                Username: subscription.username,
                UserID: subscription.userid,
                Package: subscription.package_name,
                Amount: subscription.price,
                Period: subscription.duration,
                Expiry_Date: dateTime.format(expirationDate, 'DD MMM, YYYY'),
                Status: isActive ? 'Active' : 'Expired',
            };
        }));
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Subscriptions");
    
        const now = new Date();
        const formattedDate = dateTime.format(now, 'YYYY-MM-DD_HH-mm-ss');
        const fileName = `Subscriptions_Summary_${formattedDate}.xlsx`;
    
        // Write the workbook to a file with the generated name
        XLSX.writeFile(workbook, fileName);
    };
    

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(8); 
        
        // Define columns and data rows
        const columns = ["Date", "Username", "UserID", "Package", "Amount", "Period", "Expiry Date", "Status"];
        const rows = exportSubscriptions.map(subscription => {
            const creationDate = new Date(Date.parse(subscription.created_at));
            const expirationDate = new Date(creationDate.getTime() + (subscription.duration * 24 * 60 * 60 * 1000));
            const isActive = expirationDate > new Date();
    
            return [
                dateTime.format(creationDate, 'DD/MM/YY HH:mm'),
                subscription.username,
                subscription.userid,
                subscription.package_name,
                subscription.price,
                subscription.duration,
                dateTime.format(expirationDate, 'DD MMM, YYYY'),
                isActive ? 'Active' : 'Expired' 
            ];
        });

        // Add table to PDF
        doc.autoTable({
            head: [columns],
            body: rows,
            startY: 10,
            theme: 'striped'
        });
    
        // Generate filename with date and time
        const now = new Date();
        const formattedDate = dateTime.format(now, 'YYYY-MM-DD_HH-mm-ss');
        const fileName = `Subscriptions_Summary_${formattedDate}.pdf`;
    
        // Save the PDF
        doc.save(fileName);
    };
    

    const handleExportClick = (exportType) => {
        if (exportType === 'pdf') {
            exportToPDF();
        } else if (exportType === 'excel') {
            exportToExcel();
        }
        setExportModal(false);
        setIsDropdownOpen(false); 
    };


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="flex flex-col gap-4 justify-center bg-boxdark p-6 rounded-lg text-white shadow-xl" style={{ width: '700px' }}>
                {/* Modal Header */}
                <div className="text-center text-2xl font-semibold mb-4">Subscription Statement Export</div>

                <div className='flex gap-x-12 items-center'>

                    {/* Tabs */}
                    <div className="flex flex-col gap-y-8 px-2 border-r border-gray border-opacity-70">
                        {['All', 'Status', 'Records', 'Date', 'Package'].map((tab) => (
                            <button
                                key={tab}
                                onClick={() => setActiveTab(tab)}
                                className={`flex-1 py-2 px-2 text-center ${activeTab === tab ? 'font-bold bg-brand-red rounded-sm' : 'text-gray text-opacity-50'}`}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>

                    {/* Tab Content */}
                    <div className="mt-4 mx-auto">
                        {activeTab === 'All' && (
                            <div className='text-white mx-auto'>
                                Export All Subscriptions Records
                            </div>

                        )}
                        {activeTab === 'Status' && (
                            <div className='text-black w-[50vh]'>
                                <label className="block mb-2 text-white">Select Status to Export:</label>


                                <Select
                                    value={selectedOption}
                                    onChange={handleChange}
                                    options={options}
                                    placeholder="Select Status..."
                                />
                            </div>
                        )}
                        {activeTab === 'Records' && (
                            <div className='w-[50vh]'>
                                <label className="block mb-2">Number of Records to Export:</label>
                                <input
                                    type="number"
                                    value={recordCount}
                                    onChange={(e) => setRecordCount(e.target.value)}
                                    className="w-full p-2 rounded bg-gray-700 text-black"
                                    placeholder="Enter number of records"
                                />
                            </div>
                        )}
                        {activeTab === 'Date' && (
                            <div className='w-[50vh]'>
                                <label className="block mb-2">Date Range:</label>
                                <div className="flex gap-4">
                                    <input
                                        type="date"
                                        value={dateRange.startDate}
                                        onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
                                        className="w-full p-2 rounded bg-gray-700 text-black"
                                    />
                                    <input
                                        type="date"
                                        value={dateRange.endDate}
                                        onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
                                        className="w-full p-2 rounded bg-gray-700 text-black"
                                    />
                                </div>
                            </div>
                        )}

                        {activeTab === 'Package' && (
                            <div className='text-black w-[50vh]'>
                                <label className="block mb-2 text-white">Select Package to Export:</label>


                                <Select
                                    value={selectedPackage}
                                    onChange={handlePackageChange}
                                    options={packages}
                                    placeholder="Select Package..."
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Export and Cancel Buttons */}
                <div className="flex justify-end gap-4 mt-6">
                    <button
                        onClick={() => setExportModal(false)}
                        className="px-4 py-2 rounded bg-danger hover:bg-red-700"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        className="px-4 py-2 rounded bg-success hover:bg-green-700"
                    >
                        Export
                    </button>

                    {isDropdownOpen && (
                        <div className="absolute right-120 mt-12 w-48 bg-white rounded-md shadow-lg z-10">
                            <button
                                onClick={() => handleExportClick('pdf')}
                                className="block w-full px-4 py-2 text-left text-black rounded-md hover:text-white hover:bg-black"
                            >
                                Export as PDF
                            </button>
                            <button
                                onClick={() => handleExportClick('excel')}
                                className="block w-full px-4 py-2 text-left text-black rounded-md hover:text-white hover:bg-black hover:scale-105"
                            >
                                Export as Excel
                            </button>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default SubscriptionsModal;
