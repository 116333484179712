import ProductOne from '../../../images/product/product-02.png';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/ModeEdit';
import { Link, useNavigate } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/StackedBarChart';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ShowChart from '@mui/icons-material/ShowChart';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TV from '../../services/interfaces/tv-interface';
import TVsService from '../../services/tvs-service';
import ServerSwitcher from '../ServerSwitcher';
import { isLoggedIn } from '../../services/isLoggedInService';
import ManageLivetvService from '../../services/promote-to-trends-tvs';
import UpdateVisibilityOptions from '../../services/interfaces/visibilityOptions';
import { updateVisibility } from '../../services/visibilityPublishService';
import { RingLoader } from 'react-spinners';
import { useEffect, useState } from 'react';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const ITEM_HEIGHT = 48;

const TVs: React.FC = ({loggedInProvider}) => {
  const [tvs, setTVs] = React.useState<TV[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = React.useState<'views' | 'comments' | 'name'>(
    'views'
  );
  const [filterName, setFilterName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true); // Step 2

  const open = Boolean(anchorEl);
  const category = 'tvs';
  const manageLivetvService = new ManageLivetvService();

  const navigate = useNavigate();

  React.useEffect(() => {
    const userIsLoggedIn = isLoggedIn();

    if (!userIsLoggedIn) {
      // If the user is not logged in, navigate to the sign-in page
      navigate('/auth/signin');
    }
  }, [navigate]);

  // ... (previous code)

  React.useEffect(() => {
    async function fetchTVs() {
      try {
        setLoading(true);
        const fetchedTVs = await TVsService.getAllTVs();

        const tvsWithInitialStatus = fetchedTVs.map((tv) => {
          return {
            ...tv,
            status: {
              ...tv.status,
              visibility: tv.status?.visibility || false,
              publish: tv.status?.publish || false,
            },
          };
        });

        setTVs(tvsWithInitialStatus);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching TVs:', error);
        setLoading(false);
      }
    }

    fetchTVs();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortBy(event.target.value as 'views' | 'comments' | 'name');
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const ITEM_HEIGHT = 48;

  const filteredTVs = tvs
  .filter((vod) => {
    if (loggedInProvider.userType === "admin" || loggedInProvider.id === "8b1fa3") {
      return true; 
    }
    return vod.video_provider === loggedInProvider.id;
  })
  .filter((vod) =>
    vod?.name?.toLowerCase().includes(filterName.toLowerCase())
  )
  .sort((a, b) => {
    const viewsA = typeof a.views === 'number' ? a.views : 0;
    const viewsB = typeof b.views === 'number' ? b.views : 0;

    if (sortBy === 'views') {
      return viewsB - viewsA;
    } else if (sortBy === 'comments') {
      return (b.comments || 0) - (a.comments || 0);
    } else {
      return a.name?.localeCompare(b.name || '');
    }
  });

  // const filteredTVs = tvs
  //   .filter((vod) => vod.name.toLowerCase().includes(filterName.toLowerCase()))
  //   .sort((a, b) => {
  //     const viewsLengthA = a.views;
  //     const viewsLengthB = b.views;

  //     if (sortBy === 'views') {
  //       return viewsLengthB - viewsLengthA;
  //     } else if (sortBy === 'comments') {
  //       return b.comments - a.comments;
  //     } else {
  //       return a.name.localeCompare(b.name);
  //     }
  //   });

  const handlePromoteToTrendingClick = async (tvId: string) => {
    try {
      // Call the promoteToTrending function with the provided event ID
      await manageLivetvService.promoteToTrending(tvId);
    } catch (error) {
      // Handle any errors here
      console.error(error);
    }
  };

  const handleToggleChange = async (
    tvId: string,
    data: {
      visibility: boolean;
      publish: boolean;
    }
  ) => {
    try {
      await updateVisibility(tvId, category, data);

      // UPDATE FIELDS
      setTVs((prevTVs) =>
        prevTVs.map((tv) =>
          tv._id === tvId
            ? {
                ...tv,
                status: {
                  ...tv.status,
                  visibility: data.visibility,
                  publish: data.publish,
                },
              }
            : tv
        )
      );
    } catch (error) {
      // console.error(`Error updating ${field}:`, error);
    }
  };

  const [contentViews, setContentViews] = useState([])

  const getViewership = async () => {

    try {
      const response = await AxiosInstance.get(`users/viewership`);
    
      if (response.status === 200) {
        const allViews = response.data.message;
    
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
    
        const uniqueViewsMap = new Map();
    
        allViews.forEach((view) => {
          const viewDate = new Date(view.start_time);
          const viewMonth = viewDate.getMonth();
          const viewYear = viewDate.getFullYear();
    
          if (viewMonth === currentMonth && viewYear === currentYear) {
            const compositeKey = `${view.userid}-${view.contentid}`; 
    
            if (!uniqueViewsMap.has(compositeKey)) {
              uniqueViewsMap.set(compositeKey, view);
            }
          }
        });

        const uniqueViewsArray = Array.from(uniqueViewsMap.values());
    
        setContentViews(uniqueViewsArray);
      }
    } catch (error) {
      console.error("Error fetching viewership data:", error);
    }
  }

  useEffect(() => {
    getViewership();
  }, []);
  
  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-transparent dark:bg-transparent w-[1150px]">
      <div className="m-4 mt-2 flex justify-between px-4">
        {/* <div className="m-4 mr-10 flex items-start p-4 pr-10">
          <ServerSwitcher />
        </div> */}
        <div className="flex items-center">
          <p className="text-gray-500 dark:text-gray-300 mr-2 text-sm">
            Sort by:
          </p>
          <select
            className="focus:border-blue-300 rounded-md border px-2 py-1 text-sm focus:outline-none dark:border-strokedark dark:bg-transparent"
            value={sortBy}
            onChange={handleSortChange}
          >
            <option value="views">Views</option>
            <option value="comments">Comments</option>
            <option value="name">Name</option>
          </select>
        </div>
        <div className="ml-4 flex items-center">
          <p className="text-gray-500 dark:text-gray-300 mr-2 text-sm">
            Filter by name:
          </p>
          <input
            type="text"
            className="mb-1 mt-2 w-90 rounded-full border dark:border-strokedark bg-transparent px-3 py-1 text-white"
            value={filterName}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-12 md:px-6 2xl:px-7.5">
        <div className="col-span-5 flex items-center mx-12">
          <p className="font-medium">TV Name</p>
        </div>
        <div className="col-span-1 hidden items-center sm:flex justify-center">
          <p className="text-sm font-medium">Clicks</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Views</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Comments</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Visibility</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Publish</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Manage</p>
        </div>
      </div>

      <div className="m-4 mt-2 flex items-center justify-center px-4">
        {loading ? (
          <RingLoader color="#36D7B7" loading={loading} size={100} />
        ) : (
          <div className="h-screen overflow-y-scroll">
            {filteredTVs.map((tv) => (
              <div
                key={tv._id}
                className="grid grid-cols-12 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-12 md:px-6 2xl:px-7.5"
              >
                <div className="col-span-5 flex items-center">
                  <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                    <div className="h-20.5 w-35 rounded-md">
                      <img src={tv.thumb_nail} alt={tv.name} className='rounded-lg' />
                    </div>
                    <div className="container mr-4">
                      <p className="text-sm text-black dark:text-white">
                        {tv.name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 hidden items-center sm:flex justify-center">
                  <p className="text-sm text-black dark:text-white">
                    {tv.views}
                  </p>
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <p className="text-sm text-black dark:text-white">
                  {
                      contentViews.filter(view => view.contentid === tv._id).length
                    }
                  </p>
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <p className="text-sm text-black dark:text-white">
                    {tv.comments}
                  </p>
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <Switch
                    color="success"
                    checked={tv.status.visibility}
                    onChange={() =>
                      handleToggleChange(tv._id, {
                        visibility: !tv.status.visibility,
                        publish: tv.status.publish!,
                      })
                    }
                  />
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <Switch
                    color="success"
                    checked={tv.status.publish}
                    onChange={() =>
                      handleToggleChange(tv._id, {
                        visibility: tv.status.visibility!,
                        publish: !tv.status.publish,
                      })
                    }
                  />
                </div>
                <div className="col-span-1 flex items-center justify-between gap-x-2 ml-8">
                  <Link
                    to={`/ManageVideoEdit/${tv._id}?category=${category}&i=${tv._id}`}
                  >
                    <EditIcon sx={{fontSize: 20}} />
                  </Link>
                  <Link
                    to={`/ManageVideoEdit/${tv._id}?category=${category}&i=${tv._id}`}
                  >
                    <BarChartIcon sx={{fontSize: 20}} />
                  </Link>
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      color="inherit"
                    >
                      <MoreVertIcon sx={{fontSize: 20}} />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      color="inherit"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: '20ch',
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          handlePromoteToTrendingClick(tv.program_id)
                        }
                      >
                        <ShowChart />
                        Promote to trending
                      </MenuItem>
                      <MenuItem>
                        <DeleteIcon />
                        Delete forever
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TVs;
