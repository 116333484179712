// import axios, { AxiosInstance } from 'axios';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { AxiosInstance } from '../common/Loader/axios.config';
import axios from 'axios';

class ManageLivetvService {
  private axiosInstance: AxiosInstance;
  dispatch = useDispatch();

  constructor() {
    // Initialize Axios instance with your base URL and headers
    this.axiosInstance = axios.create({
      baseURL: 'https://kanemaonline.com/tvs', // Update with your API base URL
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Add bearer token from local storage
      },
    });
  }

  async promoteToTrending(tvId: string): Promise<void> {
    try {
      console.log("Event ID", tvId);
      const body = {
        'status.promoted': true,  
      }
      // Fetch all events
      const response = await AxiosInstance.put(`/tvs/${tvId}`, body);

      console.log("Response", response);


      if(response.status == 200) {

        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'TV promoted to trending successfully!',
          showConfirmButton: false,
          timer: 2000, // Auto-close after 2 seconds
        });
      } else {
        throw new Error('Failed to promote event');
      }

      
    } catch (error) {
      console.log('the error is ', error);
      // Show error message
      Swal.fire({
        icon: 'error',
        title: 'Failed to promote content to trending',
        text: 'An error occurred while promoting the event.',
      });
      throw new Error('Failed to promote event to trending');
    }
  }

  async depromoteToTrending(tvId: string): Promise<void> {
    try {


      const body = {
        'status.promoted': false,  
      }
      // Fetch all events
      const response = await AxiosInstance.put(`/tvs/${tvId}`, body);


      if(response.status == 200) {

        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'Event demoted from trending successfully!',
          showConfirmButton: false,
          timer: 2000, // Auto-close after 2 seconds
        });
      } else {
        throw new Error('Failed to demote event');
      }

      
    } catch (error) {
      console.log('the error is ', error);
      // Show error message
      Swal.fire({
        icon: 'error',
        title: 'Failed to demote event from trending',
        text: 'An error occurred while demoting the event.',
      });
      throw new Error('Failed to demote event to trending');
    }
  }

  // async promoteToTrending(tvId: string): Promise<void> {
  //   try {
  //     // Fetch all tvs
  //     const response = await this.axiosInstance.get('');
  //     // Find the tv with the given tvId in the response data
  //     const tvsToUpdate = response.data;
  //     const tvToPromote = tvsToUpdate.find(
  //       (tv: any) => tv._id === tvId
  //     );
  //     if (!tvToPromote) {
  //       throw new Error('TV not found');
  //     }
  //     // Update all fetched tvs to "type" of "normal" within the "status" object
  //     await Promise.all(
  //       tvsToUpdate.map(async (tv: any) => {
  //         if (tv.status && tv.status.type === 'live') {
  //           await this.axiosInstance.put(`/${tv._id}`, {
  //             status: {
  //               type: 'normal',
  //               lifetime: tv.status.lifetime,
  //             },
  //           });
  //         }
  //       })
  //     );
  //     // Update the specific tv with the given tvId to "type" of "live" within the "status" object
  //     await this.axiosInstance.put(`/${tvId}`, {
  //       status: {
  //         type: 'live',
  //         lifetime: tvToPromote.status.lifetime,
  //       },
  //     });
  //     // Set isTrending to true in Redux store
  //     // Show success message
  //     Swal.fire({
  //       icon: 'success',
  //       title: 'TV promoted to trending successfully!',
  //       showConfirmButton: false,
  //       timer: 2000, // Auto-close after 2 seconds
  //     });
  //   } catch (error) {
  //     console.log('the error is ', error);
  //     // Show error message
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Failed to promote TV to trending',
  //       text: 'An error occurred while promoting the tv.',
  //     });
  //     throw new Error('Failed to promote TV to trending');
  //   }
  // }





}
export default ManageLivetvService;

