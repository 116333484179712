// src/components/VideoUploadForm.tsx

import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

interface Metadata {
  [key: string]: string; // Use string as the value type for all properties
}

const VideoUploadForm: React.FC = () => {
  const { register, handleSubmit, getValues } = useForm();

  const onSubmit = async (data: Metadata) => {
    console.log("upload started ...../");
    const thumbnailFile = getValues('thumbnail')[0];

    console.log(thumbnailFile);

    try {
      const formData = new FormData();

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }

      formData.append('files', thumbnailFile);

      const response = await axios.post('https://kanemaonline.com/events/new-live-event', formData);
      console.log('Event created:', response.data);
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" {...register('name')} />
      </div>
      <div>
        <label htmlFor="thumbnail">Thumbnail</label>
        <input type="file" id="thumbnail" {...register('thumbnail')} />
      </div>
      <button type="submit">Upload Video</button>
    </form>
  );
};

export default VideoUploadForm;
