import { Link } from 'react-router-dom';
import BrandOne from '../../images/brand/brand-01.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState, useRef } from 'react';
import User from '../../services/interfaces/user-interface';
import UserService from '../../services/user-service';
import CircularProgress from '@mui/material/CircularProgress';
import UserAnalysis from '../userAnalysis';
import UserRegistrationComparizon from '../weeklyComparizon';
import WeekelyUserStatus from '../Home/WeekelyUserStatus';
import dateTime from 'date-and-time';
import { IoMdArrowRoundForward } from "react-icons/io";
import Select from 'react-select';
import { set } from 'react-hook-form';
import MultiSelect from './Users/select_fields';
import Swal from 'sweetalert2';
import { AxiosInstance } from '../../common/Loader/axios.config';
import { MdDeleteForever } from "react-icons/md";
import { Tabs } from '@mui/material';
import { UserActivityPage } from './Users/user_activity';

const options = ["User Name", "UserID", "Email", "Phone", "Region", "Country", "City", "ISP", "Status"]

const ManageUsers: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const itemsPerPage = 1000;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [allCount, setAllCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [authorizedUsers, setAuthorizedUsers] = useState<User[]>([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [deactivatedCount, setDeactivatedCount] = useState(0);
  const [googleCount, setGoogleCount] = useState(0);
  const [appleCount, setAppleCount] = useState(0);

  useEffect(() => {
    async function fetchUsers() {
      setLoading(true); // Start loading

      try {
        const skip = (currentPage - 1) * itemsPerPage;
        const response = await UserService.getAllUsers();

        setUsers(response);

        const allUsers = response;

        const uniqueStatus = [...new Set(allUsers.map((user) => user.status.admin))];
        setStatus(uniqueStatus);

        setAllCount(allUsers.length);

        const activeUsersCount = allUsers.filter(
          (user) => user.status.admin === 'active' || user.status.admin === 'authorized'
        ).length;
        setActiveCount(activeUsersCount);

        const deactivatedUsers = allUsers.filter(
          (user) => user.status.admin === 'deactivated'
        );

        const authorizedUsersCount = allUsers.filter(
          (user) => user.status.admin === 'authorized'
        );

        setAuthorizedUsers(authorizedUsersCount);

        const isGoogleAuth = (appleid) => /^\d{21}$/.test(appleid);
        const isAppleAuth = (appleid) => /^\d{6}\..+\..+$/.test(appleid);
        
        const googleUsersCount = authorizedUsersCount.filter(
          (user) => user.appleid && isGoogleAuth(user.appleid)
        ).length;
        
        const appleUsersCount = authorizedUsersCount.filter(
          (user) => user.appleid && isAppleAuth(user.appleid)
        ).length;

        // Set the counts
        setGoogleCount(googleUsersCount);
        setAppleCount(appleUsersCount);

        const deactivatedUsersCount = allUsers.filter(
          (user) => user.status.admin === 'deactivated'
        ).length;
        setDeactivatedCount(deactivatedUsersCount);



        const pendingUsersCount = allUsers.filter(
          (user) => user.status.admin === 'pending'
        ).length;
        setPendingCount(pendingUsersCount);

        const totalUsers = 2691;
        setTotalPages(Math.ceil(totalUsers / itemsPerPage));
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false); 
      }
    }

    fetchUsers();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage < 1) {
      setCurrentPage(1);
    } else if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    // Filter the users based on email or phone
    const filtered = users.filter((user) => {
      const emailMatch =
        user.email &&
        user.email.toLowerCase().includes(filterText.toLowerCase());
      const phoneMatch =
        user.phone &&
        user.phone.toLowerCase().includes(filterText.toLowerCase());
      const nameMatch =
        user.username &&
        user.username.toLowerCase().includes(filterText.toLowerCase());

      // Return true if either email or phone matches the filter text
      return emailMatch || phoneMatch || nameMatch;
    });

    setFilteredUsers(filtered);
  }, [users, filterText]);

  const handleSort = (column: keyof User) => {
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    });
    setFilteredUsers(sortedUsers);
  };

  const statusOptions = [
    { value: 'all', label: 'All' },
    ...status.map((status) => ({
      value: status,
      label: status,
    })),
  ];

  const handleStatusChange = (option) => {
    setSelectedStatus(option);
    if (option.value === 'all') {
      setFilteredUsers(filteredUsers);
    } else {
      const filtered = filteredUsers.filter((user) => user.status.admin === option.value);
      setFilteredUsers(filtered);
    }
  };

  const handleTypeChange = (option) => {
    setSelectedStatus(option);
    if (option.value === 'all') {
      setFilteredUsers(filteredUsers);
    } else if (option.value === 'google') {
      const filtered = authorizedUsers.filter((user) => !('appleid' in user));
      setFilteredUsers(filtered);
    } else if (option.value === 'apple') {
      const filtered = authorizedUsers.filter((user) => 'appleid' in user);
      setFilteredUsers(filtered);
    }
  };

  const [columnWidths, setColumnWidths] = useState({
    Date: 70,
    User: 150,
    UserID: 150,
    Email: 200,
    Phone: 50,
    Region: 50,
    AppleId: 150,
    Location: 100,
    Country: 50,
    ISP: 50,
    Status: 50,
    Action: 50,
  });

  const resizeHandleRef = useRef(null);
  // const [selectedFields, setSelectedFields] = useState(Object.keys(columnWidths));
  const [selectedFields, setSelectedFields] = useState([
    'Date',
    'User',
    'UserID',
    // 'AppleId',
    'Email',
    'Phone',
    'Region',
    'Status'
  ]);

  // Handle mousedown on the resize handle
  const onMouseDown = (e, columnName) => {
    e.preventDefault();
    const startX = e.clientX;
    const startWidth = columnWidths[columnName];

    let animationFrameId;

    const onMouseMove = (e) => {
      // Cancel the previous frame
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }

      // Request a new animation frame
      animationFrameId = requestAnimationFrame(() => {
        const newWidth = Math.max(50, startWidth + (e.clientX - startX));
        setColumnWidths((prevWidths) => ({
          ...prevWidths,
          [columnName]: newWidth,
        }));
      });
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      // Cancel any pending animation frame
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const handleFieldChange = (updatedFields) => {
    setSelectedFields(updatedFields);
  };

  const handleDeleteAccount = async (userId) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete User!',
    });

    // Check if the user confirmed the action
    if (result.isConfirmed) {
      try {
        const response = await AxiosInstance.delete(`/users/${userId}`);
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'User deleted successfully!',
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          console.error('Failed to delete User', response.data);
        }
      } catch (error) {
        console.error('Failed to delete User', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete User!',
          text: 'Please try again later.',
        });
      }
    }
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  return (
    <div className="w-[1200px] rounded-sm bg-transparent px-2 pb-1.5 pt-6 dark:border-strokedark sm:px-7.5 xl:pb-1">
      {/* <h2 className="mb-4 border-b text-2xl font-semibold">User Analytics</h2> */}
      <div className="mb-4 flex flex-col text-center">

        {/* <div className='my-3'>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            {['Users Summary', 'Users Activity'].map((label, index) => (
              <div
                key={index}
                className={`tab-item px-3 text-sm rounded-full bg-brand-background py-2 mx-2 border-[1.3px] border-brand-stroke-color shadow-lg cursor-pointer ${value === index ? 'py-1 border border-white text-white font-bold rounded-full bg-transparent' : ''}`}
                onClick={() => handleChange(null, index)}
              >
                <span>{label}</span>
              </div>
            ))}
          </Tabs>
        </div> */}

        {/* {value == 0 && ( */}
        
        <div>

          <div className="flex items-center justify-between">

            <div className="text-black border border-brand-stroke-color rounded-lg p-1" id="select">

              <MultiSelect
                options={Object.keys(columnWidths).filter((key) => key !== 'Action')}
                label="Select Fields"
                selectedOptions={selectedFields}
                onChange={handleFieldChange}
              />
            </div>

            <input
              type="text"
              placeholder="Search User..."
              className="w-full rounded-md border border-brand-stroke-color bg-transparent px-3 py-2 text-white sm:w-2/3 md:w-1/2 lg:w-1/3"
              value={filterText}
              onChange={(event) => setFilterText(event.target.value)}
            />
          </div>

          <div className='flex items-center justify-between my-2'>
            <div className='flex items-center gap-x-4'>
              <div className='text-white text-xs rounded border border-white px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                onClick={() => handleStatusChange({ value: 'all' })}>All: {allCount}</div>
              <div className='text-success text-xs rounded border border-success px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                onClick={() => handleStatusChange({ value: 'active' })}>Active: {activeCount}</div>
              <div className='text-warning text-xs rounded border border-warning px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                onClick={() => handleStatusChange({ value: 'pending' })}>Pending: {pendingCount}</div>
              <div className='text-danger text-xs rounded border border-danger px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                onClick={() => handleStatusChange({ value: 'deactivated' })}>Deactivated: {deactivatedCount}</div>
              <div className='text-success text-xs rounded border border-success px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                onClick={() => handleTypeChange({ value: 'google' })}>Google: {googleCount}</div>
              <div className='text-success text-xs rounded border border-success px-4 py-2 rounded-lg w-fit font-bold my-2 cursor-pointer hover:scale-105'
                onClick={() => handleTypeChange({ value: 'apple' })}>Apple: {appleCount}</div>
            </div>

            <div className='flex items-center gap-x-2'>

              <p className='text-white text-sm'>Sort by:</p>

              <div className="w-[40vh] text-black border border-brand-stroke-color rounded-lg p-1" id="select">

                <Select
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statusOptions}
                  placeholder="Select Status..."
                />
              </div>

            </div>

          </div>
          <div className="overflow-x-auto">
            <table className="table-auto w-full bg-brand-background shadow-md rounded-lg">
              <thead>
                <tr className="text-white text-sm text-center">
                  {selectedFields.map((columnName) => (
                    <th
                      key={columnName}
                      className="p-4 relative border-r border-brand-stroke-color"
                      style={{ width: `${columnWidths[columnName]}px` }}
                    >
                      {columnName}
                      <div
                        ref={resizeHandleRef}
                        className="resize-handle absolute right-0 top-0 h-full w-2 cursor-col-resize"
                        onMouseDown={(e) => onMouseDown(e, columnName)}
                      />
                    </th>
                  ))}
                  <th
                    key="Action"
                    className="p-4 relative border-r border-brand-stroke-color"
                    style={{ width: `${columnWidths['Action']}px` }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={selectedFields.length} className="py-4 text-center">
                      <CircularProgress />
                    </td>
                  </tr>
                ) : (
                  filteredUsers.map((user) => (
                    <tr key={user._id} className="hover:bg-gray-100 text-white text-xs text-center">
                      {selectedFields.map((columnName) => (
                        <td key={columnName} className="py-4 px-1">
                          {columnName === 'Date' &&
                            dateTime.format(new Date(Date.parse(user.created_at)), 'DD/MM/YY HH:mm ')}
                          {columnName === 'User' && user.username}
                          {columnName === 'UserID' && user._id}
                          {columnName === 'AppleId' && user.appleid}
                          {columnName === 'Email' && user.email}
                          {columnName === 'Phone' && user.phone}
                          {columnName === 'Region' && user.region}
                          {columnName === 'Country' && user.country}
                          {columnName === 'Location' && (
                            <div className='flex items-center justify-center gap-x-2'>
                              <p>{user.city}</p>
                              <p>{user.country}</p>
                            </div>
                          )}
                          {columnName === 'ISP' && user.isp}
                          {columnName === 'Status' && (
                            <p
                              className={`text-${user.status.admin === 'active'
                                ? 'success'
                                : user.status.admin === 'authorized'
                                  ? 'success'
                                  : user.status.admin === 'pending'
                                    ? 'warning'
                                    : 'danger'
                                }`}
                            >
                              {user.status.admin}
                            </p>
                          )}
                        </td>

                      ))}
                      <td key="Action" className="py-4 px-1">
                        <div className='flex items-center justify-center gap-x-1'>
                          <Link to={`/user?JSXKU=${user?._id || ''}`}>
                            <IoMdArrowRoundForward className="ml-6" size={18} />
                          </Link>

                          {/* <MdDeleteForever size={20} onClick={() => handleDeleteAccount(user._id)} /> */}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>

            </table>
          </div>
        </div>
        {/* )} */}

        {/* {value == 1 && (
          <UserActivityPage />
        )} */}

      </div>
    </div>
  );
};

export default ManageUsers;
