('use client');
import { Card, Typography } from '@material-tailwind/react';
import { Select } from 'flowbite-react';
import * as React from 'react';
import { AxiosInstance } from '../../common/Loader/axios.config';
import { useEffect, useState } from 'react';
import dateTime from 'date-and-time';
import { Tabs, Tab, TextField } from '@mui/material';
import PaymentSummary from './Payments/PaymentSummary';
import ManagePackages from './ManagePackages';
import Gateway from './Payments/Gateway';
import { Provider } from 'react-redux';
import VideoProviders from './Videos/Provider';
import TVsService from '../../services/tvs-service';

const ITEM_HEIGHT = 48;

const TABLE_HEAD = [
  'Date',
  'User Name',
  'User ID',
  'Package',
  'Amount',
  'Gateway',
  'Operator',
  'Status',
];

const TABLE_ROWS = [
  {
    user: 'CJ Designs',
    packages: 'Supa',
    date: '23 April 2023',
    content: 'All',
    period: 36,
    amount: 1500,
    MOP: 'Airtel Money',
    status: 'Active',
    expire: '23 April 2024',
  },
];

interface Payment {
  created_at: string;
  username: string;
  userid: string;
  mobile_operator: string;
  phone: string;
  email: string;
  _id: string;
  product: string;
  amount: number;
  currency: string;
  payment_gateway: string;
  status: string;
}

interface PaymentResponse {
  data: Payment[];
}

const ManagePayments: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [methods, setMethods] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    AxiosInstance.get<PaymentResponse>('/billing/payments').then((response) => {
      const allPayments = response.data;
      const sortedPayments = allPayments.sort((a, b) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });

      setPayments(sortedPayments);
    });
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }


  const [loggedInProvider, setLoggedInProvider] = useState(null);
  const [tvs, setTVs] = useState([]);

  useEffect(() => {
    async function fetchTVs() {
      try {
        setLoading(true);
        const fetchedTVs = await TVsService.getAllTVs();
        setTVs(fetchedTVs);
      } catch (error) {
        console.error("Error fetching TVs:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchTVs();
  }, []);

  useEffect(() => {
    const storedProvider = JSON.parse(localStorage.getItem("provider"));
    if (storedProvider) {
      setLoggedInProvider(storedProvider);
    }
  }, []);

  return (
    <div className=" px-0 w-[1200px] mx-auto">
      <div className="flex justify-between items-center py-4">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {['Summary', 'Packages', 'Gateways'].map((label, index) => (
            <div
              key={index}
              className={`tab-item px-3 text-sm rounded-full bg-brand-background py-2 mx-2 border-[1.3px] border-brand-stroke-color shadow-lg cursor-pointer ${value === index ? 'py-2 border border-white rounded-full bg-transparent' : ''}`}
              onClick={() => handleChange(null, index)}
            >
              <span>{label}</span>
            </div>
          ))}
        </Tabs>


      </div>

      {value === 0 && loggedInProvider &&
        <PaymentSummary loggedInProvider={loggedInProvider} tvs={tvs} />
      }
      {value === 1 && <ManagePackages />}
      {value === 2 && <Gateway />}

    </div>
  );
};

export default ManagePayments;
