import DragVideo from "../components/Upload/DragVideo";
const Upload = () => {
    return (
      <>
        <DragVideo/>
      </>
    );
  };
  
  export default Upload;

  