import React, { useState, useEffect } from 'react';

interface ServerConfig {
  server: string;
  weight: string;
}

const RadioButton: React.FC<{
  server: ServerConfig;
  onUpdate: (weight: string) => void;
  selectedServer: string | null;
  onRadioClick: () => void;
}> = ({ server, onUpdate, selectedServer, onRadioClick }) => {
  const isSelected = server.server === selectedServer;

  const handleRadioChange = () => {
    const newMainWeight = 'fail_timeout=6000s max_fails=1';
    const newBackupWeight = 'backup';

    onUpdate(server.weight === newMainWeight ? newBackupWeight : newMainWeight);
    onRadioClick();
  };

  return (
    <label className="inline-flex cursor-pointer items-center space-x-2">
      <input
        type="radio"
        checked={isSelected}
        onChange={handleRadioChange}
        className="form-radio text-blue-500"
      />
      <span
        className={`text-sm ${
          server.weight === 'fail_timeout=6000s max_fails=1'
            ? 'text-green-500'
            : 'text-red-500'
        }`}
      >
        {server.server.includes('tv1') ? 'TV1' : 'TV2'}
      </span>
    </label>
  );
};

const ServerSwitcher: React.FC = () => {
  // Use static server data instead of fetching from the server
  const [serverStatus, setServerStatus] = useState<ServerConfig[]>([
    { server: 'tv1.kanemaonline.com:443', weight: 'fail_timeout=6000s max_fails=1' },
    { server: 'tv2.kanemaonline.com:443', weight: 'backup' },
  ]);
  
  const [selectedServer, setSelectedServer] = useState<string | null>('tv1.kanemaonline.com:443');

  const handleServerSelect = (server: string) => {
    setSelectedServer(server === selectedServer ? null : server);
  };

  return (
    <div className="server-switcher bg-gray-800 text-white p-4 rounded-lg">
      <div className="flex items-center space-x-2">
        {serverStatus.map((server) => (
          <RadioButton
            key={server.server}
            server={server}
            onUpdate={(weight) => console.log(`${server.server} weight updated:`, weight)}
            selectedServer={selectedServer}
            onRadioClick={() => handleServerSelect(server.server)}
          />
        ))}
      </div>
    </div>
  );
};

export default ServerSwitcher;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// interface ServerConfig {
//   server: string;
//   weight: string;
// }

// const RadioButton: React.FC<{
//   server: ServerConfig;
//   onUpdate: (weight: string) => void;
//   selectedServer: string | null;
//   onRadioClick: () => void;
// }> = ({ server, onUpdate, selectedServer, onRadioClick }) => {
//   const isSelected = server.server === selectedServer;

//   const handleRadioChange = async () => {
//     const newMainWeight = 'fail_timeout=6000s max_fails=1';
//     const newBackupWeight = 'backup';

//     try {
//       const response = await axios.put(
//         'https://admin.kanemaonline.com/lb/update-config',
//         {
//           servers: [
//             `${server.server} ${
//               server.weight === newMainWeight ? newBackupWeight : newMainWeight
//             }`,
//             `${
//               server.server === 'tv1.kanemaonline.com:443'
//                 ? 'tv2.kanemaonline.com:443'
//                 : 'tv1.kanemaonline.com:443'
//             } ${
//               server.weight === newMainWeight ? newMainWeight : newBackupWeight
//             }`,
//           ],
//         }
//       );
//       console.log('Server response:', response);

//       if (response.data.status === 'success') {
//         onUpdate(
//           server.weight === newMainWeight ? newBackupWeight : newMainWeight
//         );
//         onRadioClick();
//       }
//     } catch (error) {
//       console.error('Error toggling server:', error);
//     }
//   };

//   return (
//     <label className="flex cursor-pointer items-center space-x-2">
//       <input
//         type="radio"
//         checked={isSelected}
//         onChange={handleRadioChange}
//         className="form-radio text-blue-500"
//       />
//       <span
//         className={`text-sm ${
//           server.weight === 'fail_timeout=6000s max_fails=1'
//             ? 'text-green-500'
//             : 'text-red-500'
//         }`}
//       >
//         {server.server.includes('tv1') ? 'TV1' : 'TV2'}
//       </span>
//     </label>
//   );
// };

// const ServerSwitcher: React.FC = () => {
//   const [serverStatus, setServerStatus] = useState<ServerConfig[]>([]);
//   const [selectedServer, setSelectedServer] = useState<string | null>(null);

//   useEffect(() => {
//     async function fetchServerStatus() {
//       try {
//         const response = await axios.get('https://admin.kanemaonline.com/lb');
//         const { message } = response.data;

//         const serverConfigs: ServerConfig[] = message.map((msg: string) => {
//           const parts = msg.split(' ');
//           return {
//             server: parts[0],
//             weight: parts[1],
//           };
//         });

//         console.log('Server status:', serverConfigs);

//         setServerStatus(serverConfigs);
//       } catch (error) {
//         console.error('Error fetching server status:', error);
//       }
//     }

//     fetchServerStatus();
//   }, []);

//   const handleServerSelect = (server: string) => {
//     setSelectedServer(server === selectedServer ? null : server);
//   };

//   return (
//     <div className="server-switcher">
//       <div className="space-y-2">
//         {serverStatus.map((server) => (
//           <RadioButton
//             key={server.server}
//             server={server}
//             onUpdate={(weight) =>
//               console.log(`${server.server} weight updated:`, weight)
//             }
//             selectedServer={selectedServer}
//             onRadioClick={() => handleServerSelect(server.server)}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ServerSwitcher;

// interface ServerConfig {
//   server: string;
//   weight: string;
// }

// const SwitchButton: React.FC<{
//   server: ServerConfig;
//   onUpdate: (weight: string) => void;
// }> = ({ server, onUpdate }) => {
//   const [isMain, setIsMain] = useState(!server.weight.includes('backup'));

//   const handleToggle = async () => {
//     const newMainWeight = isMain ? 'backup' : 'fail_timeout=6000s max_fails=1';
//     const newBackupWeight = isMain
//       ? 'fail_timeout=6000s max_fails=1'
//       : 'backup';

//     try {
//       const response = await axios.put(
//         'https://kanemaonline.com/lb/update-config',
//         {
//           servers: [
//             `server ${server.server} ${newMainWeight}`,
//             `server ${
//               server.server === 'tv1.kanemaonline.com:443'
//                 ? 'tv2.kanemaonline.com:443'
//                 : 'tv1.kanemaonline.com:443'
//             } ${newBackupWeight}`,
//           ],
//         }
//       );
//       console.log('Server response:', response);

//       if (response.data.status === 'success') {
//         setIsMain(!isMain);
//         onUpdate(newMainWeight);
//       }
//     } catch (error) {
//       console.error('Error toggling server:', error);
//     }
//   };

//   return (
//     <div className="col-span-1 flex items-center">
//       <Switch
//         color="success"
//         checked={isMain}
//         onChange={handleToggle}
//         inputProps={{ 'aria-label': 'Toggle server' }}
//       />
//       {server.server.includes('tv1') ? 'TV1' : 'TV2'}
//     </div>
//   );
// };

// const ServerSwitcher: React.FC = () => {
//   const [serverStatus, setServerStatus] = useState<ServerConfig[]>([]);

//   useEffect(() => {
//     async function fetchServerStatus() {
//       try {
//         const response = await axios.get('https://kanemaonline.com/lb');
//         const { message } = response.data;

//         const serverConfigs: ServerConfig[] = message.map((msg: string) => {
//           const parts = msg.split(' ');
//           return {
//             server: parts[0],
//             weight: parts[1],
//           };
//         });

//         console.log('Server status:', serverConfigs);

//         setServerStatus(serverConfigs);
//       } catch (error) {
//         console.error('Error fetching server status:', error);
//       }
//     }

//     fetchServerStatus();
//   }, []);

//   return (
//     <div className="server-switcher">
//       <ToggleButtonGroup
//         value={null}
//         exclusive
//         aria-label="TV switches"
//         className="toggle-group"
//       >
//         {serverStatus.map((server) => (
//           <SwitchButton
//             key={server.server}
//             server={server}
//             onUpdate={(weight) =>
//               console.log(`${server.server} weight updated:`, weight)
//             }
//           />
//         ))}
//       </ToggleButtonGroup>
//     </div>
//   );
// };

// export default ServerSwitcher;

// const SwitchButton: React.FC<{
//   server: ServerConfig;
//   onUpdate: (weight: string) => void;
// }> = ({ server, onUpdate }) => {
//   const [isOn, setIsOn] = useState(server.weight.includes('fail_timeout'));

//   const handleToggle = async () => {
//     const newWeight = isOn ? 'backup' : 'fail_timeout=6000s max_fails=1';

//     try {
//       const response = await axios.put(
//         'https://kanemaonline.com/lb/update-config',
//         {
//           servers: [`server ${server.server} ${newWeight}`],
//         }
//       );
//       console.log('Server response:', response);

//       if (response.data.status === 'success') {
//         setIsOn(!isOn);
//         onUpdate(newWeight);
//       }
//     } catch (error) {
//       console.error('Error toggling server:', error);
//     }
//   };

//   return (
//     <div className="col-span-1 flex items-center">
//       <Switch
//         color="success"
//         checked={isOn}
//         onChange={handleToggle}
//         inputProps={{ 'aria-label': 'Toggle server' }}
//       />
//       {server.server.includes('tv1') ? 'TV1 W' : 'TV2 L'}
//     </div>
//   );
// };

// const ServerSwitcher: React.FC = () => {
//   const [serverStatus, setServerStatus] = useState<ServerConfig[]>([]);

//   useEffect(() => {
//     async function fetchServerStatus() {
//       try {
//         const response = await axios.get('https://kanemaonline.com/lb');
//         const { message } = response.data;

//         const serverConfigs: ServerConfig[] = message.map((msg: string) => {
//           const parts = msg.split(' ');
//           return {
//             server: parts[0],
//             weight: parts[1],
//           };
//         });

//         console.log('Server status:', serverConfigs);

//         setServerStatus(serverConfigs);
//       } catch (error) {
//         console.error('Error fetching server status:', error);
//       }
//     }

//     fetchServerStatus();
//   }, []);

//   const handleToggleAll = async () => {
//     try {
//       const updatedServerConfigs: string[] = [];

//       serverStatus.forEach((server) => {
//         const newWeight = server.weight.includes('fail_timeout')
//           ? 'backup'
//           : 'fail_timeout=6000s max_fails=1';

//         updatedServerConfigs.push(`server ${server.server} ${newWeight}`);
//       });

//       const requestBody = updatedServerConfigs.join('\n');

//       const response = await axios.put(
//         'https://kanemaonline.com/lb/update-config',
//         requestBody,
//         {
//           headers: {
//             'Content-Type': 'text/plain',
//           },
//         }
//       );

//       console.log('Update all servers response:', response);

//       if (response.data.status === 'success') {
//         const updatedServerStatus = serverStatus.map((server) => ({
//           ...server,
//           weight: server.weight.includes('fail_timeout')
//             ? 'backup'
//             : 'fail_timeout=6000s max_fails=1',
//         }));
//         setServerStatus(updatedServerStatus);
//       }
//     } catch (error) {
//       console.error('Error updating servers:', error);
//     }
//   };

//   return (
//     <div className="server-switcher">
//       <div className="toggle-group">
//         <button onClick={handleToggleAll}>Toggle All Servers</button>
//       </div>
//       <ToggleButtonGroup
//         value={null}
//         exclusive
//         aria-label="TV switches"
//         className="toggle-group"
//       >
//         {serverStatus.map((server) => (
//           <SwitchButton
//             key={server.server}
//             server={server}
//             onUpdate={(weight) =>
//               console.log(`${server.server} weight updated:`, weight)
//             }
//           />
//         ))}
//       </ToggleButtonGroup>
//     </div>
//   );
// };

// interface ServerConfig {
//   server: string;
//   weight: string;
// }

// const SwitchButton: React.FC<{
//   server: ServerConfig;
//   onUpdate: (weight: string) => void;
// }> = ({ server, onUpdate }) => {
//   const [isOn, setIsOn] = useState(server.weight === '10');

//   const handleToggle = async () => {
//     const newWeight = isOn ? 'backup' : 'weight=10';

//     try {
//       const response = await axios.put(
//         'https://kanemaonline.com/lb/update-config',
//         {
//           servers: [`${server.server} ${newWeight}`],
//         }
//       );
//       console.log('Server  response :', response);

//       if (response.data.status === 'success') {
//         setIsOn(!isOn);
//         onUpdate(newWeight);
//       }
//     } catch (error) {
//       console.error('Error toggling server:', error);
//     }
//   };

//   return (
//     <div className="col-span-1 flex items-center">
//       <Switch
//         color="success"
//         checked={isOn}
//         onChange={handleToggle}
//         inputProps={{ 'aria-label': 'Toggle server' }}
//       />
//       {server.server.includes('tv1') ? 'TV1 W' : 'TV2 L'}
//     </div>
//   );
// };

// const ServerSwitcher: React.FC = () => {
//   const [serverStatus, setServerStatus] = useState<ServerConfig[]>([]);

//   useEffect(() => {
//     async function fetchServerStatus() {
//       try {
//         const response = await axios.get('https://kanemaonline.com/lb');
//         const { message } = response.data;

//         const serverConfigs: ServerConfig[] = message.map((msg: string) => {
//           const parts = msg.split(' ');
//           return {
//             server: parts[0],
//             weight: parts[1],
//           };
//         });

//         console.log('server status :', serverConfigs);

//         setServerStatus(serverConfigs);
//       } catch (error) {
//         console.error('Error fetching server status:', error);
//       }
//     }

//     fetchServerStatus();
//   }, []);

//   return (
//     <div className="server-switcher">
//       <ToggleButtonGroup
//         value={null}
//         exclusive
//         aria-label="TV switches"
//         className="toggle-group"
//       >
//         {serverStatus.map((server) => (
//           <SwitchButton
//             key={server.server}
//             server={server}
//             onUpdate={(weight) =>
//               console.log(`${server.server} weight updated:`, weight)
//             }
//           />
//         ))}
//       </ToggleButtonGroup>
//     </div>
//   );
// };

// export default ServerSwitcher;

//   server tv1.kanemaonline.com:443 fail_timeout=6000s max_fails=1;
//   server tv2.kanemaonline.com:443 backup;

// {

//   server tv1.kanemaonline.com:443 fail_timeout=6000s max_fails=1;
//   server tv2.kanemaonline.com:443 backup;
// }
