import { useState } from 'react';
import dateTime from 'date-and-time';


const PaymentModal = ({ isOpen, payment, setIsOpen }) => {
    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        >
            <div className="flex flex-col gap-4 justify-center bg-boxdark p-4 rounded-lg text-white shadow-xl" style={{ width: '700px' }}>
                <p className='text-xl text-white mx-auto'>Payment Details</p>
                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>UserName:</p>
                    <p className='text-white'>{payment?.username}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>User ID:</p>
                    <p className='text-white'>{payment?.userid}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>Payer ID:</p>
                    <p className='text-white'>{payment?.payerid || "-"}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>Payment ID:</p>
                    <p className='text-white'>{payment?._id}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>Payment Reference:</p>
                    <p className='text-white'>{payment?.payment_reference}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>Subscription Reference:</p>
                    <p className='text-white'>{payment?.subscription_reference}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>Package:</p>
                    <p className='text-white'>{payment?.product}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>Date:</p>
                    <p className='text-white'>                                {dateTime.format(
                        new Date(Date.parse(payment.created_at)),
                        'DD MMM, YYYY at hh:mm A'
                    )}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>Amount:</p>
                    <p className='text-white'>{payment?.currency} {payment?.amount}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>Gateway:</p>
                    <p className='text-white'>{payment?.payment_gateway}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>Operator:</p>
                    <p className='text-white'>{payment?.mobile_operator}</p>
                </div>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-white'>Status:</p>
                    <p className={`
                        ${payment?.status == 'COMPLETED' ? 'text-success' 
                        : payment?.status == 'PENDING' ? 'text-warning' : 'text-danger'}`}>{payment?.status}</p>
                </div>

                {payment?.failure_reason && (
                    <div className='w-full flex items-center justify-between'>
                        <p className='text-danger'>Reason:</p>
                        <p className='text-danger'>{payment?.failure_reason}</p>
                    </div>
                )}

                <div className='bg-danger rounded-full px-4 py-2 w-fit mx-auto text-white cursor-pointer'
                onClick={() => setIsOpen(false)}>Close</div>
            </div>
        </div>
    );
};

export default PaymentModal;