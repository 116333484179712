import { useNavigate } from 'react-router-dom';
import UserActivity from '../components/User/UserActivity';
import UserInfo from '../components/User/UserInfo';
import { isLoggedIn } from '../services/isLoggedInService';
import React from 'react';

const User = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const userIsLoggedIn = isLoggedIn();

    if (!userIsLoggedIn) {
      // If the user is not logged in, navigate to the sign-in page
      navigate('/auth/signin');
    }
  }, [navigate]);
  return (
    <div className="mt-1 grid grid-cols-10 gap-2 md:mt-1 md:gap-3 2xl:mt-3.5 2xl:gap-7.5">
      <div className="col-span-4 xl:col-span-4">
        <div className="grid grid-cols-1 gap-2">
          <UserInfo />
        </div>
      </div>
      <div className="col-span-5 xl:col-span-6">
        <UserActivity />
      </div>
    </div>
  );
};

export default User;
