// reducers.js
const initialState = {
  user: null,
  refresh_token: null,
  accessToken: null,
  error: null,
};

const rootReducer = (state = initialState, action: { type: any; payload: { user: any; tk: { refresh_token: any; accessToken: any; }; }; }) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        user: action.payload.user,
        refresh_token: action.payload.tk.refresh_token,
        accessToken: action.payload.tk.accessToken,
        error: null,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        user: null,
        refresh_token: null,
        accessToken: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
