import { configureStore, combineReducers } from '@reduxjs/toolkit';
import uploadReducer from './uploadSlice';
import authReducer from '../redux/reducers/authReducer'; // Import the authReducer from authSlice.ts
// import otherReducer from './reducers/otherReducer'; // Import otherReducer from the existing setup

const rootReducer = combineReducers({
  upload: uploadReducer,
  auth: authReducer,
  // other: otherReducer, // Add otherReducer to the root reducer
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
