import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import { Link } from "react-router-dom";


const slideToLeft = (): void => {
  const slider: HTMLElement | null = document.getElementById("Trendslider");
  if (slider) {
    slider.scrollLeft = slider.scrollLeft - 500;
  }
};

const slideToRight = (): void => {
  const slider: HTMLElement | null = document.getElementById("Trendslider");
  if (slider) {
    slider.scrollLeft = slider.scrollLeft + 500;
  }
};

const SubscribedEvents = ({ topPackage, packageCount, totalSubs }) => {
  return (
    <div className="rounded-xl border border-stroke bg-white px-6 py-4 shadow-default dark:border-strokedark dark:bg-brand-background">
      <Link to="/videos">
        <h4 className="mb-6 text-center text-sm text-black dark:text-white">
          Top Subscribed Package <KeyboardDoubleArrowRightOutlinedIcon />
        </h4>
      </Link>
      <div className="flex flex-row text-black dark:text-white sm:flex-row">
        {/* <MdChevronLeft onClick={slideToLeft} size={30} /> */}
        <div className="scroll no-scrollbar flex items-center overflow-x-scroll scroll-smooth">
          <div id="Trendslider" className="col-span-5 flex items-center">
            <div className="flex flex-col gap-5 sm:flex-row sm:items-center">
              <div className="h-20.5  rounded-md">
                <Stack spacing={2} direction="row">
                  <CircularProgress
                    size={50}
                    variant="determinate"
                    value={((packageCount / totalSubs) * 100)}
                  />
                </Stack>
              </div>
              <div className="container mr-4">
                <p className="text-xs text-black dark:text-white">
                  {topPackage}
                </p>
                <h4 className="mt-2 text-title-sm font-bold text-black dark:text-white">
                  {/* {packageCount} ({(packageCount / totalSubs) * 100}%) */}
                  {packageCount} (
                  {((packageCount / totalSubs) * 100).toFixed(2)}%)
                </h4>
              </div>
            </div>
          </div>
        </div>
        {/* <MdChevronRight onClick={slideToRight} size={30} /> */}
      </div>
    </div>
  );
};

export default SubscribedEvents;
