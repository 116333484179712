import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import upload from '../../images/Upload icon.png'

interface DragVideoProps {}

const DragVideo: React.FC<DragVideoProps> = () => {
  const getFileName = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target as HTMLInputElement;
    document.getElementById('fileName')!.innerHTML = fileInput.value.split('\\').pop()!;
  };

  return (
    <>
      <div className="container dark:bg-[#233040] bg-white rounded-lg">
        <form className='mx-auto py-20 w-1/3' action=''>
          <div className=''>
            <div className='mt-2 flex justify-center rounded-lg border border-dashed border-green-900 px-6 py-40'>
              <div className='text-center'>
                <div className='mx-auto h-12 w-12'>
                  <img src={upload} alt='' />
                </div>
                <div className='mt-4 flex text-sm leading-6 text-gray-600'>
                  <p className='pl-1'>drag and drop or</p>
                  <label htmlFor='file-upload' className='relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500'>
                    <span className='ml-2 text-success'>Browse</span>
                    <input id='file-upload' name='file-upload' type='file' className='sr-only' onChange={getFileName} />
                  </label>
                </div>
                {/* <p className='text-xs leading-5 text-gray-600'>PNG, JPG, GIF up to 10MB</p> */}
              </div>
            </div>
            <Link to='/ContinueUploading'>
              <button className='w-full bg-black border text-white py-2 mt-4 rounded-full hover:bg-success'>Next</button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default DragVideo;