import React, { useState } from "react";

const ProviderFields = ({ options, label, onChange, selectedOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
//   const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];

    onChange(updatedOptions); // Notify parent about the change
  };


  return (
    <div className="relative w-[35vh]">
      <button
        onClick={toggleDropdown}
        className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg flex justify-between items-center"
      >
        {label}
        <span className="ml-2">{isOpen ? "▲" : "▼"}</span>
      </button>
      {isOpen && (
        <div className="absolute mt-2 w-full bg-brand-background text-white rounded-lg shadow-lg z-50">
          {options.map((option, index) => (
            <label
              key={index}
              className="flex items-center p-2 hover:bg-gray-600 cursor-pointer"
            >
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
                checked={selectedOptions.includes(option)}
                onChange={() => handleSelect(option)}
              />
              <span className="ml-2">{option}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProviderFields;
