import { React, useState, useRef, useEffect } from 'react';
import { IoMdAddCircleOutline } from "react-icons/io";
import CreateProviderForm from '../Payments/create_provider';
import CreateProviderModal from './create_provider_modal';
import { FaArrowCircleRight } from "react-icons/fa";
import ProviderModal from './provider_modal';
import { AxiosInstance } from '../../../common/Loader/axios.config';
import dateTime from 'date-and-time';
import VodService from '../../../services/vods-service';
import EventService from '../../../services/event-service';
import TVsService from '../../../services/tvs-service';
import ProviderFields from './provider_fields';
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from 'sweetalert2';



const VideoProviders = () => {

    const [isEditing, setIsEditing] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [openProvider, setOpenProvider] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [providers, setProviders] = useState([])

    const [searchTerm, setSearchTerm] = useState('');

    const [columnWidths, setColumnWidths] = useState({
        Date: 100,
        Logo: 100,
        Provider: 200,
        ProviderID: 200,
        Email: 100,
        Phone: 150,
        Action: 50
    });

    const resizeHandleRef = useRef(null);

    const [selectedFields, setSelectedFields] = useState([
        'Date',
        'Logo',
        'Provider',
        'ProviderID',
        'Email',
        'Phone',
    ]);

    // Handle mousedown on the resize handle
    const onMouseDown = (e, columnName) => {
        e.preventDefault();
        const startX = e.clientX;
        const startWidth = columnWidths[columnName];

        let animationFrameId;

        const onMouseMove = (e) => {
            // Cancel the previous frame
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }

            // Request a new animation frame
            animationFrameId = requestAnimationFrame(() => {
                const newWidth = Math.max(50, startWidth + (e.clientX - startX));
                setColumnWidths((prevWidths) => ({
                    ...prevWidths,
                    [columnName]: newWidth,
                }));
            });
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            // Cancel any pending animation frame
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const handleArrowClick = (provider) => {
        setSelectedProvider(provider);
        setOpenProvider(true);
    };

    const [allContent, setAllContent] = useState([]);
    const [filteredContent, setFilteredContent] = useState([]);
    const [providerContentNames, setProviderContentNames] = useState(new Set());

    const getProviders = async () => {
        try {
            AxiosInstance.get("/content-providers")
            .then((response) => {
                const allProviders = response.data;
    
                // Sort providers by created_at in descending order
                const sortedProviders = allProviders.sort((a, b) => {
                    const dateA = new Date(a.created_at);
                    const dateB = new Date(b.created_at);
    
                    // Return value to sort in descending order
                    return dateB - dateA;
                });
    
                setProviders(sortedProviders);
            })
            .catch((error) => {
                console.error("Error fetching providers:", error);
            });
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getProviders();
    }, []);
    

    useEffect(() => {
        async function fetchAllContent() {
            try {
                const [fetchedVods, fetchedEvents, fetchedTVs] = await Promise.all([
                    VodService.getVods(),
                    EventService.getEvents(),
                    TVsService.getAllTVs(),
                ]);

                const combinedContent = [...fetchedVods, ...fetchedEvents, ...fetchedTVs];
                setAllContent(combinedContent);

                const providerContentNames = new Set();
                providers.forEach((provider) => {
                    provider.content.forEach((item) => {
                        providerContentNames.add(item.trim().toLowerCase());
                    });
                });

                const uniqueContent = combinedContent.filter(content => {
                    const normalizedName = content.name.trim().toLowerCase();
                    return !providerContentNames.has(normalizedName);
                });

                setFilteredContent(uniqueContent);

            } catch (error) {
                console.error("Error fetching content:", error);
            }
        }

        fetchAllContent();
    }, [providers]);

    const handleFieldChange = (updatedFields) => {
        setSelectedFields(updatedFields);
    };

    const [filteredProviders, setFilteredProviders] = useState([])

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredProviders(providers);
        } else {
            const filtered = providers.filter((provider) => {
                const searchTermLower = searchTerm.toLowerCase();

                const providerName = provider.name ? provider.name.toLowerCase() : '';
                const providerId = provider.id ? provider.id.toString().toLowerCase() : '';
                const providerEmail = provider.email ? provider.email.toLowerCase() : '';
                const providerPhone = provider.phone ? provider.phone.toLowerCase() : '';

                return (
                    providerName.includes(searchTermLower) ||
                    providerId.includes(searchTermLower) ||
                    providerEmail.includes(searchTermLower) ||
                    providerPhone.includes(searchTermLower)
                );
            });
            setFilteredProviders(filtered);
        }
    }, [searchTerm, providers]);

    const handleDelete = (provider) => {
        // Prompt user for confirmation
        const isConfirmed = window.confirm(`Are you sure you want to delete provider: ${provider.name}?`);
    
        if (isConfirmed) {
            // If confirmed, make the delete request
            AxiosInstance.delete(`/${provider._id}/content-provider`)
                .then((response) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Provider Deleted successfully!',
                        showConfirmButton: false,
                        timer: 2000,
                    });
                })
                .catch((error) => {
                    console.error('Error deleting provider:', error);
                });
        } else {

        }
    };
    

    return (
        <div className='w-[1100px] flex flex-col space-y-2'>
            <div className="flex justify-end py-3">
                <div className="text-md sha flex w-fit items-center justify-center space-x-2 rounded-full bg-brand-background p-2 text-white shadow-lg cursor-pointer hover:scale-105"
                    onClick={() => {
                        isEditing && setIsEditing(false)
                        setIsCreating(true)
                    }
                    }>
                    <IoMdAddCircleOutline size={24} />
                    <p className="px-0.5"> Create Provider </p>
                </div>
            </div>
            <div className='flex items-center justify-between'>
                <div className="text-black border border-brand-stroke-color rounded-lg p-1" id="select">
                    <ProviderFields
                        options={Object.keys(columnWidths).filter((key) => key !== 'Action')}
                        label="Select Fields"
                        selectedOptions={selectedFields}
                        onChange={handleFieldChange}
                    />
                </div>

                <div className='flex items-center gap-x-2'>
                    <input
                        type='text'
                        name='search'
                        placeholder='Search for Provider'
                        className='rounded-md text-sm border border-stroke bg-transparent px-4 py-3 shadow-default dark:border-strokedark dark:bg-black w-64'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <table className="table-auto w-full bg-brand-background shadow-md rounded-lg">
                    <thead>
                        <tr className="text-white text-sm text-center">
                            {selectedFields.map((columnName) => (
                                <th
                                    key={columnName}
                                    className="p-4 relative border-r border-brand-stroke-color"
                                    style={{ width: `${columnWidths[columnName]}px` }}
                                >
                                    {columnName}
                                    <div
                                        ref={resizeHandleRef}
                                        className="resize-handle absolute right-0 top-0 h-full w-2 cursor-col-resize"
                                        onMouseDown={(e) => onMouseDown(e, columnName)}
                                    />
                                </th>
                            ))}
                            <th
                                key="Action"
                                className="p-4 relative border-r border-brand-stroke-color"
                                style={{ width: `${columnWidths['Action']}px` }}
                            >
                                Action
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {filteredProviders.map((provider, index) => (
                            <tr key={provider._id} className="hover:bg-gray-100 text-white text-sm text-center">
                                {selectedFields.map((columnName) => (
                                    <td key={columnName} className="py-4 px-1">
                                        {columnName === 'Date' &&
                                            dateTime.format(new Date(Date.parse(provider.created_at)), 'DD/MM/YY HH:mm ')}
                                        {columnName === 'Logo' && (
                                            <div className='w-[6vh] h-[6vh] rounded-xl bg-black mx-auto'>
                                                <img src={provider.logo} alt="Logo" className="object-cover w-full h-full rounded-xl" />
                                            </div>
                                        )}
                                        {columnName === 'Provider' && provider.name}
                                        {columnName === 'ProviderID' && provider.id}
                                        {columnName === 'Email' && provider.email}
                                        {columnName === 'Phone' && provider.phone}
                                    </td>
                                ))}
                                <td key="Action" className="py-4 px-1 cursor-pointer hover:scale-105">
                                    <div className='flex items-center justify-center gap-x-2'>
                                    <FaArrowCircleRight className="" size={18} onClick={() => handleArrowClick(provider)} />
                                    {/* <RiDeleteBin6Line size={18} onClick={() => handleDelete(provider)} /> */}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>


                </table>
            </div>

            {isCreating && (
                <CreateProviderModal setIsCreating={setIsCreating} getProviders={getProviders} />
            )}
            {openProvider && (
                <ProviderModal provider={selectedProvider} setOpenProvider={setOpenProvider} allContent={filteredContent} getProviders={getProviders} />
            )}
        </div>
    )
}

export default VideoProviders