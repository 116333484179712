import authHeader from './auth-header';
import Event from './interfaces/live-event-interface';
import { AxiosInstance } from '../common/Loader/axios.config';


const token = authHeader();

class EventService {
  // private static readonly API_BASE_URL = 'https://kanemaonline.com/clone/';
  private static readonly API_BASE_URL = 'https://kanema.staticcomputersinc.com';
  private static readonly ACCESS_TOKEN = localStorage.getItem('accessToken'); // Replace with your actual access token

  private static async fetchWithAuthorization<T>(
    url: string,
    options?: RequestInit
  ): Promise<T> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      ...(options?.headers || {}),
    };

    console.log('Fetching data from:', `${this.API_BASE_URL}${url}`); // Log the URL being fetched

    // const response = await fetch(`${this.API_BASE_URL}${url}`, {
    //   ...options,
    //   headers,
    // });

    const response = await AxiosInstance.get(`${url}`);

    if (response.status != 200) {
      console.error('Request failed with status:', response.status); // Log error if request fails
      throw new Error(`Request failed with status ${response.status}`);
    }

    return response.data as Promise<T>;
  }

  static async getEvents(): Promise<Event[]> {
    try {
      console.log('Fetching events...'); // Log before fetching events
      return this.fetchWithAuthorization<Event[]>('/events');
    } catch (error) {
      console.error('Error fetching events:', error); // Log error if fetching events fails
      throw error;
    }
  }

  static async createEvent(eventData: Event): Promise<Event> {
    try {
      console.log('Creating event...'); // Log before creating event
      return this.fetchWithAuthorization<Event>('/events', {
        method: 'POST',
        body: JSON.stringify(eventData),
      });
    } catch (error) {
      console.error('Error creating event:', error); // Log error if creating event fails
      throw error;
    }
  }

  static async updateEvent(
    eventId: string,
    eventData: Partial<Event>
  ): Promise<Event> {
    try {
      console.log('Updating event...'); // Log before updating event
      return this.fetchWithAuthorization<Event>(`/events/${eventId}`, {
        method: 'PUT',
        body: JSON.stringify(eventData),
      });
    } catch (error) {
      console.error('Error updating event:', error); // Log error if updating event fails
      throw error;
    }
  }

  static async deleteEvent(eventId: string): Promise<void> {
    try {
      console.log('Deleting event...'); // Log before deleting event
      await this.fetchWithAuthorization<void>(`/events/${eventId}`, {
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Error deleting event:', error); // Log error if deleting event fails
      throw error;
    }
  }
}

export default EventService;
