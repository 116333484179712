import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import TV from '../services/interfaces/tv-interface';
import Vod from '../services/interfaces/vods-interface';
import Event from '../services/interfaces/live-event-interface';
import { useNavigate } from 'react-router-dom';
import videoDetailsApiService from '../services/videoDetailsApiService';
import { useLocation, useParams } from 'react-router-dom';
import dateTime from 'date-and-time';
import Select from 'react-select';
import { MdDeleteForever } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { MdEdit, MdOutlineFeedback, MdOutlineSubtitles } from "react-icons/md";
import { Tabs } from '@mui/material';
import "./VideoEdit.css"

import { AxiosInstance } from '../common/Loader/axios.config';
import { set } from 'react-hook-form';
import Swal from 'sweetalert2';
import { Analytics } from '../components/Videos/VideoEdit/Analytics';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VideoEdit() {
  const [value, setValue] = React.useState(0);
  const [video, setVideo] = useState<TV | Event | Vod>();
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [thumbNail, setThumbNail] = useState('');
  const [video_provider, setVideoProvider] = useState('');
  const [projectName, setProjectName] = useState('')

  const [thumb_nail, setThumbnailFile] = useState<File | null>(null);
  const [trailer, setTrailer] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [streamUrl, setStreamUrl] = useState('');
  const [streamKey, setStreamKey] = useState('');
  const [live, setLive] = useState(null);
  const [published, setPublished] = useState(null);
  const [promoted, setPromoted] = useState(null);
  const [visible, setVisible] = useState(null);
  const [region, setRegion] = useState('');
  const [created, setCreated] = useState('');
  const [duration, setDuration] = useState('');
  const [views, setViews] = useState(0);

  const [destination, setDestination] = useState('');
  const [providers, setProviders] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category');

  const videoId = queryParams.get('i');

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token !== null) {
      const apiService = new videoDetailsApiService(token);
      const fetchVideo = async () => {
        setLoading(true);

        try {
          if (category && videoId) {
            let fetchedVideo;

            switch (category) {
              case 'tvs':
                fetchedVideo = await apiService.fetchTV(videoId);
                break;
              case 'events':
                fetchedVideo = await apiService.fetchEvent(videoId);
                break;
              case 'vods':
                fetchedVideo = await apiService.fetchVOD(videoId);
                break;
              default:
                fetchedVideo = null;
            }
            setVideo(fetchedVideo[0]);
            setName(fetchedVideo[0].name);
            setDescription(fetchedVideo[0].description);
            setPrice(fetchedVideo[0].price);
            setThumbNail(fetchedVideo[0].thumb_nail);
            setVideoProvider(fetchedVideo[0].video_provider);
            setVideoUrl(fetchedVideo[0].video_url);
            setStreamUrl(fetchedVideo[0].stream_url);
            setStreamKey(fetchedVideo[0].stream_key);
            setLive(fetchedVideo[0].status.live);
            setPublished(fetchedVideo[0].status.publish);
            setPromoted(fetchedVideo[0].status.promoted);
            setVisible(fetchedVideo[0].status.visibility);
            setRegion(fetchedVideo[0].region);
            setCreated(fetchedVideo[0].created_at);
            setDuration(fetchedVideo[0].duration);
            setViews(fetchedVideo[0].views);
            setProjectName(fetchedVideo[0].project_name);
          }
        } catch (error) {
          console.error('Error fetching video:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchVideo();
    } else {
      console.error('Access token is missing or invalid');
    }
  }, []);

  useEffect(() => {
    AxiosInstance.get("/content-providers")
      .then((response) => {
        const allProviders = response.data;

        const sortedProviders = allProviders.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });

        setProviders(sortedProviders)
      })
      .catch((error) => {
      });
  }, []);

  const providerOptions = providers.map((provider) => ({
    value: provider.id,
    label: provider.name,
  }));

  const [selectedProvider, setSelectedProvider] = useState(null);

  const handleProviderChange = (option) => {
    setSelectedProvider(option);
    setVideoProvider(option.value);
  };


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: 'vod', label: 'Videos' },
    { value: 'event', label: 'Events' },
    { value: 'tv', label: 'TVs' },
  ];

  const handleOptionChange = (option) => {
    setDestination(option);
    setSelectedOption(option.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSaving(true);
    try {
      var category = '';
      if (selectedOption) {
        category = selectedOption;
      } else {
        category = video?.category;
      }

      const formData = new FormData();
      formData.append('_id', videoId);
      formData.append('name', name);
      formData.append('description', description);
      formData.append('price', price);
      formData.append('category', category);
      formData.append('stream_key', streamKey);
      formData.append('stream_url', streamUrl);
      formData.append('project_name', projectName);
      formData.append('video_url', videoUrl);
      if (trailer) {
        formData.append('trailer', trailer);
      }
      if (thumb_nail) {
        formData.append('files', thumb_nail);
      }
      if (video_provider) {
        formData.append('video_provider', video_provider);
      }

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }

      const response = await AxiosInstance.post(
        `/update-profile`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setSaving(false)
      Swal.fire({
        icon: 'success',
        title: 'Updated successfully!',
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {
      setSaving(false);
      Swal.fire({
        icon: 'error',
        title: 'Failed to Update Content',
        text: 'An error occurred while Updating Content.',
      });
    }
  };

  const handleToggle = async () => {
    const toggledValue = !live;

    try {
      const response = await AxiosInstance.put(`/${videoId}/content`, {
        'status.live': toggledValue
      });

      if (response.status === 200) {
        setLive(toggledValue);
        Swal.fire({
          icon: 'success',
          title: 'Live Status Updated!',
          showConfirmButton: false,
          timer: 2000,
        });

        if (toggledValue == true) {
          sendNotification();
        }
      }
    } catch (error) {
      console.error("Failed to update Live status", error);
    }
  };

  const sendNotification = async () => {
    const body = {
      title: `${name}`,
      body: `Watch ${name} Live Now!`,
      contentid: `${videoId}`
    }

    // const response = await AxiosInstance.post('/unified-messaging/fcm', body);

    // if (response.status == 200) {
    //   Swal.fire({
    //     icon: 'success',
    //     title: 'Notification Sent to app',
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // } else {
    //   console.log("Failed to send notification", response)
    // }
  }

  const handlepublishToggle = async () => {
    const toggledValue = !published;

    try {
      const response = await AxiosInstance.put(`/${videoId}/content`, {
        'status.publish': toggledValue
      });

      if (response.status === 200) {
        setPublished(toggledValue);
        Swal.fire({
          icon: 'success',
          title: 'Publish Status Updated!',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Failed to update publish status", error);
    }
  };


  const handlePromoteToggle = async () => {
    const toggledValue = !promoted;

    try {
      const response = await AxiosInstance.put(`/${videoId}/content`, {
        'status.promoted': toggledValue
      });

      if (response.status === 200) {
        setPromoted(toggledValue);
        Swal.fire({
          icon: 'success',
          title: 'Promoted Status Updated!',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Failed to update Promoted status", error);
    }
  };

  const handleVisibilityToggle = async () => {
    const toggledValue = !visible;

    try {
      const response = await AxiosInstance.put(`/${videoId}/content`, {
        'status.visibility': toggledValue
      });

      if (response.status === 200) {
        setVisible(toggledValue);
        Swal.fire({
          icon: 'success',
          title: 'Visibility Status Updated!',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Failed to update Visibility status", error);
    }
  };

  const handleDelete = async () => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    // Check if the user confirmed the action
    if (result.isConfirmed) {
      try {
        const response = await AxiosInstance.delete(`/${videoId}`);
        if (response.status === 200) {
          // navigate('/dashboard');
          Swal.fire({
            icon: 'success',
            title: 'Video deleted successfully!',
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          console.error('Failed to delete video', response.data);
        }
      } catch (error) {
        console.error('Failed to delete video', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete video!',
          text: 'Please try again later.',
        });
      }
    }
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const [contentViews, setContentViews] = useState(0)

  const getViewership = async () => {

    try {
      const response = await AxiosInstance.get(`users/viewership`);

      if (response.status == 200) {
        const allViews = response.data.message;
        const filteredViews = allViews.filter(view => view.contentid === videoId);

        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();

        const uniqueUserIds = new Set();

        filteredViews.forEach((view) => {
          const viewDate = new Date(view.start_time);
          const viewMonth = viewDate.getMonth();
          const viewYear = viewDate.getFullYear();


          if (viewMonth === currentMonth && viewYear === currentYear) {
            uniqueUserIds.add(view.userid);
          }
        });
        setContentViews(uniqueUserIds.size);


      } else {

      }
      // }
    } catch (error) {

    } finally {

    }
  }

  useEffect(() => {
    getViewership();
  }, [videoId]);

  const [loggedInProvider, setLoggedInProvider] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    const storedProvider = JSON.parse(localStorage.getItem("provider"));
    if (storedProvider) {
      if (storedProvider.userType === "admin") {
        setIsAdmin(true);
      } else if (storedProvider.id === "8b1fa3") {
        setIsAdmin(true)
      }
      else {
        setIsAdmin(false)
      }

      setLoggedInProvider(storedProvider);
    }
  }, []);

  return (
    <>
      <div className='w-[1200px] mx-auto bg-brand-background border border-brand-stroke-color rounded-xl shadow-lg'>
        <div className='w-full flex items-start justify-start'>

          <div className='w-[35vh]'>
            <div className='flex-col gap-4 p-3'>

              {/* Back Button */}
              <div className='flex items-center justify-start text-white py-2'>
                <IoArrowBack size={20} onClick={handleGoBack} />
              </div>

              {/* Image */}
              <div className='w-full h-[200px] rounded-lg border border-brand-stroke-color'>

                <img src={thumbNail} alt="Video" className='w-full h-full object-cover rounded-lg' />
              </div>

              <div className='my-3'>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  orientation='vertical'
                  TabIndicatorProps={{ style: { display: 'none' } }}
                >
                  {['Details', 'Subtitles', 'Analytics'].map((label, index) => (
                    <div
                      key={index}
                      className={`tab-item flex items-center justify-start gap-x-4 px-3 text-lg p-2 my-1 shadow-lg cursor-pointer ${tabValue === index ? 'text-white bg-brand-red' : 'bg-brand-stroke-color'}`}
                      onClick={() => handleTabChange(null, index)}
                    >
                      {index === 0 && <MdEdit />}
                      {index === 1 && <MdOutlineSubtitles />}
                      {index === 2 && <MdOutlineFeedback />}
                      <span>{label}</span>
                    </div>
                  ))}
                </Tabs>

              </div>

            </div>
          </div>

          {tabValue == 0 && (



            <div className='w-[60vh] flex flex-col video-details p-4 mx-auto text-white'>

              <p>Video Details</p>

              <br />
              <p>Name:</p>
              <input type="text" placeholder='Title' className='w-full mb-2'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <p>Description:</p>
              <textarea name="" id=""
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className='p-2 h-24 overflow-y-auto resize-none mb-2'></textarea>

              <p>Project Name:</p>
              <input type="text" placeholder='Title' className='w-full mb-2'
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />

              {/* <input type="text" placeholder='thumbnail' className='w-full' /> */}
              <p>Thumbnail:</p>

              <input
                type="file"
                accept="image/*"
                placeholder='thumbnail'
                onChange={(e) => setThumbnailFile(e.target.files?.[0])}
                className='w-full mb-2'
              />

              <p>Trailer:</p>
              <input type="text" placeholder='Trailer' className='w-full mb-2' value={trailer} onChange={(e) => setTrailer(e.target.value)} />

              <div className='flex flex-col border border-brand-stroke-color p-2 rounded-lg'>
                <p>Video Package</p>
                <div className='flex justify-between items-center'>
                  <div>
                    <p>Price:</p>
                    <input type="number" name="" id=""
                      placeholder="Price"
                      className='w-full mb-2'
                      value={price}
                      onChange={(e) => setPrice(Number(e.target.value))}
                    />
                  </div>

                  <div>
                    <p>Duration:</p>
                    <input type="text" name="" id=""
                      placeholder="Duration"
                      className='w-full mb-2'
                      value={duration}
                      readOnly
                    />
                  </div>

                </div>
              </div>

              <div className='flex justify-between items-center'>

                <div>
                  <p>Created:</p>
                  <input type="text" name="" id=""
                    placeholder="Created"
                    className='w-full mb-2'
                    value={dateTime.format(new Date(Date.parse(created)), 'DD/MM/YY HH:mm')}
                    readOnly
                  />
                </div>

                <div>
                  <p>Region:</p>
                  <input type="text" name="" id=""
                    placeholder="Region"
                    className='w-full mb-2'
                    value={region}
                    readOnly
                  />
                </div>

              </div>

              <div className='flex justify-between items-center'>

                <div>
                  <p>Category:</p>
                  <input type="text" name="" id=""
                    placeholder="Category"
                    className='w-full mb-2'
                    value={category}
                    readOnly
                  />
                </div>

                <div>
                  <p>Clicks:</p>
                  <input type="text" name="" id=""
                    placeholder="Duration"
                    className='w-full mb-2'
                    value={views}
                    readOnly
                  />
                </div>

              </div>

              <div className='flex justify-between items-center'>

                <div>
                  <p>Views:</p>
                  <input type="text" name="" id=""
                    placeholder="Category"
                    className='w-full mb-2'
                    value={contentViews}
                    readOnly
                  />
                </div>

                <div>
                  {/* <p>Clicks:</p>
                  <input type="text" name="" id=""
                    placeholder="Duration"
                    className='w-full mb-2'
                    value={views}
                    readOnly
                  /> */}
                </div>

              </div>

            </div>
          )}

          {tabValue == 0 && (

            <div className='w-[45vh] p-4'>
              <div className='flex flex-col justify-start gap-4'>
                <div className='flex justify-end items-center'>
                  <div className='w-fit rounded-full px-3 py-1 text-white bg-brand-red my-2 cursor-pointer' onClick={handleSubmit}>{saving ? 'Saving...' : 'Save'}</div>
                </div>

                {(category === "events" || category === "tvs") && isAdmin && (
                  <div>
                    <div className='flex flex-col items-start justify-start'>
                      <p className='text-md text-white'>Video URL</p>
                      <input type="text" placeholder='Video Url' className='w-full bg-transparent rounded-lg border border-brand-stroke-color p-2' value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} />

                    </div>

                    <div className='flex flex-col items-start justify-start'>
                      <p className='text-md text-white'>Stream URL</p>
                      <input type="text" placeholder='Stream Url' className='w-full bg-transparent rounded-lg border border-brand-stroke-color p-2' value={streamUrl} onChange={(e) => setStreamUrl(e.target.value)} />

                    </div>

                    <div className='flex flex-col items-start justify-start'>
                      <p className='text-md text-white'>Stream Key</p>
                      <input type="text" placeholder='Stream Key' className='w-full bg-transparent rounded-lg border border-brand-stroke-color p-2' value={streamKey} onChange={(e) => setStreamKey(e.target.value)} />

                    </div>

                  </div>

                )}

                <div >
                  <p className="text-white text-md font-semibold">Destination Playlist</p>
                  {/* <p className="text-white text-xs font-normal">Upload the video to a specific category e.g. <span className='font-bold'>Movies, Live Events & Videos</span></p> */}
                </div>

                <div className="w-full text-black border border-brand-stroke-color rounded-lg p-2" id="select">

                  <Select
                    value={destination}
                    onChange={handleOptionChange}
                    options={options}
                    placeholder="Select Videos..."
                  />
                </div>

                <div className='flex flex-col'>
                  <p className="text-white text-md font-semibold">Provider</p>
                  <input type="text" placeholder='Provider' className='w-full bg-transparent border border-brand-stroke-color rounded-lg py-1 px-2 my-2'
                    value={video_provider}
                    readOnly
                  />
                  <p className="text-white text-sm font-normal">Choose Content Provider</p>

                  <div className="w-full text-black border border-brand-stroke-color rounded-lg p-2" id="select">

                    <Select
                      value={selectedProvider}
                      onChange={handleProviderChange}
                      options={providerOptions}
                      placeholder="Select Provider..."
                    />
                  </div>

                  {(category === "events" || category === "vods") && (
                    <div>
                      <div className='flex items-center justify-between'>
                        <div className='my-3'>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <Switch
                              color="error"
                              checked={live}
                              onChange={handleToggle}
                            />
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              {live ? 'Live' : 'Not Live'}
                            </span>
                          </label>
                        </div>

                        <div className='my-3'>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <Switch
                              color="error"
                              checked={published}
                              onChange={handlepublishToggle}
                            />
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              {published ? 'Published' : 'Unpublished'}
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className='flex items-center justify-between'>
                        <div className='my-3'>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <Switch
                              color="error"
                              checked={promoted}
                              onChange={handlePromoteToggle}
                            />
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              {promoted ? 'Promoted' : 'Not Promoted'}
                            </span>
                          </label>
                        </div>

                        <div className='my-3'>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <Switch
                              color="error"
                              checked={visible}
                              onChange={handleVisibilityToggle}
                            />
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              {visible ? 'Visible' : 'Not Visible'}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {(category === "events" || category === "vods") && (
                    <div className='flex items-end justify-end mt-35 gap-x-2'>

                      {/* <div className='w-fit rounded-full px-6 py-3 text-black text-xl bg-white my-2 cursor-pointer hover:scale-105' onClick={sendNotification}>
                        <p>Notify</p>
                      </div> */}

                      <div className='w-fit flex items-center justify-center gap-x-2 rounded-full px-6 py-3 text-white text-xl bg-brand-red my-2 cursor-pointer hover:scale-105' onClick={handleDelete}>
                        <MdDeleteForever size={24} />
                        <p>{saving ? 'Deleting...' : 'Delete'}</p>
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          )}

          {tabValue == 1 && (
            <div className=''>Subtitles</div>
          )}


          {tabValue == 2 && (
            <div className='w-full m-0'>
              <Analytics contentName={name} contentID={videoId} />
            </div>
          )}

        </div>
      </div>

    </>
  );
}
