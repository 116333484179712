import ProductOne from '../../../images/product/product-02.png';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/ModeEdit';
import { Link, useNavigate } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/StackedBarChart';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ShowChart from '@mui/icons-material/ShowChart';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TV from '../../../services/interfaces/tv-interface';
import TVsService from '../../../services/tvs-service';
import ServerSwitcher from '../../ServerSwitcher';
import { isLoggedIn } from '../../../services/isLoggedInService';
import ManageLivetvService from '../../../services/promote-to-trends-tvs';
import UpdateVisibilityOptions from '../../../services/interfaces/visibilityOptions';
import { updateVisibility } from '../../../services/visibilityPublishService';
import { RingLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { AxiosInstance } from '../../../common/Loader/axios.config';
import Select from 'react-select';


const label = { inputProps: { 'aria-label': 'Switch demo' } };
const ITEM_HEIGHT = 48;

const LiveTVsManage: React.FC = ({ loggedInProvider, isAdmin }) => {
  const [tvs, setTVs] = React.useState<TV[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = React.useState<'views' | 'comments' | 'name'>(
    'views'
  );
  const [filterName, setFilterName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true); // Step 2

  const open = Boolean(anchorEl);
  const category = 'tvs';
  const manageLivetvService = new ManageLivetvService();

  const [selectedTvId, setSelectedTvId] = useState(null);

  const navigate = useNavigate();

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [videoProvider, setVideoProvider] = useState('');

  React.useEffect(() => {
    const userIsLoggedIn = isLoggedIn();

    if (!userIsLoggedIn) {
      // If the user is not logged in, navigate to the sign-in page
      navigate('/auth/signin');
    }
  }, [navigate]);

  // ... (previous code)

  React.useEffect(() => {
    async function fetchTVs() {
      try {
        setLoading(true);
        const fetchedTVs = await TVsService.getAllTVs();

        const tvsWithInitialStatus = fetchedTVs.map((tv) => {
          return {
            ...tv,
            status: {
              ...tv.status,
              visibility: tv.status?.visibility || false,
              publish: tv.status?.publish || false,
            },
          };
        });

        setTVs(tvsWithInitialStatus);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    fetchTVs();
  }, []);

  const handleClick = (event, TvId) => {
    setAnchorEl(event.currentTarget);
    setSelectedTvId(TvId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedTvId(null);
  };

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortBy(event.target.value as 'views' | 'comments' | 'name');
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const ITEM_HEIGHT = 48;

  const filteredTVs = tvs
    .filter((vod) => {
      if (loggedInProvider.userType === "admin" || loggedInProvider.id === "8b1fa3") {
        return true;
      }
      return vod.video_provider === loggedInProvider.id;
    })
    .filter((vod) =>
      vod.name.toLowerCase().includes(filterName.toLowerCase()) &&
      (videoProvider ? vod.video_provider === videoProvider : true)
    )
    .sort((a, b) => {
      const viewsLengthA = a.views;
      const viewsLengthB = b.views;

      if (sortBy === 'views') {
        return viewsLengthB - viewsLengthA;
      } else if (sortBy === 'comments') {
        return b.comments - a.comments;
      } else {
        return a.name.localeCompare(b.name);
      }
    });


  const handlePromoteToTrendingClick = async (tvId: string) => {
    try {
      // Call the promoteToTrending function with the provided event ID
      await manageLivetvService.promoteToTrending(tvId);
      handleClose();

      setTVs((prevTVs) =>
        prevTVs.map((tv) =>
          tv._id === tvId ? { ...tv, status: { ...tv.status, promoted: true } } : tv
        )
      );
    } catch (error) {
      // Handle any errors here
    }
  };

  const handleDemoteClick = async (tvId: string) => {
    try {
      // Call the promoteToTrending function with the provided event ID
      await manageLivetvService.depromoteToTrending(tvId);

      handleClose();

      setTVs((prevTVs) =>
        prevTVs.map((tv) =>
          tv._id === tvId ? { ...tv, status: { ...tv.status, promoted: false } } : tv
        )
      );
    } catch (error) {
      // Handle any errors here
    }
  };

  const handleToggleChange = async (
    tvId: string,
    field: 'visibility' | 'publish',
    newValue: boolean
  ) => {
    try {
      // Fetch the current state of the TV from the state
      const currentTV = tvs.find((tv) => tv._id === tvId);
      if (!currentTV) {
        throw new Error('TV not found');
      }

      // Build update options to always include both visibility and publish
      const updateOptions: UpdateVisibilityOptions = {
        visibility: field === 'visibility' ? newValue : currentTV.status.visibility,
        publish: field === 'publish' ? newValue : currentTV.status.publish,
      };

      await updateVisibility(tvId, category, updateOptions);

      setTVs((prevTVs) =>
        prevTVs.map((tv) =>
          tv._id === tvId
            ? {
              ...tv,
              status: { ...tv.status, ...updateOptions },
            }
            : tv
        )
      );
    } catch (error) {
      console.error('Error updating TV status:', error);
    }
  };

  const [providers, setProviders] = useState([]);

  useEffect(() => {
    AxiosInstance.get("/content-providers")
      .then((response) => {
        const allProviders = response.data;

        const sortedProviders = allProviders.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });

        setProviders(sortedProviders)
      })
      .catch((error) => {
      });
  }, []);

  const providerOptions = providers.map((provider) => ({
    value: provider.id,
    label: provider.name,
  }));

  const handleProviderChange = (option) => {
    setSelectedProvider(option);
    setVideoProvider(option.value);
  };

  const [contentViews, setContentViews] = useState([])

  const getViewership = async () => {

    try {
      const response = await AxiosInstance.get(`users/viewership`);

      if (response.status === 200) {
        const allViews = response.data.message;

        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();

        const uniqueViewsMap = new Map();

        allViews.forEach((view) => {
          const viewDate = new Date(view.start_time);
          const viewMonth = viewDate.getMonth();
          const viewYear = viewDate.getFullYear();

          if (viewMonth === currentMonth && viewYear === currentYear) {
            const compositeKey = `${view.userid}-${view.contentid}`;

            if (!uniqueViewsMap.has(compositeKey)) {
              uniqueViewsMap.set(compositeKey, view);
            }
          }
        });

        const uniqueViewsArray = Array.from(uniqueViewsMap.values());

        setContentViews(uniqueViewsArray);
      }
    } catch (error) {
      console.error("Error fetching viewership data:", error);
    }
  }

  useEffect(() => {
    getViewership();
  }, []);

  return (
    <div className="rounded-sm bg-white shadow-default dark:border-strokedark dark:bg-black w-[1100px]">
      {/* <div className="m-4 mt-2 flex justify-center px-4"> */}
      <div className='w-[1100px] flex items-center justify-center'>
        <div className="flex items-center justify-start">
          <p className="text-gray-500 dark:text-gray-300 mr-2 text-sm">
            Sort by:
          </p>
          <select
            className="focus:border-blue-300 rounded-md border px-2 py-1 text-sm focus:outline-none dark:border-strokedark dark:bg-transparent"
            value={sortBy}
            onChange={handleSortChange}
          >
            <option value="views">Views</option>
            <option value="comments">Comments</option>
            <option value="name">Name</option>
          </select>
        </div>

        <div className="m-4 flex items-start p-4">
          <ServerSwitcher />
        </div>

        {isAdmin ? (
          <div className='w-[30vh] flex items-center justify center'>
            <p className='text-sm'>Filter by provider</p>
            <div className="w-90 text-black border border-brand-stroke-color rounded-lg p-2 my-2" id="select">
              <Select
                value={selectedProvider}
                onChange={handleProviderChange}
                options={providerOptions}
                placeholder="Select Provider..."
              />
            </div>
          </div>
        ) : (
          <div className='w-[30vh]'></div>
        )
        }

        <div className="ml-4 flex items-center">
          <p className="text-gray-500 dark:text-gray-300 text-sm">
            Filter by name:
          </p>
          <input
            type="text"
            className="mb-1 mt-2 w-70 rounded-full border border-stroke dark:border-strokedark bg-transparent px-3 py-1 text-white"
            value={filterName}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      {/* </div> */}
      <div className="grid grid-cols-12 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-12 md:px-6 2xl:px-7.5 text-sm">
        <div className="col-span-5 flex items-center mx-12">
          <p className="font-medium">TV Name</p>
        </div>
        <div className="col-span-1 hidden items-center sm:flex justify-center">
          <p className="text-sm font-medium">Clicks</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Views</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Comments</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Visibility</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Publish</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Manage</p>
        </div>
      </div>

      <div className="m-4 mt-2 flex items-center justify-center px-4">
        {loading ? (
          <RingLoader color="#36D7B7" loading={loading} size={100} />
        ) : (
          <div className="h-screen overflow-y-scroll">
            {filteredTVs.map((tv) => (
              <div
                key={tv._id}
                className="grid grid-cols-12 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-12 md:px-6 2xl:px-7.5 text-xs"
              >
                <div className="col-span-5 flex items-center">
                  <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                    <div className="h-20.5 w-35 rounded-lg">
                      <img src={tv.thumb_nail} alt={tv.name} className='rounded-lg' />
                    </div>
                    <div className="container mr-4">
                      <p className="text-sm text-black dark:text-white text-xs">
                        {tv.name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 hidden items-center sm:flex justify-center">
                  <p className="text-sm text-black dark:text-white">
                    {tv.views}
                  </p>
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <p className="text-sm text-black dark:text-white">
                    {
                      contentViews.filter(view => view.contentid === tv._id).length
                    }
                  </p>
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <p className="text-sm text-black dark:text-white">
                    {tv.comments}
                  </p>
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <Switch
                    color="success"
                    checked={tv.status.visibility}
                    onChange={() =>
                      handleToggleChange(
                        tv._id,
                        'visibility',
                        !tv.status.visibility
                      )
                    }
                  />
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <Switch
                    color="success"
                    checked={tv.status.publish}
                    onChange={() =>
                      handleToggleChange(tv._id, 'publish', !tv.status.publish)
                    }
                  />
                </div>
                <div className="col-span-1 flex items-center justify-center gap-x-1 ml-12">
                  <Link
                    to={`/ManageVideoEdit/${tv._id}?category=${category}&i=${tv._id}`}
                  >
                    <EditIcon sx={{ fontSize: 20 }} />
                  </Link>
                  <Link
                    to={`/ManageVideoEdit/${tv._id}?category=${category}&i=${tv._id}`}
                  >
                    <BarChartIcon sx={{ fontSize: 20 }} />
                  </Link>
                  <div>
                    <IconButton
                      aria-label="more"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, tv._id)}
                      color="inherit"
                    >
                      <MoreVertIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={open && selectedTvId === tv._id}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: '20ch',
                        },
                      }}
                    >
                      {tv.status.promoted ? (
                        <MenuItem onClick={() => handleDemoteClick(tv._id)}>
                          <ShowChart /> Demote
                        </MenuItem>
                      ) : (
                        <MenuItem onClick={() => handlePromoteToTrendingClick(tv._id)}>
                          <ShowChart /> Promote To Trending
                        </MenuItem>
                      )}
                      <MenuItem>
                        <DeleteIcon />
                        Delete forever
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveTVsManage;

