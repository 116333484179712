import ProductOne from '../../../images/product/product-04.png';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/ModeEdit';
import { Link, useNavigate } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/StackedBarChart';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ShowChart from '@mui/icons-material/ShowChart';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Event from '../../services/interfaces/live-event-interface';
import EventService from '../../services/event-service';
import ManageLiveEventService from '../Go Live/ManageLiveEventService';
import Swal from 'sweetalert2';
import { setIsLive, resetUploadState } from '../../store/uploadSlice';
import { useDispatch } from 'react-redux';
import { isLoggedIn } from '../../services/isLoggedInService';
import { updateVisibility } from '../../services/visibilityPublishService';
import UpdateVisibilityOptions from '../../services/interfaces/visibilityOptions';
import { RingLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { GiUpgrade } from "react-icons/gi";
import { AxiosInstance } from '../../common/Loader/axios.config';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const ITEM_HEIGHT = 48;

const LiveEvent: React.FC = ({loggedInProvider}) => {
  const [vods, setVods] = React.useState<Event[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = React.useState<'views' | 'comments' | 'name'>(
    'views'
  );
  const [loading, setLoading] = React.useState<boolean>(true); // Step 2

  const [filterName, setFilterName] = React.useState<string>('');
  const manageLiveEventService = new ManageLiveEventService();
  const [publisValue, setPublisValue] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const userIsLoggedIn = isLoggedIn();
    if (!userIsLoggedIn) {
      // If the user is not logged in, navigate to the sign-in page
      navigate('/auth/signin');
    }
  }, [navigate]);

  const open = Boolean(anchorEl);
  const category = 'events';

  const [selectedVodId, setSelectedVodId] = useState(null);

  React.useEffect(() => {
    async function fetchVods() {
      try {
        setLoading(true);
        const fetchedVods = await EventService.getEvents();

        // Set the initial values for visibility and publish status, and publisValue
        const vodsWithInitialStatus = fetchedVods.map((vod) => {
          return {
            ...vod,
            status: {
              ...vod.status,
              visibility: vod.status.visibility || false,
              publish: vod.status.publish || false,
            },
            publisValue: vod.status.publish || false,
          };
        });

        setVods(vodsWithInitialStatus);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching VODs:', error);
        setLoading(false);
      }
    }

    fetchVods();
  }, []);

  const handleToggleChange = async (
    eventId: string,
    field: 'visibility' | 'publish',
    newValue: boolean
  ) => {
    try {
      // Fetch the current state of the TV from the state
      const currentEvent = vods.find((vod) => vod._id === eventId);
      if (!currentEvent) {
        throw new Error('Video not found');
      }
  
      // Build update options to always include both visibility and publish
      const updateOptions: UpdateVisibilityOptions = {
        visibility: field === 'visibility' ? newValue : currentEvent.status.visibility,
        publish: field === 'publish' ? newValue : currentEvent.status.publish,
      };

      await updateVisibility(eventId, category, updateOptions);

      // Update the state with the new visibility/publish status
      setVods((prevVods) =>
        prevVods.map((vod) =>
          vod._id === eventId
            ? {
              ...vod,
              status: { ...vod.status, [field]: newValue },
            }
            : vod
        )
      );
    } catch (error) {
    }
  };

  const handleClick = (event, vodId) => {
    setAnchorEl(event.currentTarget);
    setSelectedVodId(vodId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedVodId(null);
  };

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortBy(event.target.value as 'views' | 'comments' | 'name');
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);
  };

  const ITEM_HEIGHT = 48;

  const filteredVods = vods
  .filter((vod) => {
    if (loggedInProvider.userType === "admin" || loggedInProvider.id === "8b1fa3") {
      return true; 
    }
    return vod.video_provider === loggedInProvider.id;
  })
  .filter((vod) =>
    vod?.name?.toLowerCase().includes(filterName.toLowerCase())
  )
  .sort((a, b) => {
    const viewsA = typeof a.views === 'number' ? a.views : 0;
    const viewsB = typeof b.views === 'number' ? b.views : 0;

    if (sortBy === 'views') {
      return viewsB - viewsA;
    } else if (sortBy === 'comments') {
      return (b.comments || 0) - (a.comments || 0);
    } else {
      return a.name?.localeCompare(b.name || '');
    }
  });



  // const filteredVods = vods
  //   .filter((vod) =>
  //     vod?.name?.toLowerCase().includes(filterName.toLowerCase())
  //   )
  //   .sort((a, b) => {
  //     const viewsA = typeof a.views === 'number' ? a.views : 0;
  //     const viewsB = typeof b.views === 'number' ? b.views : 0;

  //     if (sortBy === 'views') {
  //       return viewsB - viewsA;
  //     } else if (sortBy === 'comments') {
  //       return (b.comments || 0) - (a.comments || 0);
  //     } else {
  //       return a.name?.localeCompare(b.name || '');
  //     }
  //   });

  const [anchorEls, setAnchorEls] = React.useState<{
    [key: string]: null | HTMLElement;
  }>({});

  // ...

  // Function to open the menu for a specific video
  const handleOpenMenu = (
    videoId: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEls({
      ...anchorEls,
      [videoId]: event.currentTarget,
    });
  };

  // Function to close the menu for a specific video
  const handleCloseMenu = (videoId: string) => {
    setAnchorEls({
      ...anchorEls,
      [videoId]: null,
    });
  };

  const handlePromoteToTrendingClick = async (eventId: string) => {
    try {
      // Call the promoteToTrending function with the provided event ID
      await manageLiveEventService.promoteToTrending(eventId);

      handleClose();

      setVods((prevVods) =>
        prevVods.map((vod) =>
          vod._id === eventId ? { ...vod, status: { ...vod.status, promoted: true } } : vod
        )
      );
    } catch (error) {
      // Handle any errors here
    }
  };

  const handleDemoteClick = async (eventId: string) => {
    try {
      // Call the promoteToTrending function with the provided event ID
      await manageLiveEventService.depromoteToTrending(eventId);

      handleClose();

      setVods((prevVods) =>
        prevVods.map((vod) =>
          vod._id === eventId ? { ...vod, status: { ...vod.status, promoted: false } } : vod
        )
      );
    } catch (error) {
      // Handle any errors here
    }
  };

  const handleDeleteForeverEvent = (eventId: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to end the Event?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked "Yes," proceed with ending the event
        manageLiveEventService
          .endEvent(eventId)
          .then((response) => {
            if (response.acknowledged && response.deletedCount === 1) {
              // Display a success SweetAlert for "deleted successfully"
              Swal.fire({
                icon: 'success',
                title: 'Deleted successfully!',
                showConfirmButton: false,
                timer: 2000, // Auto-close after 2 seconds
              });
              // Set isLive to false in the Redux store
              // dispatch(setIsLive(false));
              // Perform any additional actions or update your UI as needed.
              // dispatch(resetUploadState());
            } else {
              console.error('Failed to end event.');
            }
          })
          .catch((error) => {
            console.error('Error while ending event:', error.message);

          });
      }

    });
  };

  const [contentViews, setContentViews] = useState([])

  const getViewership = async () => {

    try {
      const response = await AxiosInstance.get(`users/viewership`);

      if (response.status === 200) {
        const allViews = response.data.message;

        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();

        const uniqueViewsMap = new Map();

        allViews.forEach((view) => {
          const viewDate = new Date(view.start_time);
          const viewMonth = viewDate.getMonth();
          const viewYear = viewDate.getFullYear();

          if (viewMonth === currentMonth && viewYear === currentYear) {
            const compositeKey = `${view.userid}-${view.contentid}`;

            if (!uniqueViewsMap.has(compositeKey)) {
              uniqueViewsMap.set(compositeKey, view);
            }
          }
        });

        const uniqueViewsArray = Array.from(uniqueViewsMap.values());

        setContentViews(uniqueViewsArray);
      }
    } catch (error) {
      console.error("Error fetching viewership data:", error);
    }
  }

  useEffect(() => {
    getViewership();
  }, []);

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-transparent dark:bg-transparent w-[1150px]">
      <div className="m-4  mt-2 flex justify-between px-4">
        <div className="flex items-center">
          <p className="text-gray-500 dark:text-gray-300 mr-2 text-sm">
            Sort by:
          </p>
          <select
            className="focus:border-blue-300 rounded-md border px-2 py-1 text-sm focus:outline-none dark:border-strokedark dark:bg-transparent"
            value={sortBy}
            onChange={handleSortChange}
          >
            <option value="views">Views</option>
            <option value="comments">Comments</option>
            <option value="name">Name</option>
          </select>
        </div>
        <div className="ml-4 flex items-center">
          <p className="text-gray-500 dark:text-gray-300 mr-2 text-sm">
            Filter by name:
          </p>
          <input
            type="text"
            className="mb-1 mt-2 w-90 rounded-full border dark:border-strokedark bg-transparent px-3 py-1 text-white"
            value={filterName}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-12 md:px-6 2xl:px-7.5">
        <div className="col-span-5 flex items-center mx-12">
          <p className="font-medium">Name</p>
        </div>
        <div className="col-span-1 hidden items-center sm:flex justify-center">
          <p className="text-sm font-medium">Clicks</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Views</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Comments</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Visibility</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Publish</p>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-sm font-medium">Manage</p>
        </div>
      </div>

      <div className="m-4 mt-2 flex items-center justify-center px-4">
        {loading ? (
          <RingLoader color="#36D7B7" loading={loading} size={100} />
        ) : (
          <div className="h-screen overflow-y-scroll">
            {filteredVods.map((vod) => (
              <div
                key={vod._id}
                className="grid grid-cols-12 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-12 md:px-6 2xl:px-7.5"
              >
                <div className="col-span-5 flex items-center">
                  <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                    <div className="h-20.5 w-35 rounded-md">
                      <img src={vod.thumb_nail} alt={vod.name} className='rounded-lg' />
                    </div>
                    { vod.status.promoted &&
                    <GiUpgrade className='text-success' size={32} />
                    }
                    <div className="container mr-4">
                      <p className="text-sm text-black dark:text-white">
                        {vod.name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 hidden items-center sm:flex justify-center">
                  <p className="text-sm text-black dark:text-white">
                    {vod.views || 0}
                  </p>
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <p className="text-sm text-black dark:text-white">
                  {
                      contentViews.filter(view => view.contentid === vod._id).length
                    }
                  </p>
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <p className="text-sm text-black dark:text-white">
                    {vod.comments || 0}
                  </p>
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <Switch
                    color="success"
                    checked={vod.status.visibility}
                    onChange={() =>
                      handleToggleChange(
                        vod._id,
                        'visibility',
                        !vod.status.visibility
                      )
                    }
                  />
                </div>

                <div className="col-span-1 flex items-center justify-center">
                  <Switch
                    color="success"
                    checked={vod.status.publish}
                    onChange={() =>
                      handleToggleChange(
                        vod._id,
                        'publish',
                        !vod.status.publish
                      )
                    }
                  />
                </div>
                <div className="col-span-1 flex items-center justify-between gap-x-1 ml-8">
                  <Link
                    to={`/ManageVideoEdit/${vod._id}?category=${category}&i=${vod._id}`}
                  >
                    <EditIcon sx={{ fontSize: 20 }} />
                  </Link>
                  <Link
                    to={`/ManageVideoEdit/${vod._id}?category=${category}&i=${vod._id}`}
                  >
                    <BarChartIcon sx={{ fontSize: 20 }} />
                  </Link>
                  <div>
                    <IconButton
                      aria-label="more"
                      id={`long-button-${vod._id}`}
                      aria-controls={
                        anchorEls[vod._id] ? 'long-menu' : undefined
                      }
                      aria-expanded={anchorEls[vod._id] ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, vod._id)} 
                      color="inherit"
                    >
                      <MoreVertIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={open && selectedVodId === vod._id}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: '25ch',
                        },
                      }}
                    >
                      {vod.status.promoted ? (
                        <MenuItem onClick={() => handleDemoteClick(vod._id)}>
                          <ShowChart /> Demote
                        </MenuItem>
                      ) : (
                        <MenuItem onClick={() => handlePromoteToTrendingClick(vod._id)}>
                          <ShowChart /> Promote To Trending
                        </MenuItem>
                      )}
                      {/* <MenuItem><VisibilityOffOutlinedIcon /> Unpublish</MenuItem> */}
                      <MenuItem
                        onClick={() => handleDeleteForeverEvent(vod._id)}
                      >
                        <DeleteIcon /> Delete forever
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveEvent;

// import ProductOne from '../../images/product/product-01.png';
// import Switch from '@mui/material/Switch';
// import EditIcon from '@mui/icons-material/ModeEdit';
// import { Link } from 'react-router-dom';
// import BarChartIcon from '@mui/icons-material/StackedBarChart';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ShowChart from '@mui/icons-material/ShowChart';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
// import * as React from 'react';
// import IconButton from '@mui/material/IconButton';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

// const label = { inputProps: { 'aria-label': 'Switch demo' } };
// const ITEM_HEIGHT = 48;

// const LiveEvent = () => {

//   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
//       <div className="grid grid-cols-12 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-12 md:px-6 2xl:px-7.5">
//         <div className="col-span-5 flex items-center">
//           <p className="font-medium"></p>
//         </div>
//         <div className="col-span-1 hidden items-center sm:flex">
//           <p className="text-sm font-medium">Views</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//           <p className="text-sm font-medium">Shares</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//           <p className="text-sm font-medium">Likes</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//           <p className="text-sm font-medium">Feedbacks</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//           <p className="text-sm font-medium">Subscription</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//           <p className="text-sm font-medium">Publish</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//           <p className="text-sm font-medium">Manage</p>
//         </div>
//       </div>

//       <div className="grid grid-cols-12 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-12 md:px-6 2xl:px-7.5">
//         <div className="col-span-5 flex items-center">
//           <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
//             <div className="h-20.5 w-35 rounded-md">
//               <img src={ProductOne} alt="Product" />
//             </div>
//             <div className='container mr-4'>
//                 <p className="text-sm text-black dark:text-white">
//                 Umodzi park End Of Year Paty
//                 </p>
//                 <p className="mt-4 text-xs text-black dark:text-white">
//                     Loyal Hustle The Factory & QoQ Malawi
//                     presents the   Henry Czar Concert with  performance
//                     by Phyzix, Quest, Charisma, Bee Jay and  Ace Jizzy and many more
//                 </p>
//             </div>
//           </div>
//         </div>
//         <div className="col-span-1 hidden items-center sm:flex">
//           <p className="text-sm text-black dark:text-white">2074</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//           <p className="text-sm text-black dark:text-white">269</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//           <p className="text-sm text-black dark:text-white">22</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//           <p className="text-sm text-black dark:text-white">22</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//           <p className="text-sm text-black dark:text-white">22</p>
//         </div>
//         <div className="col-span-1 flex items-center">
//             <Switch color='success' {...label} defaultChecked />
//         </div>
//         <div className="col-span-1 flex items-center justify-between">
//           <Link to="/ManageVideoEdit">
//             <EditIcon />
//           </Link>
//           <Link to="/ManageVideoEdit">
//             <BarChartIcon/>
//           </Link>
//           <div>
//           <IconButton
//             aria-label="more"
//             id="long-button"
//             aria-controls={open ? 'long-menu' : undefined}
//             aria-expanded={open ? 'true' : undefined}
//             aria-haspopup="true"
//             onClick={handleClick}
//             color='inherit'
//           >
//             <MoreVertIcon />
//           </IconButton>
//           <Menu
//             id="long-menu"
//             color='inherit'
//             MenuListProps={{
//               'aria-labelledby': 'long-button',
//             }}
//             anchorEl={anchorEl}
//             open={open}
//             onClose={handleClose}
//             PaperProps={{
//               style: {
//                 maxHeight: ITEM_HEIGHT * 4.5,
//                 width: '20ch',
//               },
//             }}
//           >
//             <MenuItem><ShowChart/>Promote to trending</MenuItem>
//             <MenuItem><VisibilityOffOutlinedIcon/>Unpublish</MenuItem>
//             <MenuItem><DeleteIcon/>Delete forever</MenuItem>
//           </Menu>
//         </div>

//         </div>
//       </div>

//     </div>
//   );
// };

// export default LiveEvent;
