import { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2'; // Assuming you're using chart.js

export const WatchTimeView = ({ contentViews, selectedPeriod, setDailyHours, setWeeklyHours, setMonthlyHours }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const aggregateWatchHours = () => {
            const watchCounts = {};
            const now = new Date();

            if (selectedPeriod === 'Today') {
                const now = new Date();
                const currentHour = now.getHours();
                const startHour = currentHour - 5 < 0 ? 24 + (currentHour - 5) : currentHour - 5;
            
                const labels = Array.from({ length: 6 }, (_, i) => {
                    const hour = (startHour + i) % 24;
                    return `${hour}:00 - ${hour + 1}:00`;
                });
            
                const watchCounts = {};
                labels.forEach(label => (watchCounts[label] = 0));
            
                let totalWatchTime = 0; 
            
                contentViews.forEach((view) => {
                    const startTime = new Date(view.start_time);
                    const endTime = new Date(view.end_time);
                    const watchTime = (endTime - startTime) / (1000 * 60 * 60); 
            
                    const viewHour = startTime.getHours();
                    const label = `${viewHour}:00 - ${viewHour + 1}:00`;
            
                    // Check if the view is today (without mutating now)
                    const todayStart = new Date().setHours(0, 0, 0, 0);
                    const todayEnd = new Date().setHours(23, 59, 59, 999);
            
                    if (startTime >= todayStart && startTime <= todayEnd) {
                        watchCounts[label] += watchTime;
                        totalWatchTime += watchTime;
                    }
                });
            
                setDailyHours(totalWatchTime.toFixed(2)); 
            
                setChartData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Watch Hours',
                            data: labels.map(label => watchCounts[label]),
                            borderColor: 'rgba(255,159,64,1)',
                            tension: 0.4,
                            fill: false,
                        },
                    ],
                });
            }
            
            // Logic for Weekly Period
            else if (selectedPeriod === 'Weekly') {
                const now = new Date();
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 6); 
        
                const labels = Array.from({ length: 7 }, (_, i) => {
                    const date = new Date(oneWeekAgo);
                    date.setDate(date.getDate() + i);
                    return date.toISOString().split('T')[0]; 
                });
        
                const watchCounts = {};
                labels.forEach(label => (watchCounts[label] = 0));
        
                let totalWatchTime = 0; 
        
                contentViews.forEach((view) => {
                    const startTime = new Date(view.start_time);
                    const endTime = new Date(view.end_time);
                    const watchTime = (endTime - startTime) / (1000 * 60 * 60); 
        
                    const viewDateLabel = startTime.toISOString().split('T')[0]; 
        
                    // Check if the view is within the past week
                    if (startTime >= oneWeekAgo && startTime <= now) {
                        watchCounts[viewDateLabel] += watchTime;
                        totalWatchTime += watchTime; 
                    }
                });
        
                // Set the total watch hours for the week
                setWeeklyHours(totalWatchTime.toFixed(2)); 
        
                setChartData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Watch Hours',
                            data: labels.map(label => watchCounts[label]),
                            borderColor: 'rgba(255,159,64,1)',
                            tension: 0.4,
                            fill: false,
                        },
                    ],
                });
            }

            // Logic for Monthly Period
            else if (selectedPeriod === 'Monthly') {
                const sixMonthsAgo = new Date();
                sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 5); // 6 months range including current

                const labels = Array.from({ length: 6 }, (_, i) => {
                    const date = new Date();
                    date.setMonth(sixMonthsAgo.getMonth() + i);
                    return date.toLocaleString('default', { month: 'short', year: 'numeric' }); // "Mon YYYY"
                });

                labels.forEach(label => (watchCounts[label] = 0));

                contentViews.forEach((view) => {
                    const startTime = new Date(view.start_time);
                    const endTime = new Date(view.end_time);
                    const watchTime = (endTime - startTime) / (1000 * 60 * 60); // Convert to hours

                    const viewMonthLabel = startTime.toLocaleString('default', { month: 'short', year: 'numeric' });

                    if (startTime >= sixMonthsAgo && startTime <= now) {
                        watchCounts[viewMonthLabel] += watchTime;
                    }
                });

                setChartData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Watch Hours',
                            data: labels.map(label => watchCounts[label]),
                            borderColor: 'rgba(255,159,64,1)',
                            tension: 0.4,
                            fill: false,
                        },
                    ],
                });
            }
        };

        aggregateWatchHours();
    }, [selectedPeriod, contentViews]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#fff',
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#fff',
                },
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    color: '#fff',
                    count: 5,
                    // stepSize: 20,
                    // callback: function (value) {
                    //     if (Number.isInteger(value)) {
                    //         return value;
                    //     }
                    // }
                },
                grid: {
                    color: '#444',
                    borderDash: [5, 5],
                    borderDashOffset: 3,
                },
                beginAtZero: true,
                // suggestedMax: Math.ceil(Math.max(...chartData.data) / 5) * 5,
            },
        },
    };

    return (
        <div className='h-60 w-full]'>
            {chartData && <Line data={chartData} options={options} />}
        </div>
    );
};
