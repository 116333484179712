import { React, useEffect, useState } from 'react';
import { AxiosInstance } from '../../../common/Loader/axios.config';
import Swal from 'sweetalert2';
import VodService from '../../../services/vods-service';
import EventService from '../../../services/event-service';
import TVsService from '../../../services/tvs-service';
import Select from 'react-select';
import { RiDeleteBin6Line } from 'react-icons/ri';


const ProviderModal = ({ provider, setOpenProvider, allContent, getProviders }) => {
    const [loading, setLoading] = useState(false);
    const [updatedProvider, setUpdatedProvider] = useState({
        name: provider.name || '',
        email: provider.email || '',
        phone: provider.phone || '',
        logo: null,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedProvider((prev) => ({
            ...prev,
            [name.toLowerCase()]: value,
        }));
    };

    const handleFileChange = (e) => {
        setUpdatedProvider((prev) => ({
            ...prev,
            logo: e.target.files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const formData = new FormData();
            for (const [key, value] of Object.entries(updatedProvider)) {
                if (value) {
                    formData.append(key, value);
                }
            }

            if (selectedContent && selectedContent.length > 0) {

                selectedContent.forEach(item => {
                    formData.append('content[]', item); // Append each value separately
                });
            }


            // for (const [key, value] of formData.entries()) {
            //     console.log(`${key}:`, value);
            // }

            const response = await AxiosInstance.put(`/${provider._id}/content-provider`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Provider updated successfully!',
                    showConfirmButton: false,
                    timer: 2000,
                });
                setOpenProvider(false);
                getProviders();
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Update failed',
                text: error.response?.data?.message || 'An error occurred',
            });
        } finally {
            setLoading(false);
        }
    };

    const [selectedContent, setSelectedContent] = useState<string[]>([]);

    const options = allContent.map((content) => ({
        value: content.name,
        label: content.name,
    }));

    const handleChange = (selectedOptions) => {
        setSelectedContent(selectedOptions ? selectedOptions.map((opt) => opt.value) : []);
    };

    const handleDelete = (provider) => {
        // Prompt user for confirmation
        const isConfirmed = window.confirm(`Are you sure you want to delete provider: ${provider.name}?`);
    
        if (isConfirmed) {
            // If confirmed, make the delete request
            AxiosInstance.delete(`/${provider._id}/content-provider`)
                .then((response) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Provider Deleted successfully!',
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getProviders();
                })
                .catch((error) => {
                    console.error('Error deleting provider:', error);
                });
        } else {
            console.log('Delete action cancelled');
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="flex flex-col gap-4 justify-center bg-boxdark p-6 rounded-lg text-white shadow-xl" style={{ width: '900px' }}>
                <p className='text-center'>Provider Details</p>
                <div className='flex items-start justify-start'>
                    <div className='w-fit px-6'>
                        <div className='w-[12vh] h-[12vh] rounded-xl bg-black'>
                            <img src={provider.logo} alt="Logo" className="object-cover w-full h-full" />
                        </div>
                    </div>
                    <div className='w-[35vh] flex flex-col'>
                        <div className='flex flex-col items-start justify-start'>
                        <p className='text-sm'>Name:</p>
                        <input
                            type="text"
                            name="Name"
                            placeholder="Provider Name"
                            className="my-2 w-full rounded-lg border border-white bg-transparent px-4 py-2"
                            value={updatedProvider.name}
                            onChange={handleInputChange}
                        />
                        </div>

                        {provider.email && (
                                                    <div className='flex flex-col items-start justify-start'>
                                <p className='text-sm'>Email:</p>
                            <input
                                type="text"
                                name="Email"
                                placeholder="Provider Email"
                                className="my-2 w-full rounded-lg border border-white bg-transparent px-4 py-2"
                                value={updatedProvider.email}
                                onChange={handleInputChange}
                            />
                            </div>
                        )}

                        {provider.phone && (
                        <div className='flex flex-col items-start justify-start'>
                            <p className='text-sm'>Phone:</p>
                            <input
                                type="text"
                                name="Phone"
                                placeholder="Provider Phone"
                                className="my-2 w-full rounded-lg border border-white bg-transparent px-4 py-2"
                                value={updatedProvider.phone}
                                onChange={handleInputChange}
                            />
                            </div>
                        )}

<div className='flex flex-col items-start justify-start'>
                            <p className='text-sm'>Logo:</p>
                        <input
                            type="file"
                            name="Logo"
                            className="my-2 w-full rounded-lg border border-white bg-transparent px-4 py-2"
                            onChange={handleFileChange}
                        />
                        </div>

                        <div className='w-full flex flex-col justify-start'>
                            <p className='text-sm'>Content:</p>
                        <div className='text-black py-1'>
                            <Select
                                isMulti
                                value={selectedContent.map((content) => ({ value: content, label: content }))}
                                onChange={handleChange}
                                options={options}
                                placeholder="Select Content..."
                            />
                        </div>
                        </div>
                        <div className='flex items-center justify-end'>
                            <div className='flex items-center justify-center gap-x-4'>
                                <div className='cursor-pointer hover:scale-105' onClick={() => setOpenProvider(false)}>Cancel</div>

                                <button
                                    className="border-[2px] border-brand-stroke-color bg-brand-red px-4 py-2 my-4 text-white rounded-xl px-4 items-center justify-center"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {loading ? 'Updating...' : 'Update'}
                                </button>

                                <div className='rounded-lg p-3 bg-brand-red hover:scale-105 cursor-pointer'>
                                <RiDeleteBin6Line size={18} onClick={() => handleDelete(provider)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-[40vh] h-[36vh] flex flex-col items-start justify-start p-4 ml-4 rounded-xl border border-brand-stroke-color'>
                        <p className='text-md text-center'>Provider Content</p>
                        <div className='flex flex-col items-start justify-start overflow-y-auto h-full w-full'>
                            {provider.content && provider.content.length > 0 ? (
                                provider.content.map((item, index) => (
                                    <p key={index} className="text-sm text-gray-300 py-1">
                                        {item}
                                    </p>
                                ))
                            ) : (
                                <p className="text-sm text-gray-500 italic">No content available.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProviderModal;
