import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProductOne from '../../images/product/product-01.png';
import TrendsService from '../../services/trends-service';
import Trend from '../../services/interfaces/trending-interface';

import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';

const TrendingVideos = () => {
  const [trends, setTrends] = useState<Trend[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchTrends() {
      try {
        const trendsData = await TrendsService.getTrends();
        // Sort trends by views in descending order
        const sortedTrends = trendsData.sort((a, b) => b.views - a.views);
        setLoading(false);
        setTrends(sortedTrends);
      } catch (error) {
        console.error('Error fetching trends:', error);
        setLoading(false);
      }
    }

    fetchTrends();
  }, []);

  return (
    <div className="no-scrollbar col-span-12 rounded-xl border border-stroke bg-white py-6 shadow-default dark:border-strokedark dark:bg-brand-background xl:col-span-3">
      <div className="flex items-center space-x-6 ">
        <h4 className="text-md mb-2 px-4 font-semibold text-black dark:text-white">
          Trendings
        </h4>
        {/* <h5 className="text-md mb-2 px-4  text-black dark:text-white">
          <div className="">
            <select className="bg-transparent dark:bg-black">
              <option>Day</option>
              <option>Month</option>
              <option>Year</option>
            </select>
          </div>
        </h5> */}
      </div>

      {loading ? (
        <div className="mt-8 flex justify-center">
          <RingLoader size={70} color={'#36D7B7'} loading={loading} />
        </div>
      ) : (
        <div
          className="no-scrollbar  rounded-xl px-2"
          style={{ height: '380px', overflowY: 'scroll' }}
        >
          <div className="container rounded-xl dark:bg-black">
            {trends.map((trend) => (
              <Link
                key={trend._id}
                to={`/trends/${trend._id}`}
                className="flex items-center gap-5 hover:bg-gray-3 dark:hover:bg-meta-4"
              >
                <div className="col-span-5 flex items-center p-1">
                  <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                    <div className="h-20.5 w-35 overflow-clip rounded-xl">
                      <img src={trend.thumb_nail} alt="Product" />
                    </div>
                    <div className="container mr-4">
                      <p className="text-sm text-black dark:text-white">
                        {trend.name}
                      </p>
                      <p className="text-sm text-black dark:text-white">
                        Views: {trend.views}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TrendingVideos;
