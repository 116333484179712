import authHeader from "./auth-header";
import Trend from "./interfaces/trending-interface";
import { AxiosInstance } from "../common/Loader/axios.config";


class TrendsService {
  private static readonly API_BASE_URL = 'https://kanemaonline.com';
  private static readonly ACCESS_TOKEN = localStorage.getItem('accessToken');

  private static async fetchWithAuthorization<T>(url: string, options?: RequestInit): Promise<T> {
    // const headers = {
    //   'Content-Type': 'application/json',
    //   Authorization: `Bearer ${this.ACCESS_TOKEN}`,
    //   ...(options?.headers || {}),
    // };

    // const response = await fetch(`${this.API_BASE_URL}${url}`, {
    //   ...options,
    //   headers,
    // });
    // 
    // 
    // 
    const response = await AxiosInstance.get('/trends')

    if (response.status != 200) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    return response.data as Promise<T>;
  }

  static async getTrends(): Promise<Trend[]> {
    try {
      return this.fetchWithAuthorization<Trend[]>('/trends');
    } catch (error) {
      console.error('Error fetching trends:', error);
      throw error;
    }
  }

  static async createTrend(trendData: Trend): Promise<Trend> {
    try {
      return this.fetchWithAuthorization<Trend>('/trends', {
        method: 'POST',
        body: JSON.stringify(trendData),
      });
    } catch (error) {
      console.error('Error creating trend:', error);
      throw error;
    }
  }

  static async updateTrend(trendId: string, trendData: Partial<Trend>): Promise<Trend> {
    try {
      return this.fetchWithAuthorization<Trend>(`/trends/${trendId}`, {
        method: 'PUT',
        body: JSON.stringify(trendData),
      });
    } catch (error) {
      console.error('Error updating trend:', error);
      throw error;
    }
  }

  static async deleteTrend(trendId: string): Promise<void> {
    try {
      await this.fetchWithAuthorization<void>(`/trends/${trendId}`, {
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Error deleting trend:', error);
      throw error;
    }
  }
}

export default TrendsService;
