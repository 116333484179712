import axios from 'axios';

const accessToken: string =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NmI2MWZlMjBiNGJkZmViYTQ4MWVhYzUiLCJlbWFpbCI6InN3YWdnZXJAa2FuZW1hb25saW5lLmNvbSIsImlhdCI6MTcyMzIxMTc0NiwiZXhwIjoxNzM4NzYzNzQ2fQ.pp3N9TlJeL5ySWOhY3Na3dUFx_bmK-DIqvbgdg-ZhAo';
var localAccessToken = localStorage.getItem('accessToken');
const axiosInstance = axios.create({
  baseURL: 'https://kanema.staticcomputersinc.com/api',
  // baseURL: 'https://dev.staticcomputersinc.com/kanemaonline',
  // baseURL: 'http://64.227.45.60:1973',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  },
});

export { axiosInstance as AxiosInstance };
