import React, { useState, useEffect } from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import { AxiosInstance } from "../../../common/Loader/axios.config";
import VodService from '../../../services/vods-service';
import Select from 'react-select';
import { set } from "react-hook-form";
import Swal from 'sweetalert2';
import TVsService from "../../../services/tvs-service";
import EventService from "../../../services/event-service";
import { FaChevronCircleRight } from "react-icons/fa";




const CreatePackageForm = ({ }) => {
    const [name, setPackageName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(0);
    const [type, setType] = useState("");
    const [duration, setDuration] = useState(0);
    const [videoId, setVideoId] = useState("");
    const [_id, setID] = useState('');
    const [file, setThumbnailFile] = useState<File | null>(null);
    const [creating, setCreating] = useState(false);

    const [vods, setVods] = useState<Vod[]>([]);
    const [tvs, setTVs] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedOption, setSelectedOption] = useState("");

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);



    React.useEffect(() => {
        async function fetchVods() {
            try {
                setLoading(true);
                const fetchedVods = await VodService.getVods();

                setVods(fetchedVods);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        fetchVods();
    }, []);

    React.useEffect(() => {
        async function fetchEvents() {
            try {
                const fetchedEvents = await EventService.getEvents();

                setEvents(fetchedEvents);

            } catch (error) {
                setLoading(false);
            }
        }

        fetchEvents();
    }, []);

    React.useEffect(() => {
        async function fetchTVs() {
            try {
                setLoading(true);
                const fetchedTVs = await TVsService.getAllTVs();


                // setTVs(tvsWithInitialStatus);
                setTVs(fetchedTVs);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        fetchTVs();
    }, []);

    //   const options = [
    //     ...vods.map((vod) => ({
    //         value: vod._id,
    //         label: vod.name,
    //     })),
    // ];

    // const handleChange = (option) => {
    //     setSelectedOption(option.value);
    // };

    const options = selectOptions.map((vod) => ({
        value: vod._id,
        label: vod.name,
    }));

    const handleChange = (selectedOptions) => {
        // Set selectedOption as an array of selected values or an empty array if none are selected
        setSelectedOptions(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    const handleSubmit = async (e) => {
        try {
            setCreating(true);
            e.preventDefault();
            const createdPackage = {
                name,
                description,
                price,
                type,
                duration,
                videoId,
                content: selectedOptions || [],
            };
            const response = await AxiosInstance.post('/billing/package', createdPackage);
            if (response.status === 201) {
                setCreating(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Package created Successfully!',
                    showConfirmButton: false,
                    timer: 2000,
                });
                window.location.reload();
            } else {
            }

        } catch (error) {
            setCreating(false);
        } finally {
            setCreating(false);
        }
    };

    return (
        <form>
            <div className="flex flex-col items-center gap-x-4 w-full">
                <input
                    type="text"
                    name="packageName"
                    placeholder="Package Name"
                    className="my-2 w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-4 py-2"
                    value={name}
                    onChange={(e) => setPackageName(e.target.value)}
                />
                <br />
                <input
                    type="text"
                    name="description"
                    placeholder="Details/Description"
                    className="my-2 w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-4 py-2"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <br />
            </div>
            <div className="grid grid-cols-2 gap-4">

                <div className="col-span-2">
                    <input
                        type="number"
                        name="price"
                        placeholder="Package Price"
                        className="my-2 w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-4 py-4 text-2xl font-bold"
                        value={price}
                        onChange={(e) => setPrice(parseInt(e.target.value, 10))}
                    />
                </div>

                <div>
                    <input
                        type="text"
                        name="type"
                        placeholder="Subscription Type"
                        className="my-2 w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-4 py-4"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    />
                </div>

                <div>
                    <input
                        type="number"
                        name="duration"
                        placeholder="Duration"
                        className="my-2 w-full rounded-lg border-[1.5px] border-brand-stroke-color bg-transparent px-4 py-4"
                        value={duration}
                        onChange={(e) => setDuration(parseInt(e.target.value, 10))}
                    />
                </div>
            </div>
            <hr className="border-gray-300 my-4 border-t-2 border-dashed" />

            <div className="flex flex-col my-4 gap-y-2">
                <div className="flex items-center gap-x-2">
                    <input
                        type="checkbox"
                        id="liveTv"
                        name="liveTv"
                        className="h-5 w-5 bg-transparent border-[1.5px] border-brand-stroke-color"
                        value="All"
                        onChange={(e) => {
                            setType(e.target.value);
                            if (e.target.checked) {
                                setSelectedOptions([...vods.map((vod) => vod._id), ...tvs.map((tv) => tv._id), ...events.map((event) => event._id)]);
                            } else {

                                setSelectedOptions([]);
                            }
                        }}
                    />
                    <label htmlFor="liveTv" className="text-white text-lg">
                        All
                    </label>
                </div>

                <div className="flex items-center gap-x-2">
                    <input
                        type="checkbox"
                        id="liveTv"
                        name="liveTv"
                        className="h-5 w-5 bg-transparent border-[1.5px] border-brand-stroke-color"
                        value="tvs"
                        onChange={(e) => {
                            setType(e.target.value);
                            if (e.target.checked) {
                                if (selectedOptions.length === 0) {
                                    setSelectedOptions(tvs.map((tv) => tv._id));
                                } else {
                                    setSelectedOptions([...selectedOptions, ...tvs.map((tv) => tv._id)]);
                                }
                            } else {
                                setSelectedOptions(selectedOptions.filter((id) => !tvs.map((tv) => tv._id).includes(id)));
                            }
                        }}
                    />
                    <label htmlFor="liveTv" className="text-white text-lg">
                        Live TVs
                    </label>
                    <FaChevronCircleRight className="ml-10" color={selectOptions == tvs ? 'red' : 'white'} size={24} onClick={() => setSelectOptions(tvs)} />
                </div>

                <div className="flex items-center gap-x-2">
                    <input
                        type="checkbox"
                        id="liveEvents"
                        name="liveEvents"
                        className="h-5 w-5 bg-transparent border-[1.5px] border-brand-stroke-color"
                        value="events"
                        onChange={(e) => {
                            setType(e.target.value);
                            if (e.target.checked) {
                                if (selectedOptions.length === 0) {
                                    setSelectedOptions(events.map((event) => event._id));
                                } else {
                                    setSelectedOptions([...selectedOptions, ...events.map((event) => event._id)]);
                                }
                            } else {
                                setSelectedOptions(selectedOptions.filter((id) => !events.map((event) => event._id).includes(id)));
                            }
                        }}
                    />
                    <label htmlFor="liveEvents" className="text-white text-lg">
                        Live Events
                    </label>
                    <FaChevronCircleRight className="ml-4" color={selectOptions == events ? 'red' : 'white'} size={24} onClick={() => setSelectOptions(events)} />

                </div>

                <div className="flex items-center gap-x-2">
                    <input
                        type="checkbox"
                        id="videos"
                        name="videos"
                        className="h-5 w-5 bg-transparent border-[1.5px] border-brand-stroke-color"
                        value="Videos"
                        onChange={(e) => {
                            setType(e.target.value);
                            if (e.target.checked) {
                                if (selectedOptions.length === 0) {
                                    setSelectedOptions(vods.map((vod) => vod._id));
                                } else {
                                    setSelectedOptions([...selectedOptions, ...vods.map((vod) => vod._id)]);
                                }
                            } else {
                                setSelectedOptions(selectedOptions.filter((id) => !vods.map((vod) => vod._id).includes(id)));
                            }
                        }}
                    />
                    <label htmlFor="videos" className="text-white text-lg">
                        Videos
                    </label>

                    <FaChevronCircleRight className="ml-14" color={selectOptions == vods ? 'red' : 'white'} size={24} onClick={() => setSelectOptions(vods)} />

                </div>
            </div>


            <div className="col-span-2">
                <Select
                    isMulti
                    value={options.filter(option => selectedOptions.includes(option.value))}
                    onChange={handleChange}
                    options={options}
                    placeholder="Select Content..."
                />
            </div>

            <div className="flex items-center justify-between">
                <button className="border-[2px] border-brand-stroke-color bg-brand-background px-4 py-2 my-4 text-white rounded-xl px-4 items-center justify-center"
                    onClick={handleSubmit}>
                    {creating ? 'Creating...' : 'Create'}
                </button>

            </div>


        </form>
    );
};

export default CreatePackageForm;
