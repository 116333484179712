import {useState, useEffect} from 'react';

const Gateway = () => {

    return (

        <div className='text-white'>Gateway</div>
    )
}

export default Gateway;