// import * as React from 'react';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

// const columns: GridColDef[] = [
//   { field: 'id', headerName: 'ID', width: 70 },
//   { field: 'firstName', headerName: 'First name', width: 130 },
//   { field: 'lastName', headerName: 'Last name', width: 130 },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 90,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params: GridValueGetterParams) =>
//       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

// export default function ManageFeedback() {
//   return (
//     <div style={{ width: '100%' }}>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         initialState={{
//           pagination: {
//             paginationModel: { page: 0, pageSize: 5 },
//           },
//         }}
//         pageSizeOptions={[5, 10]}
//         checkboxSelection
//       />
//     </div>
//   );
// }

import BrandOne from '../../images/brand/brand-01.svg';
import ProductOne from '../../images/product/product-01.png';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useState, useEffect } from 'react';
import { AxiosInstance } from '../../common/Loader/axios.config';

const ITEM_HEIGHT = 48;

interface FeedbackResponse {
  data: string;
}

const ManageFeedback = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    AxiosInstance.get<FeedbackResponse>('/comments').then((response) => {
      const allcomments = response.data;
      // setFeedback(response.data);
    });
  }, []);

  return (
    <div className="rounded-xl border border-stroke bg-white px-5 pb-2.5 pt-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="flex flex-col">
        <div className="grid grid-cols-4 border-b border-stroke dark:border-strokedark sm:grid-cols-6">
          <div className="flex items-center gap-3 p-2.5 xl:p-3">
            <div className="flex-shrink-0">
              <img src={BrandOne} alt="Brand" />
            </div>
            <div>
              <p className="hidden text-black dark:text-white sm:block">
                CJ Designs
              </p>
              <p className="hidden text-xs text-black dark:text-white sm:block">
                No Sound, Please do something
              </p>
              <p className="text-grey hidden text-xs dark:text-white sm:block">
                2 days ago
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center p-2.5 xl:p-5">
            <div>
              <p className="hidden text-black dark:text-white sm:block">
                cjdesigns@gmail.com
              </p>
              <p className="hidden text-xs text-black dark:text-white sm:block">
                {' '}
                +265 992 888 353
              </p>
            </div>
          </div>

          <div className="items-center justify-center p-2.5 sm:flex xl:p-5">
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                color="inherit"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >
                <MenuItem>
                  <TelegramIcon />
                  <p className="p-2">Send Email</p>
                </MenuItem>
                <MenuItem>
                  <VisibilityOffOutlinedIcon />
                  <p className="p-2">Hide</p>
                </MenuItem>
                <MenuItem>
                  <DeleteIcon />
                  <p className="p-2">Delete forever</p>
                </MenuItem>
              </Menu>
            </div>
          </div>

          <div className="col-span-3 flex items-center">
            <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
              <div className="h-20.5 w-35 rounded-md">
                <img src={ProductOne} alt="Product" />
              </div>
              <div className="container mr-4">
                <p className="text-sm text-black dark:text-white">
                  CASTEL SAME SPIRIT LAUNCH
                </p>
                <p className="mt-4 text-xs text-black dark:text-white">
                  Loyal Hustle The Factory & QoQ Malawi presents the Henry Czar
                  Concert with performance by Phyzix, Quest, Charisma, Bee Jay
                  and Ace Jizzy and many more
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageFeedback;
