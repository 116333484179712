import BrandOne from '../../images/brand/brand-01.svg';
import Users from '../../services/interfaces/user-interface';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import UserService from '../../services/user-service';
import { RingLoader } from 'react-spinners';
import { AxiosInstance } from '../../common/Loader/axios.config';
import { set } from 'react-hook-form';
import Swal from 'sweetalert2';
import { MdDeleteForever } from "react-icons/md";

const UserInfo: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('JSXKU');

  const [user, setUser] = useState<Users | null>(null);
  const [loading, setLoading] = useState(true);

  const [activate, setActivate] = useState(false);
  const [deactivate, setDeactivate] = useState(false);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        if (userId) {
          const fetchedUser = await UserService.getUser(userId);
          console.log("Fetched User", fetchedUser.message[0].username);
          setUser(fetchedUser.message[0]);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    if (userId) {
      fetchUser();
    }
  }, [userId]);

  const handleStatusToggle = async () => {
    try {
      if (user) {
        const updatedStatus = user.status === 'Active' ? 'Blocked' : 'Active';
        const updatedUser = { ...user, status: updatedStatus };
        const results = await UserService.updateUser(userId || '', updatedUser);
        console.log(results);
        setUser(updatedUser);
      }
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      if (user) {
        const isConfirmed = window.confirm(
          `Are you sure you want to deactivate ${user.username}'s account?`,
        );
        if (isConfirmed) {
          setDeactivate(true);
          const response = await AxiosInstance.put(`/users/${userId}`, {
            status: {
              admin: "deactivated"
            }
          });
          setDeactivate(false);
          Swal.fire({
            icon: 'success',
            title: 'Account deactivated successfully!',
            text: `${user.username} account has been deactivated successfully!`,
            timer: 2000,
          });
          console.log(response);
        }
      }
    } catch (error) {
      console.error('Error deactivating user account:', error);
      setDeactivate(false);
      Swal.fire({
        icon: 'error',
        title: 'Failed to Deactivate User',
        text: 'An error occurred while deactivating the user account.',
      });
    }
  };

  const handleActivateAccount = async () => {
    try {
      if (user) {
        const isConfirmed = window.confirm(
          `Are you sure you want to Activate ${user.username}'s account?`,
        );
        if (isConfirmed) {
          setActivate(true);
          const response = await AxiosInstance.put(`/users/${userId}`, {
            status: {
              admin: "active"
            }
          });
          console.log(response);
          setActivate(false);
          Swal.fire({
            icon: 'success',
            title: 'Account Activated Successfully',
            text: `${user.username} account has been activated successfully!`,
            timer: 2000,
          });
        }
      }
    } catch (error) {
      console.error('Error deactivating user account:', error);
      setActivate(false);
      Swal.fire({
        icon: 'error',
        title: 'Failed to Activate User',
        text: 'An error occurred while Activating the user account.',
      });
    }
  };

  const handleDeleteAccount = async () => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete User!',
    });
  
    // Check if the user confirmed the action
    if (result.isConfirmed) {
      try {
        const response = await AxiosInstance.delete(`/users/${userId}`);
        if (response.status === 200) {
          console.log('User deleted successfully', response);
          // navigate('/dashboard');
          Swal.fire({
            icon: 'success',
            title: 'User deleted successfully!',
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          console.error('Failed to delete User', response.data);
        }
      } catch (error) {
        console.error('Failed to delete User', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete User!',
          text: 'Please try again later.',
        });
      }
    }
  }

  return (
    <div className="rounded-lg border border-stroke bg-white px-7.5 py-6 shadow-default dark:border-strokedark dark:bg-brand-background w-[400px]">
      <ArrowBackOutlinedIcon
        onClick={handleGoBack}
        style={{ cursor: 'pointer' }}
      />
      <div className="flex items-center justify-center p-4">
        <img src={BrandOne} alt="" />
      </div>
      <h4 className="mb-4 text-center text-xl text-black dark:text-white font-semibold py-4">
        {loading ? (
          <RingLoader color={'#36D7B7'} size={50} loading={loading} />
        ) : (
          user?.username || 'User Details'
        )}
      </h4>

      {!loading && user && (
        <div className="mb-6 flex items-end justify-between">
          <div>
            <span className="text-lg flex items-center gap-1 rounded-full py-5 text-black dark:text-white">
              <p>Details</p>
            </span>
            <span className="flex items-center gap-1 rounded-full py-2 text-md text-black dark:text-white">
              <p>Username: </p>
              <p>{user.username}</p>
            </span>
            {user.email && (
              <span className="flex items-center gap-1 rounded-full py-2 text-md text-black dark:text-white">
                <p>Email: </p>
                <p>{user.email}</p>
              </span>

            )}
            {user.phone && (
              <span className="flex items-center gap-1 rounded-full py-2 text-md text-black dark:text-white">
                <p>Phone : </p>
                <p>{user.phone}</p>
              </span>

            )}

            <span className="flex items-center gap-1 rounded-full py-2 text-md text-black dark:text-white">
              <p>Registered ISP : </p>
              <p>{user.isp}</p>
            </span>
            <span className="flex items-center gap-1 rounded-full py-2 text-md text-black dark:text-white">
              <p>City: </p>
              <p>{user.city}</p>
            </span>
            <span className="flex items-center gap-1 rounded-full py-2 text-md text-black dark:text-white">
              <p>Country: </p>
              <p>{user.country}</p>
            </span>
            <span className="flex items-start gap-1 rounded-full py-2 text-md text-black dark:text-white">
              <p>Registered: </p>
              <div className='flex flex-col justify-start'>
                <p className="text-black dark:text-white text-opacity-50">
                  {new Date(user.created_at).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
                <p className="text-black dark:text-white">
                  {new Date(user.created_at).toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZoneName: 'short',
                  })}
                </p>
              </div>
            </span>
          </div>
        </div>
      )}
      <div className='flex justify-between items-center'>
        {user?.status.admin == 'active' || user?.status.admin == 'authorized' ? (
          <div className='text-white text-md bg-brand-red px-4 py-2 my-8 shadow-lg rounded-lg text-center font-light cursor-pointer hover:scale-105'
            onClick={handleDeactivateAccount}>{deactivate ? 'DEACTIVATING...' : 'DEACTIVATE'}</div>

        ) : (
          <div className='text-white text-md bg-success px-4 py-2 my-8 shadow-lg rounded-lg text-center font-light cursor-pointer hover:scale-105'
            onClick={handleActivateAccount}>{activate ? 'ACTIVATING...' : 'ACTIVATE'}</div>

        )
        }

        <div className='flex items-center justify-center gap-x-3 text-white text-md bg-brand-red px-4 py-2 my-8 shadow-lg rounded-lg text-center font-light cursor-pointer hover:scale-105'
        onClick={handleDeleteAccount}>
        <MdDeleteForever size={24} />
          <p>Delete</p>
        </div>
      </div>

      {/* {!loading && user && (
        <Stack spacing={2} alignItems="center">
          <Button
            variant="contained"
            color={user.status === 'Active' ? 'error' : 'success'}
            onClick={handleStatusToggle}
          >
            {user.status === 'Active' ? 'Deactivate' : 'Activate'}
          </Button>
        </Stack>
      )} */}
    </div>
  );
};

export default UserInfo;
