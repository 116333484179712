import { Suspense, lazy, useEffect, useState } from 'react';
import { Router, Route, Routes } from 'react-router-dom';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';
import Loader from './common/Loader';
import Videos from './pages/Videos';
import Manage from './pages/Manage';
import Upload from './pages/Upload';
import GoLive from './pages/GoLive';
import Home from './pages/Home';
import VideoEdit from './pages/VideoEdit';
import User from './pages/User';
import ContinueUploading from './components/Upload/ContinueUpload';
import UploadDetails from './components/Upload/UploadDetails';
import UploadProgress from './components/Upload/UploadProgress';
import StreamElement from './components/Go Live/StreamElement';
import FormLayout from './pages/Other/Form/FormLayout';
import Live from './components/Go Live/GoLiveManage';
import VideoUploadForm from './components/VideoUploadForm';
import MultiStepForm from './components/Go Live/HandleNavigate';
import Notifications from './pages/Notifications';
import LiveForm from './components/Go Live/Live';

const DefaultLayout = lazy(() => import('./layout/DefaultLayout'));

function App() {
  // const [loading, setLoading] = useState<boolean>(true);

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 1000);
  // }, []);

  return (

    <>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}

        <Route index element={<SignIn />} />
        <Route path="/auth/signin" element={<SignIn />} />
        {/*
        <Route path="/auth/signup" element={<SignUp />} /> */}
        <Route element={<DefaultLayout />}>
          <Route
            path="/home"
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/Videos"
            element={
              <Suspense fallback={<Loader />}>
                <Videos />
              </Suspense>
            }
          />
          <Route
            path="/Manage"
            element={
              <Suspense fallback={<Loader />}>
                <Manage />
              </Suspense>
            }
          />
          <Route
            path="/Notifications"
            element={
              <Suspense fallback={<Loader />}>
                <Notifications />
              </Suspense>
            }
          />
          <Route
            path="/Upload"
            element={
              <Suspense fallback={<Loader />}>
                <Upload />
              </Suspense>
            }
          />
          <Route
            path="/GoLive"
            element={
              <Suspense fallback={<Loader />}>
                {/* <MultiStepForm /> */}
                <LiveForm />
              </Suspense>
            }
          />
          <Route
            path="/form"
            element={
              <Suspense fallback={<Loader />}>
                <FormLayout />
              </Suspense>
            }
          />
          <Route
            path="/ManageVideoEdit/:id"
            element={
              <Suspense fallback={<Loader />}>
                <VideoEdit />
              </Suspense>
            }
          />

          <Route
            path="/User"
            element={
              <Suspense fallback={<Loader />}>
                <User />
              </Suspense>
            }
          />
          <Route
            path="/ContinueUploading"
            element={
              <Suspense fallback={<Loader />}>
                <ContinueUploading />
              </Suspense>
            }
          />
          <Route
            path="/UploadDetails"
            element={
              <Suspense fallback={<Loader />}>
                <UploadDetails />
              </Suspense>
            }
          />
          <Route
            path="/UploadProgress"
            element={
              <Suspense fallback={<Loader />}>
                <UploadProgress />
              </Suspense>
            }
          />
          <Route
            path="/LiveStreamElement"
            element={
              <Suspense fallback={<Loader />}>
                <StreamElement />
              </Suspense>
            }
          />
          <Route
            path="/Live"
            element={
              <Suspense fallback={<Loader />}>
                <Live />
              </Suspense>
            }
          />
          <Route
            path="/VideoUploadForm-test"
            element={
              <Suspense fallback={<Loader />}>
                <VideoUploadForm />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
{
  /* <Route
            path="/settings"
            element={
              <Suspense fallback={<Loader />}>
                <Settings />
              </Suspense>
            }
          />
          <Route
            path="/chart"
            element={
              <Suspense fallback={<Loader />}>
                <Chart />
              </Suspense>
            }
          />
          <Route
            path="/ui/alerts"
            element={
              <Suspense fallback={<Loader />}>
                <Alerts />
              </Suspense>
            }
          />
          <Route
            path="/ui/buttons"
            element={
              <Suspense fallback={<Loader />}>
                <Buttons />
              </Suspense>
            }
          /> */
}
