import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import UserService from '../../services/user-service';
import BrandOne from '../../images/brand/brand-01.svg';
import User from '../../services/interfaces/user-interface';

import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

// Import the User interface here

const UserAnayltics = () => {
  const [stats, setStats] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const itemsPerPage = 1000; // Show three users per page
  const [currentPage, setCurrentPage] = useState(1);
  const [allUsers, setAllUsers] = useState<User[]>([]);

  const [dailyUsers, setDailyUsers] = useState(0);
  const [weeklyUsers, setWeeklyUsers] = useState(0);
  const [monthlyUsers, setMonthlyUsers] = useState(0);

  const isWithinTwoWeeks = (date: Date) => {
    const currentDate = new Date();
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return date >= twoWeeksAgo && date <= currentDate;
  };

  const newUsersCount = users.filter((user) =>
    isWithinTwoWeeks(new Date(user.created_at))
  ).length;

  const pendingUsersCount = users.filter(
    (user) => user.status.admin === 'pending'
  ).length;

  const activeUsersCount = users.filter(
    (user) => user.status.admin === 'active' || user.status.admin === 'authorized'
  ).length;
  

  useEffect(() => {
    async function fetchUserStatistics() {
      try {
        const statisticsData = await UserService.getUserStatistics();
        setStats(statisticsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user statistics:', error);
        setLoading(false);
      }
    }

    fetchUserStatistics();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const skip = (currentPage - 1) * itemsPerPage;
        const response = await UserService.getAllUsers();
        // const response = await UserService.getUsersByPagination(skip, itemsPerPage);

        setUsers(response);
        calculateUserCounts(response);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const calculateUserCounts = (users) => {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const thisWeek = new Date(now);
      thisWeek.setDate(thisWeek.getDate() - 7);
      const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);

      const dailyCount = users.filter(user => new Date(user.created_at) >= today).length;
      const weeklyCount = users.filter(user => new Date(user.created_at) >= thisWeek).length;
      const monthlyCount = users.filter(user => new Date(user.created_at) >= thisMonth).length;

      console.log("Daily Users", dailyCount);
      console.log("Weekly Users", weeklyCount);
      console.log("Monthly Users", monthlyCount);

      setDailyUsers(dailyCount);
      setWeeklyUsers(weeklyCount);
      setMonthlyUsers(monthlyCount);
    };

    fetchUsers();
  }, [currentPage]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await UserService.getAllUsers();
        setAllUsers(response);
        console.log('All Users Response', response);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [currentPage]);

  return (
    <div className="rounded-xl border border-stroke bg-white px-5 mx-1 pb-2.5 pt-6 shadow-default dark:border-strokedark dark:bg-brand-background sm:px-7.5 xl:pb-1">
      <Link to="/manage?tab=1">
        <h4 className="mb-6 text-center text-xl text-black dark:text-white">
          User Analytics <KeyboardDoubleArrowRightOutlinedIcon />
        </h4>
      </Link>
      <div className="flex flex-col">
        <div className="flex items-center justify-between rounded-sm sm:grid-cols-2">
          <div className='flex flex-col items-center justify-center rounded-xl bg-transparent py-2.5 px-10 my-2 border border-[#81C784] w-fit'>
            <h5 className='text-title-md font-medium'>{dailyUsers}</h5>

            <p className='text-[#81C784] text-md'>Daily</p>
          </div>

          <div className='flex flex-col items-center justify-center rounded-xl bg-transparent py-2.5 px-9 my-2 border border-[#87CEEB] w-fit'>
            <h5 className='text-title-md font-medium'>{weeklyUsers}</h5>

            <p className='text-[#87CEEB] text-md'>Weekly</p>
          </div>

          <div className='flex flex-col items-center justify-center rounded-xl bg-transparent py-2.5 px-10 my-2 border border-[#FFD700] w-fit'>
            <h5 className='text-title-md font-medium'>{monthlyUsers}</h5>

            <p className='text-[#FFD700] text-md'>Monthly</p>
          </div>

        </div>
        <div className="flex items-center justify-between rounded-sm sm:grid-cols-2">

          <div className='flex flex-col items-center justify-center rounded-xl bg-transparent py-2.5 px-8 my-2 border border-white w-fit'>
            <h5 className='text-title-md font-medium'>{users.length}</h5>
            <p className='text-md'>Total</p>
          </div>

          <div className='flex flex-col items-center justify-center rounded-xl bg-transparent py-2.5 px-10 my-2 border border-success w-fit'>
            <h5 className='text-title-md font-medium'> {activeUsersCount} </h5>
            <p className='text-success text-md'>Active</p>
          </div>

          <div className='flex flex-col items-center justify-center rounded-xl bg-transparent py-2.5 px-10 my-2 border border-warning w-fit'>
            <h5 className='text-title-md font-medium'>{pendingUsersCount}</h5>
            <p className='text-warning text-md'>Pending</p>
          </div>
        </div>

        <div className="grid grid-cols-1 border-t border-stroke dark:border-strokedark sm:grid-cols-1">
          <div className="flex items-center justify-between px-4">
            <div className="p-2.5 text-right sm:block xl:p-2">
              <p className="text-md font-semibold">User</p>
            </div>

            <div className="p-2.5 text-right sm:block xl:p-2">
              <p className="text-md font-semibold">Account Creation Date</p>
            </div>
          </div>
        </div>

        {/* ... (existing code) */}
        {loading ? (
          <div className="mt-8 flex justify-center">
            <RingLoader size={70} color={'#36D7B7'} loading={loading} />
          </div>
        ) : (
          <div
            className="no-scrollbar"
            style={{ maxHeight: '290px', overflowY: 'scroll' }}
          >
            {users.map((user) => (
              <div
                key={user._id}
                className="grid grid-cols-2 border-t border-stroke dark:border-strokedark sm:grid-cols-2"
              >
                <div className="flex items-center gap-3 p-2.5 xl:p-5">
                  {/* <div className="flex-shrink-0">
                  <img src={BrandOne} alt="Brand" />
                </div> */}
                  <p className="hidden text-black dark:text-white sm:block">
                    {user.email ?? user.phone}
                  </p>

                  {/* <p className="hidden text-black dark:text-white sm:block">
                    {user.username}
                  </p> */}
                </div>
                <div className="justify-center p-2.5 text-right xl:p-5">
                  <p className="text-black dark:text-white">
                    {new Date(user.created_at).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      // second: '2-digit',
                    })}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* <div className="mt-4 flex justify-center">
          <button
            className="rounded-md bg-boxdark px-4 py-2 text-white"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="ml-2 rounded-md bg-boxdark px-4 py-2 text-white"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={users.length < itemsPerPage}
          >
            Next
          </button>
        </div> */}
      </div>
    </div>
  );
};
export default UserAnayltics;
