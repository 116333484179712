import React from 'react';
import upload from '../../images/Upload icon.png';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Import useSelector to access Redux state
import { RootState } from '../../store/store';


const UploadProgress: React.FC = () => {
  // Get the videoInfo and thumbnail data from Redux state
  const videoInfo = useSelector((state: RootState) => state.upload.videoInfo);
  const thumbnailUrl = useSelector((state: RootState) => state.upload.thumbnail);

  return (
    <>
      <div className='container dark:bg-black bg-white rounded-lg'>
        <div className='p-4'>
          <Link to="/uploadDetails">
            <ArrowBackOutlinedIcon />
          </Link>
        </div>
        <div className='flex justify-center'>
          <form className='mx-auto py-20 w-1/3' action="">
            <div className="">
              <div className="mt-2 rounded-lg px-2 py-2">
                <div className="dark:bg-[#233040] rounded border p-2">
                  <div className='mx-auto h-74 w-75'>
                    {thumbnailUrl && <img src={thumbnailUrl} alt="Selected Thumbnail"  className='mx-auto h-54 w-55 p-4'/>}
                  </div>
                  <h6 className="text-xs mt-4 flex justify-center leading-5 font-bold text-gray-600">We're still Processing your files</h6>
                  <div className="flex justify-center mt-4 flex text-sm leading-6 text-gray-600">
                    <label htmlFor="file-upload" className="relative cursor-pointer rounded-md font-semibold focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                      <span className='text-danger'>Change File</span>
                      <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                  </div>
                </div>
                <p className="text-xs mt-10 leading-5 text-gray-600">Uploading - 1/1 files</p>
                <div>
                  <h6>{videoInfo.name}</h6> {/* Display video name */}
                  <div className="w-full dark:bg-black h-1 mb-6">
                    <div className="bg-success h-1 w-1/2"></div>
                  </div>
                </div>
                <h6 className='flex justify-center w-full text-success py-2 mt-4'>PLEASE WAIT...</h6>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UploadProgress;
