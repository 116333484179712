import axios from 'axios';
import Swal from 'sweetalert2';
import { setisTrending } from '../../store/uploadSlice';
import { Dispatch } from 'redux'; // Import Dispatch from redux
import { useDispatch } from 'react-redux';
import { AxiosInstance } from '../../common/Loader/axios.config';

class ManageLiveEventService {
  dispatch = useDispatch();

 

  async promoteToTrending(eventId: string): Promise<void> {
    try {
      console.log("Event ID", eventId);
      const body = {
        'status.promoted': true,  
      }
      // Fetch all events
      const response = await AxiosInstance.put(`/${eventId}/content`, body);

      console.log("Response", response);


      if(response.status == 200) {

        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'Event promoted to trending successfully!',
          showConfirmButton: false,
          timer: 2000, // Auto-close after 2 seconds
        });
      } else {
        throw new Error('Failed to promote event');
      }

      
    } catch (error) {
      console.log('the error is ', error);
      // Show error message
      Swal.fire({
        icon: 'error',
        title: 'Failed to promote content to trending',
        text: 'An error occurred while promoting the event.',
      });
      throw new Error('Failed to promote event to trending');
    }
  }

  async depromoteToTrending(eventId: string): Promise<void> {
    try {


      const body = {
        'status.promoted': false,  
      }
      // Fetch all events
      const response = await AxiosInstance.put(`/${eventId}/content`, body);


      if(response.status == 200) {

        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'Event demoted from trending successfully!',
          showConfirmButton: false,
          timer: 2000, // Auto-close after 2 seconds
        });
      } else {
        throw new Error('Failed to demote event');
      }

      
    } catch (error) {
      console.log('the error is ', error);
      // Show error message
      Swal.fire({
        icon: 'error',
        title: 'Failed to demote event from trending',
        text: 'An error occurred while demoting the event.',
      });
      throw new Error('Failed to demote event to trending');
    }
  }




  // Edit an event
  async editEvent(eventId: string, eventData: any): Promise<void> {
    try {
      await this.AxiosInstance.put(`/${eventId}`, eventData);
    } catch (error) {
      throw new Error('Failed to edit event');
    }
  }
  // End an event and delete it
  async endEvent(
    eventId: string
  ): Promise<{ acknowledged: boolean; deletedCount: number }> {
    try {
      const response = await this.AxiosInstance.delete(`/${eventId}`);
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error('Failed to end event');
    }
  }

  async resetTrendingState(): Promise<void> {
    try {
      // Dispatch the setisTrending action with 'false' to reset the state
      this.dispatch(setisTrending(false));
    } catch (error) {
      console.error('Failed to reset trending state:', error);

      // Show error message
      Swal.fire({
        icon: 'error',
        title: 'Failed to reset trending state',
        text: 'An error occurred while resetting the trending state.',
      });

      throw new Error('Failed to reset trending state');
    }
  }
}
export default ManageLiveEventService;

//   async promoteToTrending(eventId: string): Promise<void> {
//     try {
//       // Fetch all events
//       const response = await this.axiosInstance.get('');

//       // Find the event with the given eventId in the response data
//       const eventsToUpdate = response.data;
//       const eventToPromote = eventsToUpdate.find(
//         (event: any) => event._id === eventId
//       );

//       if (!eventToPromote) {
//         throw new Error('Event not found');
//       }

//       // Update all fetched events to "type" of "normal" within the "status" object
//       const alert = await Promise.all(
//         eventsToUpdate.map(async (event: any) => {
//           if (event.status && event.status.type === 'live') {
//             await this.axiosInstance.put(`/${event._id}`, {
//               status: {
//                 type: 'normal',
//                 lifetime: event.status.lifetime,
//               },
//             });
//           }
//         })
//       );

//       console.log('data 1', alert);

//       // Update the specific event with the given eventId to "type" of "live" within the "status" object
//       const alert2 = await this.axiosInstance.put(`/${eventId}`, {
//         status: {
//           type: 'live',
//           lifetime: eventToPromote.status.lifetime,
//         },
//       });

//       console.log('data 2', alert2);

//       // Show success message
//       Swal.fire({
//         icon: 'success',
//         title: 'Event promoted to trending successfully!',
//         showConfirmButton: false,
//         timer: 2000, // Auto-close after 2 seconds
//       });
//     } catch (error) {
//       // Show error message
//       Swal.fire({
//         icon: 'error',
//         title: 'Failed to promote event to trending',
//         text: 'An error occurred while promoting the event.',
//       });
//       throw new Error('Failed to promote event to trending');
//     }
//   }
//   // Promote an event to trending
//   async promoteToTrending(eventId: string): Promise<void> {
//     try {
//       // Fetch events with "type" of "live"
//       const response = await this.axiosInstance.get('', {
//         params: { type: 'normal' },
//       });

//       // Update all fetched events to "type" of "normal"
//       const eventsToUpdate = response.data;

//       console.log(eventsToUpdate);
//       const data = await Promise.all(
//         eventsToUpdate.map(async (event: any) => {
//           await this.axiosInstance.put(`/${event._id}`, {
//             type: 'live',
//           });
//         })
//       );
//       console.log('Fetched data ', data);
//       // Update the specific event with the given eventId to "type" of "live"
//       await this.axiosInstance.put(`/${eventId}`, {
//         type: 'live',
//       });
//     } catch (error) {
//       throw new Error('Failed to promote event to trending');
//     }
//   }
