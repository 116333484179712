import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// Adjust the import path
import { useNavigate, Link } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { setThumbnail, setUploadedFile } from '../../store/uploadSlice';

const ContinueUploading: React.FC = () => {
   const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [uploadedFile, setLocalUploadedFile] = useState<File | null>(null); // Local component state for uploaded file

  const handleThumbnailSelection = (thumbnailUrl: string) => {
    setSelectedImage(thumbnailUrl);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLocalUploadedFile(file); // Set local state for uploaded file
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBrowseClick = () => {
    const inputElement = document.getElementById('thumbnailInput');
    if (inputElement) {
      inputElement.click();
    }
  };

  const handleContinue = () => {
    if (selectedImage && uploadedFile) {
      dispatch(setThumbnail(selectedImage));
      dispatch(setUploadedFile(uploadedFile)); // Dispatch the uploaded file to Redux
      // Navigate to the next page
      navigate('/UploadDetails');
    } else {
      // Handle error, maybe show a message to select a thumbnail and file
    }
  };

  return (
    <>
      <div className="container dark:bg-[#233040] bg-white rounded-lg" style={{ height: '800px' }}>
        <div className="p-4">
          <Link to="/Upload">
            <ArrowBackOutlinedIcon />
          </Link>
        </div>
        <form className="mx-auto py-10 w-1/3" action="">
          <div className="mx-auto h-100 w-84">
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-green-900 px-6 py-4">
              <div className="text-center mt-6 cursor-pointer" onClick={handleBrowseClick}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="hidden"
                  id="thumbnailInput"
                />
                {selectedImage ? (
                  <img src={selectedImage} alt="Selected Thumbnail" className="mx-auto h-74 w-84" />
                ) : (
                  <div className="mx-auto h-12 w-12">
                    {/* Your default thumbnail image */}
                  </div>
                )}
                <div className="mt-10 flex justify-center text-md leading-6 text-black">
                  <p className="text-white">Click here to select a thumbnail</p>
                </div>
                <p className="text-xs mt-4 flex justify-center">
                  NOTE: This process cannot be done again pressing back will discard all the changes made
                </p>
              </div>
            </div>
            <button
              onClick={handleContinue}
              className="w-full bg-black border text-white py-2 mt-4 rounded-full hover:bg-success"
              disabled={!selectedImage || !uploadedFile}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ContinueUploading;



// import React, { useState } from 'react';
// import upload from '../../images/Upload icon.png';
// import { Link , useNavigate} from 'react-router-dom';
// import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
// import { useDispatch } from 'react-redux';
// import { setThumbnail } from '../../store/uploadSlice';


// const ContinueUploading: React.FC = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate(); // Use useNavigate from the correct location
//   const [selectedImage, setSelectedImage] = useState<string | null>(null);

//   const handleThumbnailSelection = (thumbnailUrl: string) => {
//     setSelectedImage(thumbnailUrl);
//   };

//   const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setSelectedImage(reader.result as string);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleBrowseClick = () => {
//     const inputElement = document.getElementById('thumbnailInput');
//     if (inputElement) {
//       inputElement.click();
//     }
//   };

//   const handleContinue = () => {
//     if (selectedImage) {
//       dispatch(setThumbnail(selectedImage));
//       // Navigate to the next page
//       navigate("/UploadDetails"); // Use navigate to navigate
//     } else {
//       // Handle error, maybe show a message to select a thumbnail
//     }
//   };

//   return (
//     <>
//           <div className="container dark:bg-[#233040] bg-white rounded-lg" style={{ height: '800px' }}>


//         <div className='p-4'>
//           <Link to="/Upload">
//             <ArrowBackOutlinedIcon/>
//           </Link>
//         </div>
//         <form className="mx-auto py-10 w-1/3" action="">
//           <div className="mx-auto h-100 w-84">
//             <div className="mt-2 flex justify-center rounded-lg border border-dashed border-green-900 px-6 py-4">
//               <div className="text-center mt-6 cursor-pointer" onClick={handleBrowseClick}>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   onChange={handleImageChange}
//                   className="hidden"
//                   id="thumbnailInput"
//                 />
//                 {selectedImage ? (
//                   <img src={selectedImage} alt="Selected Thumbnail" className="mx-auto h-74 w-84" />
//                 ) : (
//                   <div className="mx-auto h-12 w-12">
//                     {/* Your default thumbnail image */}
//                   </div>
//                 )}
//                 <div className="mt-10 flex justify-center text-md leading-6 text-black">
//                   <p className="text-white">Click here to select a thumbnail</p>
//                 </div>
//                 <p className="text-xs mt-4 flex justify-center">
//                   NOTE: This process cannot be done again pressing back will discard all the changes made
//                 </p>
//               </div>
//             </div>
//             <button
//               onClick={handleContinue}
//               className="w-full bg-black border text-white py-2 mt-4 rounded-full hover:bg-success"
//               disabled={!selectedImage}
//             >
//               Continue
//             </button>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default ContinueUploading;



// const ContinueUploading: React.FC = () => {
//   return (
//     <>
//         <div className="container dark:bg-black bg-white rounded-lg">
//           <div className='p-4'>
//             <Link to="/Upload">
//               <ArrowBackOutlinedIcon/>
//             </Link>
//           </div>
//           <form className="mx-auto py-10 w-1/3" action="">
//             <div className="">
//               <div className="mt-2 flex justify-center rounded-lg border border-dashed border-green-900 px-6 py-4">
//                 <div className="text-center mt-6">
//                   <div className="mx-auto h-12 w-12">
//                     <img src={upload} alt="" />
//                   </div>
//                   <div className="mt-40 flex justify-center text-md leading-6 text-black">
//                     <p className="text-white">Please press continue to add details and thumbnail</p>
//                   </div>
//                   <p className="text-xs mt-4 flex justify-center">
//                     NOTE: This process cannot be done again pressing back will discard all the changes made
//                   </p>
//                 </div>
//               </div>
//               <Link to="/UploadDetails">
//                 <button className="w-full bg-black border text-white py-2 mt-4 rounded-full hover:bg-success">Continue</button>
//               </Link>
//             </div>
//           </form>
//         </div>
//     </>
//   );
// };

// export default ContinueUploading;
// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { Link, useNavigate } from 'react-router-dom';
// import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
// import { setThumbnailFile } from '../../store/uploadSlice';

// const ContinueUploading: React.FC = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
//   const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);

//   const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setSelectedImageFile(file);
//         setSelectedImageUrl(reader.result as string);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleBrowseClick = () => {
//     const inputElement = document.getElementById('thumbnailInput');
//     if (inputElement) {
//       inputElement.click();
//     }
//   };

//   const handleContinue = () => {
//     if (selectedImageFile) {
//       dispatch(setThumbnailFile(selectedImageFile));
//     }
//     navigate('/UploadDetails');
//   };

//   return (
//     <>
//       <div className="container dark:bg-[#233040] bg-white rounded-lg" style={{ height: '800px' }}>
//         <div className="p-4">
//           <Link to="/Upload">
//             <ArrowBackOutlinedIcon />
//           </Link>
//         </div>
//         <form className="mx-auto py-10 w-1/3" action="">
//           <div className="mx-auto h-100 w-84">
//             <div className="mt-2 flex justify-center rounded-lg border border-dashed border-green-900 px-6 py-4">
//               <div className="text-center mt-6 cursor-pointer" onClick={handleBrowseClick}>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   onChange={handleImageChange}
//                   className="hidden"
//                   id="thumbnailInput"
//                 />
//                 {selectedImageUrl ? (
//                   <img src={selectedImageUrl} alt="Selected Thumbnail" className="mx-auto h-74 w-84" />
//                 ) : (
//                   <div className="mx-auto h-12 w-12">
//                     {/* Your default thumbnail image */}
//                   </div>
//                 )}
//                 <div className="mt-10 flex justify-center text-md leading-6 text-black">
//                   <p className="text-white">Click here to select a thumbnail</p>
//                 </div>
//                 <p className="text-xs mt-4 flex justify-center">
//                   NOTE: This process cannot be done again pressing back will discard all the changes made
//                 </p>
//               </div>
//             </div>
//             <button
//               onClick={handleContinue}
//               className="w-full bg-black border text-white py-2 mt-4 rounded-full hover:bg-success"
//               disabled={!selectedImageFile}
//             >
//               Continue
//             </button>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default ContinueUploading;



// const ContinueUploading: React.FC = () => {
//   return (
//     <>
//         <div className="container dark:bg-black bg-white rounded-lg">
//           <div className='p-4'>
//             <Link to="/Upload">
//               <ArrowBackOutlinedIcon/>
//             </Link>
//           </div>
//           <form className="mx-auto py-10 w-1/3" action="">
//             <div className="">
//               <div className="mt-2 flex justify-center rounded-lg border border-dashed border-green-900 px-6 py-4">
//                 <div className="text-center mt-6">
//                   <div className="mx-auto h-12 w-12">
//                     <img src={upload} alt="" />
//                   </div>
//                   <div className="mt-40 flex justify-center text-md leading-6 text-black">
//                     <p className="text-white">Please press continue to add details and thumbnail</p>
//                   </div>
//                   <p className="text-xs mt-4 flex justify-center">
//                     NOTE: This process cannot be done again pressing back will discard all the changes made
//                   </p>
//                 </div>
//               </div>
//               <Link to="/UploadDetails">
//                 <button className="w-full bg-black border text-white py-2 mt-4 rounded-full hover:bg-success">Continue</button>
//               </Link>
//             </div>
//           </form>
//         </div>
//     </>
//   );
// };

// export default ContinueUploading;
