import axios from 'axios';
import TV from './interfaces/tv-interface';
import Event from './interfaces/live-event-interface';
import Vod from './interfaces/vods-interface';
import { AxiosInstance } from '../common/Loader/axios.config';

class videoDetailsApiService {
  private readonly baseUrl: string = 'https://kanema.staticcomputersinc.com';
  // private readonly axiosInstance: AxiosInstance;

  constructor() {
    const accessToken = localStorage.getItem('accessToken');
    this.axiosInstance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async fetchTV(tvId: string): Promise<TV> {
    const response = await AxiosInstance.get(`/content/${tvId}/search`);
    console.log("Single TV Response",response);

    return response.data;
  }

  async fetchEvent(eventId: string): Promise<Event> {
    const response = await AxiosInstance.get(`/content/${eventId}/search`);
    console.log("Single Event Response",response);
    return response.data;
  }

  async fetchVOD(vodId: string): Promise<Vod> {
    const response = await AxiosInstance.get(`/content/${vodId}/search`);
    console.log("Single Video Response",response);
    return response.data;
  }
}

export default videoDetailsApiService;

















