import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setVideoInfo } from '../../store/uploadSlice';
import VideoUploadService from '../../services/aws-upload-service';
import Swal from 'sweetalert2';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import Select from 'react-select';
import { FaRegClock } from "react-icons/fa";
import { FaMinus } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";
import { AxiosInstance } from '../../common/Loader/axios.config';
import { Tabs, Tab, TextField } from '@mui/material';
import ExistingUpload from './existing_upload';

const UploadDetails: React.FC = () => {
  const [progress, setProgress] = useState<number | null>(null);
  const [uploadStatus, setUploadStatus] = useState<"idle" | "inProgress" | "success" | "failure">("idle");
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [price, setPrice] = useState(0);
  const [title, setTitle] = useState('');
  const [city, setCity] = useState('');
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [region, setRegion] = useState('');
  const [trailer, setTrailer] = useState('');

  const [startDate, setStartDate] = useState('');


  const renameVideoFile = (file: File,): File => {
    const fileNameWithoutExtension = file.name
      .split('.')
      .slice(0, -1)
      .join('.');
    const fileExtension = file.name.split('.').pop();

    // Replace spaces with underscores
    const renamedFileName = fileNameWithoutExtension.replace(/ /g, '_');

    // Add category-specific suffix
    let renamedFileWithCategory = renamedFileName;
    if (category === 'Live Events') {
      renamedFileWithCategory += '_event';
    }

    if (category === 'Videos') {
      renamedFileWithCategory += '_vod';
    }

    renamedFileWithCategory += `.${fileExtension}`;

    console.log('renamedFileWithCategory', renamedFileWithCategory);

    // Create a new File object with the renamed name
    return new File([file], renamedFileWithCategory, { type: file.type });
  };

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setVideoFile(file);
    }
  };


  // const updateProgress = (percentCompleted: number) => {
  //   setProgress(percentCompleted);
  // };

  const decreasePrice = () => {
    setPrice(price - 10);
  };

  const increasePrice = () => {
    setPrice(price + 10);
  };

  const [providers, setProviders] = useState([]);

  useEffect(() => {
    AxiosInstance.get("/content-providers")
      .then((response) => {
        const allProviders = response.data;

        console.log("All Providers", allProviders);

        const sortedProviders = allProviders.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });

        setProviders(sortedProviders)
      })
      .catch((error) => {
      });
  }, []);

  const providerOptions = providers.map((provider) => ({
    value: provider.id,
    label: provider.name,
  }));

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [video_provider, setVideoProvider] = useState('');


  const handleProviderChange = (option) => {
    setSelectedProvider(option);
    setVideoProvider(option.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile && title) {
      const fileExtension = selectedFile.name.split('.').pop();
      const newFileName = `${title}.${fileExtension}`;
      const renamedFile = new File([selectedFile], newFileName, {
        type: selectedFile.type,
      });

      setThumbnail(renamedFile);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!videoFile || !thumbnail) {
      Swal.fire({
        icon: 'error',
        title: 'Missing Files',
        text: 'Please upload both a video file and a thumbnail before submitting.',
      });
      return; 
    }

    setUploadStatus("inProgress");

    const formData = new FormData();

    formData.append("name", title);
    formData.append("region", region);
    formData.append("description", description);
    formData.append("category", category);

    formData.append("video_provider", video_provider);
    formData.append("project_name", projectName);
    formData.append("price", price);


    if (startDate) {
      formData.append("start_time", startDate);
    }

    if (videoFile) {
      formData.append("video", videoFile);
    }

    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    if (trailer) {
      formData.append("trailer", trailer);
    }

    for (const [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(`${key}:`, value.name, value.size, value.type);
      } else {
        console.log(`${key}: ${value}`);
      }
    }

    try {
      const response = await AxiosInstance.post("/cf-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });

      console.log("Upload Response", response);

      if (response.status === 201) {
        if (response.data.status === "succesful") {
          setUploadStatus("success");
          console.log("Upload successful:", response.data.message);
          Swal.fire({
            icon: 'success',
            title: '!',
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          setUploadStatus("failure");
          console.error("Upload failed:", response.data.message);
        }
      } else {
        setUploadStatus("failure");
        console.error("Upload failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const existingUploadRef = useRef(); 

  const handleParentSubmit = () => {
    if (existingUploadRef.current) {
      existingUploadRef.current.handleSubmit(videoFile); 
    }
  };

  return (
    <>
      <div className='flex items-start w-[1200px]'>
        <div className="flex flex-col justify-between rounded-lg bg-white dark:bg-brand-background mx-2 w-[40%] h-[800px] p-4">

          <div className="">

            <div className='bg-transparent border border-brand-stroke-color rounded-lg w-[100%] p-2'>
              <div className='flex flex-col items-center justify-center bg-black rounded-lg w-[100%] text-white text-center h-[190px] cursor-pointer'>
                <label htmlFor="file-input" className="cursor-pointer">
                  {videoFile ? `${videoFile.name}` : "Select File To Upload"}
                  <input
                    type="file"
                    id="file-input"
                    className="hidden"
                    onChange={handleVideoUpload}
                  />
                </label>

              </div>
            </div>

            <div className='w-[100%] rounded-lg p-2 border border-brand-stroke-color my-2'>
              <div className='flex justify-between items-center py-1 text-white'>
                <p className='text-white text-md'>Progress</p>
                {uploadStatus === "idle" && <p className="text-white">-</p>}
                {uploadStatus === "success" && <p className="text-success text-sm">Upload Complete!</p>}
                {uploadStatus === "failure" && <p className="text-danger text-sm">Upload Failed. Try Again!</p>}
                {uploadStatus === "inProgress" && <p className="text-primary text-sm">Uploading...</p>}
                {/* <FaRegClock /> */}
              </div>
              <LinearProgress
                variant="determinate"
                value={progress || 0}
                className={`my-1 py-1 rounded-full ${uploadStatus === "success" ? "bg-success" : uploadStatus === "failure" ? "bg-brand-red" : "bg-primary"
                  }`}
              />

            </div>
          </div>

          {value == 0 && (
            <div className='bg-white rounded-full text-black text-lg py-1 px-4 w-fit font-semibold mx-auto my-2 cursor-pointer hover:scale-105' onClick={handleSubmit}>
              Create
            </div>
          )}

          {value == 1 && (
            <div className='bg-white rounded-full text-black text-lg py-1 px-4 w-fit font-semibold mx-auto my-2 cursor-pointer hover:scale-105'
            onClick={handleParentSubmit} >
              Upload
            </div>
          )}

        </div>

        <div className="flex flex-col justify-between rounded-lg bg-white dark:bg-brand-background mx-2 w-[60%] h-[800px] p-4">

          <div>
            <div className='bg-transparent border border-brand-stroke-color rounded-lg w-[100%] p-2'>
              {/* <p className='text-lg text-white py-2'>Details</p> */}

              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { display: 'none' } }}
              >
                {['New', 'Existing'].map((label, index) => (
                  <div
                    key={index}
                    className={`tab-item px-3 text-sm rounded-full bg-brand-background py-2 mx-2 border-[1.3px] border-brand-stroke-color shadow-lg cursor-pointer ${value === index ? 'py-1 border border-white text-white font-bold rounded-full bg-transparent' : ''}`}
                    onClick={() => handleChange(null, index)}
                  >
                    <span>{label}</span>
                  </div>
                ))}
              </Tabs>

              {value == 0 && (
                <div>
                  <div>
                    <p className='text-white'>Name:</p>
                    <input type='text'
                      className='w-[100%] rounded-lg p-2 bg-transparent border border-brand-stroke-color my-2'
                      placeholder='Title'
                      name='name'
                      id='name'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div>
                    <p className='text-white'>Description</p>
                    <input type='text'
                      className='w-[100%] rounded-lg p-2 bg-transparent border border-brand-stroke-color my-2'
                      placeholder='Description'
                      name='description'
                      id='description'
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  {/* <div>
                    <p className='text-white'>City</p>
                    <input type='text'
                      className='w-[100%] rounded-lg p-2 bg-transparent border border-brand-stroke-color my-1'
                      placeholder='City'
                      name='city'
                      id='name'
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div> */}

                  <div>
                    <p className='text-white'>Project Name</p>
                    <input type='text'
                      className='w-[100%] rounded-lg p-2 bg-transparent border border-brand-stroke-color my-2'
                      placeholder='Project Name'
                      name='Project Name'
                      id='Project'
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                    />
                  </div>

                  <div>
                    <p className='text-white'>Trailer</p>
                    <input type='text'
                      className='w-[100%] rounded-lg p-2 bg-transparent border border-brand-stroke-color my-2'
                      placeholder='Trailer'
                      name='Trailer'
                      id='trailer'
                      value={trailer}
                      onChange={(e) => setTrailer(e.target.value)}
                    />
                  </div>

                  <div className='flex justify-between items-center gap-x-5'>
                    <div className='w-1/2'>
                      <p className='text-md text-white py-1'>Region</p>

                      <select
                        className='w-[100%] rounded-lg p-2 bg-transparent border border-brand-stroke-color my-2'
                        name='category'
                        id='category'
                        value={region}
                        onChange={(e) => setRegion(e.target.value)}
                      >
                        <option value=''>Select Region</option>
                        <option value='MW'>Malawi</option>
                        <option value='KE'>Kenya</option>
                        <option value='ZM'>Zambia</option>
                        <option value='ZW'>Zimbabwe</option>
                      </select>
                    </div>

                    <div className='w-1/2'>
                      <p className='text-md text-white py-1'>Category</p>

                      <select
                        className='w-[100%] rounded-lg p-2 bg-transparent border border-brand-stroke-color my-2'
                        name='category'
                        id='category'
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value=''>Select Category</option>
                        <option value='tvs'>Live TVs</option>
                        <option value='event'>Live Events</option>
                        <option value='vods'>Videos</option>
                      </select>
                    </div>
                  </div>

                  <div className='flex items-center justify-between gap-x-4'>

                    <div className='text-white w-1/2'>
                      <p>Publish Date</p>
                      <div className="p-2">
                        <input
                          type="datetime-local"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          className="w-full p-2 rounded bg-transparent border border-brand-stroke-color text-white"
                        />
                      </div>
                    </div>

                    <div className='w-1/2'>
                      <p className='text-white'>Video Provider</p>
                      <div className="w-full text-black border border-brand-stroke-color rounded-lg p-2 my-2" id="select">
                        <Select
                          value={selectedProvider}
                          onChange={handleProviderChange}
                          options={providerOptions}
                          placeholder="Select Provider..."
                        />
                      </div>
                    </div>

                  </div>

                  <div className='flex justify-between bg-transparent border border-brand-stroke-color rounded-lg w-[100%] p-2'>

                    <div className='p-4'>
                      <p className='text-lg text-white py-2'>Thumbnail</p>
                      <input
                        className='w-[75%] rounded-lg p-4 bg-transparent border border-brand-red my-2'
                        type='file'
                        id='thumbnailInput'
                        onChange={(e) => {
                          handleFileChange(e);
                        }}
                      />
                    </div>

                    <div className='p-4'>
                      <p className='text-lg text-white py-2'>Price</p>

                      <div className='flex items-center gap-x-1'>
                        <FaMinus className='cursor-pointer hover:scale-105' onClick={decreasePrice} />
                        <input className='w-[75%] rounded-lg p-4 bg-transparent border border-brand-stroke-color my-2' type='text' id='price' value={price} onChange={(e) => setPrice(e.target.value)}></input>
                        <IoMdAdd className='cursor-pointer hover:scale-105' onClick={increasePrice} />
                      </div>
                    </div>

                  </div>
                </div>
              )}

              {value == 1 && (
                <ExistingUpload setUploadStatus={setUploadStatus} setProgress={setProgress} ref={existingUploadRef} />
              )}


            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDetails;


