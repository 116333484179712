import { useState, useEffect } from 'react'
import { AxiosInstance } from '../../../common/Loader/axios.config'
import { Tabs } from '@mui/material';
import { MdEdit, MdOutlineFeedback, MdOutlineSubtitles } from 'react-icons/md';
import { Overview, ViewsOverview } from './views_overview';

export const Analytics = ({ contentName, contentID }) => {

    const [contentViews, setContentViews] = useState([])
    const [loadingViews, setLoadingViews] = useState(false)

    const getViewership = async () => {
        setLoadingViews(true);
        try {
            // if (userId) {

            const response = await AxiosInstance.get(`users/viewership`);

            if (response.status == 200) {
                const allViews = response.data.message;
                const filteredViews = allViews.filter(view => view.contentid === contentID);
                setContentViews(filteredViews)
                setLoadingViews(false);

            } else {
                setLoadingViews(false);
            }
            // }
        } catch (error) {
            setLoadingViews(false);
        } finally {

        }
    }

    useEffect(() => {
        getViewership();
    }, [contentID]);

    const handleRefresh = () => {
        console.log("Reloading...")
        getViewership()
    }

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    return (
        <div className=' py-8 flex flex-col items-start justify-start'>
            <p>{contentName} Analytics</p>
            <div className='my-3'>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    orientation='horizontal'
                >
                    {['Overview', 'Reach', 'Audience'].map((label, index) => (
                        <div
                            key={index}
                            className={`tab-item flex items-center justify-start p-4 px-3 text-sm mx-1 shadow-lg cursor-pointer ${tabValue === index ? 'text-white bg-transparent' : 'bg-transparent'}`}
                            onClick={() => handleTabChange(null, index)}
                        >
                            <span>{label}</span>
                        </div>
                    ))}
                </Tabs>

                {tabValue == 0 && (
                    <ViewsOverview contentName={contentName} contentViews={contentViews} reloadViews={handleRefresh} />
                )}

            </div>
        </div>
    )
}