import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { IoMdRefresh } from "react-icons/io";
import { WatchTimeView } from './watchhours_overview';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const ViewsOverview = ({ contentName, contentViews, reloadViews }) => {
    const [selectedPeriod, setSelectedPeriod] = useState('Weekly');
    const [overview, setOverview] = useState('views');
    const [chartData, setChartData] = useState({ labels: [], data: [] });

    const [totalWatchHours, setTotalWatchHours] = useState(0);
    const [watchTimeInMinutes, setWatchTimeInMinutes] = useState(0);

    const [totalViews, setTotalViews] = useState(0);
    const [dailyViews, setDailyViews] = useState(0);
    const [weeklyViews, setWeeklyViews] = useState(0);
    const [monthlyViews, setMonthlyViews] = useState(0);

    const [dailyHours, setDailyHours] = useState(0);
    const [weeklyHours, setWeeklyHours] = useState(0);
    const [monthlyHours, setMonthlyHours] = useState(0);

    // Calculate Total Views
    useEffect(() => {
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();

        const uniqueUserIds = new Set();

        console.log("Content Views for Mathumela", contentViews)

        contentViews.forEach((view) => {
            const viewDate = new Date(view.start_time);
            const viewMonth = viewDate.getMonth();
            const viewYear = viewDate.getFullYear();


            if (viewMonth === currentMonth && viewYear === currentYear) {
                uniqueUserIds.add(view.userid);
            }
        });

        console.log("Unique User Ids", uniqueUserIds);

        setTotalViews(uniqueUserIds.size);
        setMonthlyViews(uniqueUserIds.size);
    }, [contentViews, reloadViews]);

    //    Calculate Watch Time
    useEffect(() => {
        const calculateWatchHours = () => {
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            const currentYear = currentDate.getFullYear();

            let totalHours = 0;
            let totalMinutes = 0;

            // Filter contentViews for the current month
            const currentMonthViews = contentViews.filter((view) => {
                const startTime = new Date(view.start_time);
                const viewMonth = startTime.getMonth();
                const viewYear = startTime.getFullYear();
                return viewMonth === currentMonth && viewYear === currentYear;
            });

            currentMonthViews.forEach((view) => {
                const startTime = new Date(view.start_time);
                const endTime = new Date(view.end_time);

                const diffInMs = endTime - startTime;
                const hours = diffInMs / (1000 * 60 * 60);
                totalHours += hours;

                const minutes = diffInMs / (1000 * 60);
                totalMinutes += minutes;
            });

            if (totalHours < 1) {
                setWatchTimeInMinutes(totalMinutes.toFixed(0));
            } else {
                setTotalWatchHours(totalHours.toFixed(2));
                setWatchTimeInMinutes(0);
            }
        };

        calculateWatchHours();
    }, [contentViews]);

    // Function to handle period change
    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
    };

    // Function to aggregate views according to period
    useEffect(() => {
        const aggregateViews = () => {
            const viewCounts = {};
            const uniqueUsers = {};
            const now = new Date();

            if (selectedPeriod === 'Today') {
                const currentHour = now.getHours();
                const startHour = currentHour - 5 < 0 ? 24 + (currentHour - 5) : currentHour - 5;

                const labels = Array.from({ length: 6 }, (_, i) => {
                    const hour = (startHour + i) % 24;
                    return `${hour}:00 - ${hour + 1}:00`;
                });

                const uniqueUsers = new Set();
                let totalViewsToday = 0;  // Variable to hold the total views for today

                contentViews.forEach((view) => {
                    const viewDate = new Date(view.start_time);
                    const viewHour = viewDate.getHours();
                    const label = `${viewHour}:00 - ${viewHour + 1}:00`;

                    if (
                        viewDate >= new Date(now.getTime() - 6 * 60 * 60 * 1000) &&
                        viewDate <= now &&
                        !uniqueUsers.has(view.userid)
                    ) {
                        uniqueUsers.add(view.userid);
                        viewCounts[label] = (viewCounts[label] || 0) + 1;
                        totalViewsToday += 1;  // Increment the total views count for today
                    }
                });

                setChartData({
                    labels: labels,
                    data: labels.map(label => viewCounts[label] || 0),
                });

                // Set the total views for the day in state
                setDailyViews(totalViewsToday);
            }



            else if (selectedPeriod === 'Weekly') {
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 6);

                const labels = [];
                for (let i = 0; i < 7; i++) {
                    const date = new Date(oneWeekAgo);
                    date.setDate(oneWeekAgo.getDate() + i);
                    const label = date.toISOString().split('T')[0];
                    labels.push(label);
                    viewCounts[label] = 0;
                }

                const uniqueUsers = new Set();
                let totalViews = 0;

                contentViews.forEach((view) => {
                    const viewDate = new Date(view.start_time);
                    const label = viewDate.toISOString().split('T')[0];

                    if (viewDate >= oneWeekAgo && viewDate <= now && !uniqueUsers.has(view.userid)) {
                        uniqueUsers.add(view.userid);
                        if (labels.includes(label)) {
                            viewCounts[label] += 1;
                            totalViews += 1;
                        }
                    }
                });

                // Set the total weekly views in state
                setWeeklyViews(totalViews);

                setChartData({
                    labels: labels,
                    data: labels.map(label => viewCounts[label] || 0),
                });
            }


            else if (selectedPeriod === 'Monthly') {
                const sixMonthsAgo = new Date();
                sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 5);

                const labels = Array.from({ length: 6 }, (_, i) => {
                    const date = new Date();
                    date.setMonth(sixMonthsAgo.getMonth() + i);
                    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
                });

                labels.forEach(label => {
                    viewCounts[label] = 0;
                    uniqueUsers[label] = new Set();
                });

                contentViews.forEach((view) => {
                    const viewDate = new Date(view.start_time);
                    const label = `${viewDate.getFullYear()}-${(viewDate.getMonth() + 1).toString().padStart(2, '0')}`;

                    if (viewDate >= sixMonthsAgo && viewDate <= now) {
                        if (!uniqueUsers[label].has(view.userid)) {
                            uniqueUsers[label].add(view.userid);
                            viewCounts[label]++;
                        }
                    }
                });

                setChartData({
                    labels,
                    data: labels.map(label => viewCounts[label]),
                });
            }
        };

        aggregateViews();
    }, [selectedPeriod, contentViews]);



    // Views chart Data
    const data = {
        labels: chartData.labels,
        datasets: [
            {
                label: `${selectedPeriod} Views`,
                data: chartData.data,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#fff',
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#fff',
                },
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    color: '#fff',
                    count: 5,
                    stepSize: 20,
                    callback: function (value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    }
                },
                grid: {
                    color: '#444',
                    borderDash: [5, 5],
                    borderDashOffset: 3,
                },
                beginAtZero: true,
                suggestedMax: Math.ceil(Math.max(...chartData.data) / 5) * 5,
            },
        },
    };

    // Reload
    const handleReload = () => {
        reloadViews();
    };

    return (
        <div className='flex flex-col items-center justify-center'>
            <div className='w-full px-4 flex items-center justify-between'>
                <div></div>
                <div>
                    <h1 className='text-sm text-white text-center py-3'>
                        {contentName} got {totalViews} views in the selected period
                    </h1>
                </div>
                <div className='cursor-pointer hover:scale-110'>
                    <IoMdRefresh size={24} onClick={handleReload} />
                </div>
            </div>

            <div className='w-full mx-auto border border-brand-stroke-color rounded-xl p-4'>
                <div className='flex items-start justify-between m-8'>
                    <div className='flex items-center justify-center gap-x-8'>
                        <button className={`flex flex-col mx-auto py-4 w-40 rounded-xl border ${overview === "views" ? "border-brand-red bg-black bg-opacity-30" : "border-white border-opacity-40"}`}
                            onClick={() => setOverview("views")}>
                            <p className='text-center mx-auto'>Views</p>
                            <h1 className='text-center text-2xl mx-auto'>{totalViews}</h1>
                        </button>
                        <button className={`flex flex-col py-4 w-40 rounded-xl border ${overview === "watchTime" ? "border-brand-red bg-black bg-opacity-30" : "border-white border-opacity-40"}`}
                            onClick={() => setOverview("watchTime")}>
                            <p className='text-center mx-auto'>Watch Time</p>
                            <h1 className='text-center text-2xl mx-auto'>
                                {watchTimeInMinutes > 0
                                    ? `${watchTimeInMinutes} min`
                                    : `${totalWatchHours} hrs`}
                            </h1>
                        </button>
                    </div>


                    <div className='relative'>
                        <select
                            className='w-40 py-2 px-3 rounded-xl bg-black bg-opacity-30 text-white text-center cursor-pointer'
                            value={selectedPeriod}
                            onChange={handlePeriodChange}
                        >
                            <option value="Today" className='bg-black'>Daily</option>
                            <option value="Weekly" className='bg-black'>Weekly</option>
                            <option value="Monthly" className='bg-black'>Monthly</option>
                        </select>
                    </div>

                </div>

                {overview == "views" && (
                    <div className='h-60'>
                        <Line data={data} options={options} />
                    </div>
                )}

                {overview == "watchTime" && (
                    <div className='w-full'>
                        <WatchTimeView
                            contentViews={contentViews}
                            selectedPeriod={selectedPeriod}
                            setDailyHours={setDailyHours}
                            setWeeklyHours={setWeeklyHours}
                            setMonthlyHours={setMonthlyHours}
                        />
                    </div>
                )}

                <div className='w-full flex flex-col p-8 my-4 rounded-xl border border-brand-stroke-color bg-transparent py-6 px-7.5 shadow-default'>
                    <p className='py-4 text-white'>Summary</p>
                    <div className='flex items-center justify-center gap-x-4 my-3'>
                        <div className='w-[40vh] p-4 rounded-xl border border-brand-stroke-color'>
                            <p className='text-white'>Today</p>
                            <p className='text-white'>Views: {dailyViews}</p>
                            <p className='text-white'>Watch Hours: {dailyHours}</p>
                        </div>
                        <div className='w-[40vh] p-4 rounded-xl border border-brand-stroke-color'>
                            <p className='text-white'>This Week</p>
                            <p className='text-white'>Views: {weeklyViews}</p>
                            <p className='text-white'>Watch Hours: {weeklyHours}</p>
                        </div>
                    </div>

                    <div className='flex items-center justify-start gap-x-4'>
                        <div className='w-[40vh] p-4 rounded-xl border border-brand-stroke-color'>
                            <p className='text-white'>This month</p>
                            <p className='text-white'>Views: {monthlyViews}</p>
                            <p className='text-white'>Watch Hours: {watchTimeInMinutes > 0
                                ? `${watchTimeInMinutes} min`
                                : `${totalWatchHours} hrs`}
                            </p>
                        </div>
                        {/* <div className='w-[40vh] p-8 rounded-xl border border-brand-stroke-color'></div> */}
                    </div>
                </div>

            </div>
        </div>
    );
};
