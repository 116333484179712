import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatBubbleOutlineIcon from '@mui/icons-material/MarkChatRead';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import React, { useEffect, useState } from 'react';
import Vod from '../../services/interfaces/vods-interface';
import VodService from '../../services/vods-service';

const VideosCard = () => {
  const [vods, setVods] = useState<Vod[]>([]);

  useEffect(() => {
    async function fetchVods() {
      try {
        const fetchedVods = await VodService.getVods();

        console.log("New fetchedVods",fetchedVods)
        setVods(fetchedVods);
      } catch (error) {
        console.error('Error fetching VODs:', error);
      }
    }

    fetchVods();
  }, []);

  return (
    <div className="col-span-12 rounded-xl border border-stroke bg-white py-2 shadow-default dark:border-strokedark dark:bg-brand-background xl:col-span-3">
      <Link to="/videos">
        <h4 className="text-md mb-2 px-7.5 text-center font-semibold text-black dark:text-white">
          Content <KeyboardDoubleArrowRightOutlinedIcon />
        </h4>
      </Link>

      <div className="px-2">
        <div
          className="no-scrollbar container rounded-xl dark:bg-black"
          style={{ height: '380px', overflowY: 'scroll' }}
        >
          {Array.isArray(vods) && vods.map((vod) => (
            <div className="col-span-5 flex items-center p-1" key={vod._id}>
              <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                <div className="h-20.5 w-35 rounded-xl">
                  <img
                    className="rounded-xl"
                    src={vod.thumb_nail}
                    alt={vod.name}
                  />
                </div>
                <div className="container mr-4">
                  <p className="text-sm text-black dark:text-white">
                    {vod.name}
                  </p>
                  <div className="mt-3 flex flex-col gap-1 text-xs text-black dark:text-white sm:flex-row sm:items-center">
                    <div className="px-2">
                      <VisibilityIcon /> {vod.views}
                    </div>
                    {/* Uncomment the following lines if you have a comments array in Vod */}
                    {/* <div className="">
                      <BarChartIcon />
                      {vod.comments}
                    </div> */}
                    <div className="px-2">
                      <ChatBubbleOutlineIcon /> {vod.comments}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideosCard;
