import React from 'react'
import { useEffect, useState, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { MdMoreVert } from "react-icons/md";
import dateTime from 'date-and-time';
import { AxiosInstance } from '../../../common/Loader/axios.config';
import Swal from 'sweetalert2';
import { set } from 'react-hook-form';
import UnpaidSubFields from './unpaid_sub_fields';
import { CiExport } from "react-icons/ci";
import UnpaidSubscriptionsModal from './export_unpaid_modal';
import TVsService from '../../../services/tvs-service';



const UnpaidSubscriptions = ({ unpaidSubscriptions, setUnpaidSubscriptions, searchTerm, allUnpaid, loggedInProvider, isAdmin, isTV }) => {

    const [loading, setLoading] = useState(false);
    const [openMenuId, setOpenMenuId] = useState(null);
    const menuRef = useRef(null);
    const [activating, setActivating] = useState(false);
    const [exportModal, setExportModal] = useState(false);


    const toggleMenu = (id) => {
        setOpenMenuId((prevId) => (prevId === id ? null : id));
    };


    const [columnWidths, setColumnWidths] = useState({
        Start_Date: 70,
        User: 150,
        PayerID: 100,
        Payment_Reference: 100,
        Package: 50,
        Period: 50,
        Expiry_Date: 50,
        Amount: 50,
        Status: 50,
        Action: 50,
        UserID: 150,
        Renewal_Date: 70,

    });

    const resizeHandleRef = useRef(null);

    const [selectedFields, setSelectedFields] = useState([
        'Start_Date',
        'Expiry_Date',
        'User',
        'PayerID',
        'Payment_Reference',
        'Package',
        'Period',
        'Amount',
        'Status',
    ]);

    // Handle mousedown on the resize handle
    const onMouseDown = (e, columnName) => {
        e.preventDefault();
        const startX = e.clientX;
        const startWidth = columnWidths[columnName];

        let animationFrameId;

        const onMouseMove = (e) => {
            // Cancel the previous frame
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }

            // Request a new animation frame
            animationFrameId = requestAnimationFrame(() => {
                const newWidth = Math.max(50, startWidth + (e.clientX - startX));
                setColumnWidths((prevWidths) => ({
                    ...prevWidths,
                    [columnName]: newWidth,
                }));
            });
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            // Cancel any pending animation frame
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const handleActivate = async (sub_id) => {

        const body = {
            status: "paid"
        }

        setActivating(true);

        const response = await AxiosInstance.put(`billing/${sub_id}/update-subscriptions`, body);

        if (response.status == 200) {
            console.log("Update Response Success", response)
            Swal.fire({
                icon: 'success',
                title: 'Subscription Activated Successfully!',
                showConfirmButton: false,
                timer: 2000,
            });

            setActivating(false);

            setOpenMenuId(null);

            window.location.reload();

        } else {

        }
    };

    useEffect(() => {
        let baseUnpaid;

        if(isAdmin){
            baseUnpaid = allUnpaid;
        } else if (loggedInProvider?.content) {
            baseUnpaid = unpaidSubscriptions.filter((sub) =>
              loggedInProvider.content.includes(sub.package_name)
            );

            if (isTV) {
                const kiliyeSubscriptions = unpaidSubscriptions.filter(
                  (sub) => sub.package_name === "KiliyeKiliye"
                );
                baseUnpaid = [...baseUnpaid, ...kiliyeSubscriptions];
              }
        } else {
            baseUnpaid = [];
        }

        const sortedBaseSubs = baseUnpaid.sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        })

        if (searchTerm === '') {
            setUnpaidSubscriptions(sortedBaseSubs);
        } else {
            const filtered = sortedBaseSubs.filter((subscription) => {
                return subscription.username?.toLowerCase().includes(searchTerm.toLowerCase());
            });
            setUnpaidSubscriptions(filtered);
        }
    }, [searchTerm, unpaidSubscriptions]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpenMenuId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleFieldChange = (updatedFields) => {
        setSelectedFields(updatedFields);
    };

    return (
        <div className='flex flex-col justify-center'>

            <div className='flex items-center justify-between my-2'>
                <div className="text-black border border-brand-stroke-color rounded-lg p-1" id="select">

                    <UnpaidSubFields
                        options={Object.keys(columnWidths).filter((key) => key !== 'Action')}
                        label="Select Fields"
                        selectedOptions={selectedFields}
                        onChange={handleFieldChange}
                    />
                </div>

                <div className='flex items-center justify-center gap-x-4'>

                    <div className='text-white rounded bg-brand-background px-4 py-2 rounded-lg w-fit font-bold my-2'>Total Subscriptions: {unpaidSubscriptions.length}</div>


                    <div className='flex gap-x-2 items-center justify-center rounded bg-white px-4 py-2 rounded-lg w-fit my-2 cursor-pointer hover:scale-105'
                        onClick={() => setExportModal(true)}>
                        <CiExport size={20} color="black" />
                        <p className="text-black text-md">Export</p>
                    </div>

                </div>

            </div>

            <table className="table-auto w-full bg-brand-background shadow-md rounded-lg">
                <thead>
                    <tr className="text-white text-sm text-center">
                        {/* {Object.keys(columnWidths).map((columnName) => (
                            <th
                                key={columnName}
                                className="p-4 relative border-r border-brand-stroke-color"
                                style={{ width: `${columnWidths[columnName]}px` }}
                            >
                                {columnName}
                                <div
                                    ref={resizeHandleRef}
                                    className="resize-handle absolute right-0 top-0 h-full w-2 cursor-col-resize"
                                    onMouseDown={(e) => onMouseDown(e, columnName)}
                                />
                            </th>
                        ))} */}

                        {selectedFields.map((columnName) => (
                            <th
                                key={columnName}
                                className="p-4 relative border-r border-brand-stroke-color"
                                style={{ width: `${columnWidths[columnName]}px` }}
                            >
                                {columnName}
                                <div
                                    ref={resizeHandleRef}
                                    className="resize-handle absolute right-0 top-0 h-full w-2 cursor-col-resize"
                                    onMouseDown={(e) => onMouseDown(e, columnName)}
                                />
                            </th>
                        ))}
                        <th
                            key="Action"
                            className="p-4 relative border-r border-brand-stroke-color"
                            style={{ width: `${columnWidths['Action']}px` }}
                        >
                            Action
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={8} className="py-4 text-center">
                                <CircularProgress />
                            </td>
                        </tr>
                    ) : (
                        unpaidSubscriptions.map((subscription, index) => (

                            <tr key={subscription._id} className="hover:bg-gray-100 text-white text-xs text-center">
                                {selectedFields.map((columnName) => {
                                    let content = null;

                                    if (columnName === 'Start_Date') {
                                        content = dateTime.format(new Date(Date.parse(subscription.created_at)), 'DD/MM/YY HH:mm ');
                                    } else if (columnName === 'Renewal_Date') {
                                        content = dateTime.format(new Date(Date.parse(subscription.renewed_at)), 'DD/MM/YY HH:mm ');
                                    } else if (columnName === 'User') {
                                        content = subscription.username;
                                    } else if (columnName === 'UserID') {
                                        content = subscription.userid;
                                    } else if (columnName === 'PayerID') {
                                        content = subscription.payerid;
                                    } else if (columnName === 'Payment_Reference') {
                                        content = subscription.payment_reference;
                                    } else if (columnName === 'Package') {
                                        content = subscription.package_name;
                                    } else if (columnName === 'Period') {
                                        content = subscription.duration;
                                    } else if (columnName === 'Expiry_Date') {
                                        content = dateTime.format(
                                            new Date(Date.parse(subscription.created_at) + subscription.duration * 24 * 60 * 60 * 1000),
                                            'DD MMM, YYYY'
                                        );
                                    } else if (columnName === 'Amount') {
                                        content = subscription.price;
                                    } else if (columnName === 'Status') {
                                        content = subscription.status
                                    }

                                    return (
                                        <td key={columnName} className="py-4 px-1">
                                            {content}
                                        </td>
                                    );
                                })}
                                <td key="Action" className="py-4 px-1 relative">
                                    <MdMoreVert
                                        className="ml-6"
                                        size={18}
                                        onClick={() => toggleMenu(subscription._id)}
                                    />

                                    {openMenuId === subscription._id && (
                                        <div
                                            ref={menuRef}
                                            className="absolute top-full right-0 w-32 bg-gray-800 border border-gray-600 rounded shadow-lg z-10"
                                        >
                                            <button
                                                onClick={() => handleActivate(subscription._id)}
                                                className="block px-3 py-1 w-full text-center text-lg cursor-pointer bg-white text-black"
                                            >
                                                {activating ? "Activating..." : "Activate"}
                                            </button>
                                        </div>
                                    )}
                                </td>

                            </tr>

                            // <tr className="hover:bg-gray-100 text-white text-xs text-center">
                            //     <td className="py-4 px-1">
                            //         {dateTime.format(
                            //             new Date(Date.parse(subscription.created_at)),
                            //             'DD MMM, YYYY'
                            //         )}
                            //     </td>
                            //     <td className="py-4 px-1">
                            //         {dateTime.format(
                            //             new Date(Date.parse(subscription.renewed_at)),
                            //             'DD MMM, YYYY'
                            //         )}
                            //     </td>
                            //     <td className="py-4 px-1"> {subscription.username}</td>
                            //     <td className="py-4 px-1">{subscription.userid}</td>
                            //     <td className="py-4 px-1">{subscription.package_name}</td>
                            //     <td className="py-4 px-1">{subscription.duration}</td>
                            //     <td className="py-4 px-1">
                            //         {dateTime.format(
                            //             new Date(Date.parse(subscription.created_at) + (subscription.duration * 24 * 60 * 60 * 1000)),
                            //             'DD MMM, YYYY'
                            //         )}
                            //     </td>
                            //     <td className="py-4 px-1">{subscription.price}</td>
                            //     <td className={`py-4 px-1 text-danger`}>
                            //         {/* {Date.parse(subscription.created_at) + (subscription.duration * 24 * 60 * 60 * 1000) < Date.now() ? (<p className="text-danger">Expired</p>) : (<p className="text-brand-green">
                            //             Active
                            //         </p>)} */}
                            //         {subscription.status}
                            //     </td>

                            //     <td className="py-4 px-1 text-white cursor-pointer relative w-[100px]">
                            //         <MdMoreVert
                            //             className="ml-6"
                            //             size={18}
                            //             onClick={() => toggleMenu(subscription._id)}
                            //         />

                            //         {openMenuId === subscription._id && (
                            //             <div
                            //                 ref={menuRef}
                            //                 className="absolute top-full right-0 w-32 bg-gray-800 border border-gray-600 rounded shadow-lg z-10"
                            //             >
                            //                 <button
                            //                     onClick={() => handleActivate(subscription._id)}
                            //                     className="block px-3 py-1 w-full text-center text-lg cursor-pointer bg-white text-black"
                            //                 >
                            //                     {activating ? "Activating..." : "Activate"}
                            //                 </button>
                            //             </div>
                            //         )}
                            //     </td>
                            // </tr>
                        ))
                    )}
                </tbody>
            </table>

            {exportModal && (
            <UnpaidSubscriptionsModal subscriptions={unpaidSubscriptions} setExportModal={setExportModal} />
          )}

        </div>
    )
}

export default UnpaidSubscriptions;