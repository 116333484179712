import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Airtel from '../../images/Avatar.png';
import TNM from '../../images/TnmAvatar.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AxiosInstance } from '../../common/Loader/axios.config';
import dateTime from 'date-and-time';
import { useRef } from 'react';
import SubscriptionModal from './create_subscription_modal';
import { LuLogOut } from "react-icons/lu";
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { set } from 'react-hook-form';

const UserActivity: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('JSXKU');

    const [loginSection, setLoginSection] = useState(true)
    const [subSection, setSubSection] = useState(true)

    const [subscriptions, setSubscriptions] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const [allStatuses, setAllStatuses] = useState([]);
    const [userStatuses, setUserStatuses] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingSubs, setLoadingSubs] = useState(false);

    const getSubscriptions = async () => {
        setLoadingSubs(true);
        try {
            if (userId) {

                const response = await AxiosInstance.get(`/billing/${userId}/subscriptions`);

                if (response.status == 200) {
                    setSubscriptions(response.data.message)
                    setLoadingSubs(false);
                } else {
                    setLoadingSubs(false);
                }
            }
        } catch (error) {
            setLoadingSubs(false);
        } finally {

        }
    }

    useEffect(() => {
        getSubscriptions();
    }, [userId]);

    const [columnWidths2, setColumnWidths2] = useState({
        Device_ID: 200,
        Device_Model: 100,
        Login_at: 100,
        Logout_at: 100,
        Status: 100,
        Action: 100,
    });

    const [columnWidths, setColumnWidths] = useState({
        Plan: 200,
        price: 100,
        Start_Date: 100,
        Expiry_Date: 100,
        Status: 100,
    });

    const resizeHandleRef = useRef(null);

    // Handle mousedown on the resize handle
    const onMouseDown = (e, columnName) => {
        e.preventDefault();
        const startX = e.clientX;
        const startWidth = columnWidths[columnName];

        let animationFrameId;

        const onMouseMove = (e) => {
            // Cancel the previous frame
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }

            // Request a new animation frame
            animationFrameId = requestAnimationFrame(() => {
                const newWidth = Math.max(50, startWidth + (e.clientX - startX));
                setColumnWidths((prevWidths) => ({
                    ...prevWidths,
                    [columnName]: newWidth,
                }));
            });
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            // Cancel any pending animation frame
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const getLoginStatus = async () => {
        const allStatuses = [];
        const limit = 1000;
        let skip = 0;

        setLoadingStatus(true)

        try {
            while (true) {
                const response = await AxiosInstance.get('users/login-statuses', {
                    params: { limit, skip },
                });

                const statuses = Array.isArray(response.data)
                    ? response.data
                    : response.data?.message || response.data?.items || [];

                if (statuses.length === 0) break;

                allStatuses.push(...statuses);
                skip += limit;
            }

            const filteredStatuses = allStatuses.filter(status => status.userid === userId);
            const sortedStatuses = filteredStatuses.sort((a, b) =>
                new Date(b.loggedOutAt) - new Date(a.loggedOutAt)
            );

            console.log("Sorted Statuses", sortedStatuses);

            setUserStatuses(sortedStatuses);
            setLoadingStatus(false)
        } catch (error) {
            console.error('Error fetching statuses:', error);
            setLoadingStatus(false)
        }
    };

    useEffect(() => {
        getLoginStatus();
    }, [userId]);

    const handleLogout = async (token) => {
        const result = await Swal.fire({
            title: 'Sure to Logout User?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout User!',
        });

        // Check if the user confirmed the action
        if (result.isConfirmed) {
            try {
                const body = {
                    "userid": userId,
                    "token": token,
                    "type": "dashboard"
                }

                console.log("Logout Body", body)
                const response = await AxiosInstance.post(`/auth/logout`, body);
                if (response.status === 201) {
                    console.log('User Logged Out successfully', response);
                    Swal.fire({
                        icon: 'success',
                        title: 'User Logged Out successfully!',
                        showConfirmButton: false,
                        timer: 2000,
                    });

                    getLoginStatus();
                } else {
                    console.error('Failed to Logout User', response.data);
                }
            } catch (error) {
                console.error('Failed to Logout User', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to Logout User!',
                    text: 'Please try again later.',
                });
            }
        }
    }

    const toggleLoginSection = () => {
        setLoginSection((prevState) => !prevState);
    };

    const toggleSubSection = () => {
        setSubSection((prevState) => !prevState);
    };

    const handleRefresh = () => {
        getLoginStatus()
    };

    const handleRefreshSubs = () => {
        getSubscriptions()
    };


    return (
        <div className="rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-brand-background h-[75vh] overflow-y-auto">
            <h4 className="mb-6 text-xl text-black dark:text-white bg-brand-background sticky top-1">
                User Activity Timeline
            </h4>

            <div className='flex items-start justify-between my-3'>
                <h4 className="text-md text-black dark:text-white">
                    Active Hours
                </h4>
                <div className='flex items-center justify-center gap-x-4'>
                    <IoMdRefresh size={24} onClick={() => handleRefresh()} className='cursor-pointer hover:scale-110' />
                    {loginSection ? <MdExpandLess size={32} onClick={() => toggleLoginSection()} className='cursor-pointer hover:scale-110' /> : <MdExpandMore size={32} onClick={() => toggleLoginSection()} className='cursor-pointer hover:scale-110' />}

                </div>
            </div>

            {loginSection && (
                <div className="h-64 overflow-y-auto">
                    <table className="w-full table-auto">
                        <thead>
                            <tr className="text-white text-sm text-center border-b border-brand-stroke-color">
                                {Object.keys(columnWidths2).map((columnName) => (
                                    <th
                                        key={columnName}
                                        className="p-4 relative border-r border-brand-stroke-color font-medium"
                                        style={{ width: `${columnWidths2[columnName]}px` }}
                                    >
                                        {columnName}
                                        <div
                                            ref={resizeHandleRef}
                                            className="resize-handle absolute right-0 top-0 h-full w-2 cursor-col-resize"
                                            onMouseDown={(e) => onMouseDown(e, columnName)}
                                        />
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {loadingStatus ? (
                                <tr>
                                    <td colSpan="5" className="py-4 text-center">
                                        <CircularProgress />
                                    </td>
                                </tr>
                            ) : (
                                userStatuses.map((status, index) => {
                                    const isOffline = new Date(status.loggedOutAt) > new Date(status.loggedInAt);
                                    return (
                                        <tr key={status._id} className="hover:bg-gray-100 text-white text-xs text-center">
                                            <td className="py-4 px-1">
                                                {status.deviceid}
                                            </td>
                                            <td className="py-4 px-1">
                                                {status.device_model}
                                            </td>
                                            <td className="py-4 px-1">
                                                {dateTime.format(
                                                    new Date(Date.parse(status.loggedInAt)),
                                                    'DD/MM/YY HH:mm:ss'
                                                )}
                                            </td>
                                            <td className="py-4 px-1">
                                                {dateTime.format(
                                                    new Date(Date.parse(status.loggedOutAt)),
                                                    'DD/MM/YY HH:mm:ss'
                                                )}
                                            </td>
                                            <td className={`px-4 py-2 font-bold ${isOffline ? 'text-brand-red' : 'text-success'}`}>
                                                {isOffline ? 'Offline' : 'Active'}
                                            </td>
                                            <td
                                                className={`py-4 px-1 ${isOffline ? 'text-white text-opacity-40 cursor-not-allowed' : 'text-brand-red hover:scale-110 cursor-pointer'
                                                    }`}
                                            >
                                                <LuLogOut
                                                    size={24}
                                                    className="mx-auto"
                                                    onClick={() => {
                                                        if (!isOffline) {
                                                            handleLogout(status.refreshToken);
                                                        }
                                                    }}
                                                />
                                            </td>

                                        </tr>
                                    );
                                })
                            )}
                        </tbody>

                    </table>
                </div>
            )}


            <div>
                <div className='flex items-center justify-between mt-8'>

                    <h4 className="text-md text-black dark:text-white">
                        Subscriptions
                    </h4>

                    {/* <div className='w-fit rounded-lg bg-white text-black text-md py-2 px-4 cursor-pointer shadow-lg hover:scale-105'
                    onClick={() => setModalOpen(true)}>Add Subscription</div> */}

                    <div className='flex items-center justify-end my-3'>
                        <div className='flex items-center justify-center gap-x-4'>
                            <IoMdRefresh size={24} onClick={() => handleRefreshSubs()} className='cursor-pointer hover:scale-110' />
                            {subSection ? <MdExpandLess size={32} onClick={() => toggleSubSection()} className='cursor-pointer hover:scale-110' /> : <MdExpandMore size={32} onClick={() => toggleSubSection()} className='cursor-pointer hover:scale-110' />}

                        </div>
                    </div>

                </div>


                {subSection && (
                    <div className='h-64 overflow-y-auto'>
                        <table className="w-full table-auto">
                            <thead>
                                <tr className="text-white text-md text-center border-b border-brand-stroke-color">
                                    {Object.keys(columnWidths).map((columnName) => (
                                        <th
                                            key={columnName}
                                            className="p-4 relative border-r border-brand-stroke-color font-medium"
                                            style={{ width: `${columnWidths[columnName]}px` }}
                                        >
                                            {columnName}
                                            <div
                                                ref={resizeHandleRef}
                                                className="resize-handle absolute right-0 top-0 h-full w-2 cursor-col-resize"
                                                onMouseDown={(e) => onMouseDown(e, columnName)}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {loadingSubs ? (
                                    <tr>
                                        <td colSpan="5" className="py-4 text-center">
                                            <CircularProgress />
                                        </td>
                                    </tr>
                                ) : (
                                    subscriptions.map((subscription) => (
                                        <tr key={subscription._id} className='hover:bg-gray-100 text-white text-sm text-center'>
                                            <td className="py-4 px-1">
                                                {subscription.package_name}
                                            </td>
                                            <td className="py-4 px-1">
                                                {subscription.price}
                                            </td>
                                            <td className="py-4 px-1">
                                                {dateTime.format(
                                                    new Date(Date.parse(subscription.created_at)),
                                                    'DD/MM/YY HH:mm '
                                                )}
                                            </td>
                                            <td className="py-4 px-1">
                                                {dateTime.format(
                                                    new Date(Date.parse(subscription.expiry_date)),
                                                    'DD/MM/YY HH:mm '
                                                )}
                                            </td>
                                            <td className="py-4 px-1">
                                                {new Date(Date.parse(subscription.expiry_date)) > new Date() ? (
                                                    <span className="text-success">Active</span>
                                                ) : (
                                                    <span className="text-danger">Expired</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {modalOpen &&
                <SubscriptionModal />
            }

        </div>
    );
};

export default UserActivity;
