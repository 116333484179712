import React from 'react';
import ReactPlayer from 'react-player';

interface VideoPlayerProps {
  videoUrl: string; // Specify the type as string
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl }) => {
  return <ReactPlayer url={videoUrl} controls width="500px" height="300px" />;
};

export default VideoPlayer;




