import * as XLSX from 'xlsx';
import dateTime from 'date-and-time';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const ExportModal = ({ filteredPayments, setExportModal, setFilteredPayments }) => {
    const [activeTab, setActiveTab] = useState('All');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const [recordCount, setRecordCount] = useState('');
    const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });

    const [exportPayments, setExportPayments] = useState([]);
    const [packageOptions, setPackageOptions] = useState([]);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        const uniquePackages = [...new Set(filteredPayments.map((payment) => payment.product))];
        setPackageOptions(uniquePackages);
        setExportPayments(filteredPayments);
    }, [filteredPayments]);

    useEffect(() => {
        if (recordCount) {
            const limitedRecords = filteredPayments.slice(0, Number(recordCount));
            setExportPayments(limitedRecords);
        } else {
            setExportPayments(filteredPayments);
        }
    }, [recordCount, filteredPayments]);

    useEffect(() => {
        const filterByDateRange = () => {
            if (!dateRange.startDate && !dateRange.endDate) return filteredPayments;

            // Validate the date range
            const startDate = dateRange.startDate ? new Date(dateRange.startDate) : null;
            const endDate = dateRange.endDate ? new Date(dateRange.endDate) : null;

            if (startDate && endDate && startDate > endDate) {
                alert("Start date cannot be later than end date.");
                return []; // Or return filteredPayments, depending on your requirements
            }

            return filteredPayments.filter((payment) => {
                const paymentDate = new Date(payment.created_at);

                // Set hours for comparison
                if (startDate) {
                    startDate.setHours(0, 0, 0, 0);
                }
                if (endDate) {
                    endDate.setHours(23, 59, 59, 999);
                }

                return (
                    (!startDate || paymentDate >= startDate) &&
                    (!endDate || paymentDate <= endDate)
                );
            });
        };

        let updatedPayments = filterByDateRange();
        setExportPayments(updatedPayments);
    }, [dateRange, filteredPayments]);





    const options = [
        { value: 'COMPLETED', label: 'Completed' },
        { value: 'PENDING', label: 'Pending' },
        { value: 'FAILED', label: 'Failed' },
        { value: 'REJECTED', label: 'Rejected' },
        { value: 'CLOSED', label: 'Closed' }
    ]

    const packages = [
        ...packageOptions.map((product) => ({
            value: product,
            label: product,
        })),
    ];

    const handleChange = (option) => {
        setSelectedOption(option);
        const filtered = exportPayments.filter((payment) => payment.status === option.value);
        setExportPayments(filtered);
    };

    const handlePackageChange = (option) => {
        setSelectedPackage(option);
        const filtered = exportPayments.filter((payment) => payment.product === option.value);
        setExportPayments(filtered);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(exportPayments.map(payment => ({
            Date: dateTime.format(new Date(Date.parse(payment.created_at)), 'DD/MM/YY HH:mm'),
            Username: payment.username,
            UserID: payment.userid || payment.phone || payment.email,
            Reference: payment.payment_reference,
            Package: payment.product,
            Amount: `${payment.currency} ${payment.amount}`,
            Gateway: payment.payment_gateway,
            Operator: payment.mobile_operator,
            Status: payment.status,
            FailureReason: payment.failure_reason || "N/A",
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Payments");

        // Generate the current date and time for the file name
        const now = new Date();
        const formattedDate = dateTime.format(now, 'YYYY-MM-DD_HH-mm-ss');
        const fileName = `Payments_Summary_${formattedDate}.xlsx`;

        // Write the workbook to a file with the generated name
        XLSX.writeFile(workbook, fileName);
    };

    const exportToPDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(8); 
        
        // Define columns and data rows
        const columns = ["Date", "Username", "Package", "Amount", "Gateway", "Operator", "Status", "FailureReason"];
        const rows = exportPayments.map(payment => [
            dateTime.format(new Date(Date.parse(payment.created_at)), 'DD/MM/YY HH:mm'),
            payment.username,
            // payment.userid || payment.phone || payment.email,
            // payment.payment_reference,
            payment.product,
            `${payment.currency} ${payment.amount}`,
            payment.payment_gateway,
            payment.mobile_operator,
            payment.status,
            payment.failure_reason || "N/A",
        ]);

        // Add table to PDF
        doc.autoTable({
            head: [columns],
            body: rows,
            startY: 10,
            theme: 'striped'
        });

        // Generate filename with date and time
        const now = new Date();
        const formattedDate = dateTime.format(now, 'YYYY-MM-DD_HH-mm-ss');
        const fileName = `Payments_Summary_${formattedDate}.pdf`;

        // Save the PDF
        doc.save(fileName);
    };

    const handleExportClick = (exportType) => {
        if (exportType === 'pdf') {
            exportToPDF();
        } else if (exportType === 'excel') {
            exportToExcel();
        }
        setExportModal(false);
        setIsDropdownOpen(false); 
    };


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="flex flex-col gap-4 justify-center bg-boxdark p-6 rounded-lg text-white shadow-xl" style={{ width: '700px' }}>
                {/* Modal Header */}
                <div className="text-center text-2xl font-semibold mb-4">Payment Statement Export</div>

                <div className='flex gap-x-12 items-center'>

                    {/* Tabs */}
                    <div className="flex flex-col gap-y-8 px-2 border-r border-gray border-opacity-70">
                        {['All', 'Status', 'Records', 'Date', 'Package'].map((tab) => (
                            <button
                                key={tab}
                                onClick={() => setActiveTab(tab)}
                                className={`flex-1 py-2 px-2 text-center ${activeTab === tab ? 'font-bold bg-brand-red rounded-sm' : 'text-gray text-opacity-50'}`}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>

                    {/* Tab Content */}
                    <div className="mt-4 mx-auto">
                        {activeTab === 'All' && (
                            <div className='text-white mx-auto'>
                                Export All Payment Records
                            </div>

                        )}
                        {activeTab === 'Status' && (
                            <div className='text-black w-[50vh]'>
                                <label className="block mb-2 text-white">Select Status to Export:</label>
                                <Select
                                    value={selectedOption}
                                    onChange={handleChange}
                                    options={options}
                                    placeholder="Select Status..."
                                />
                            </div>
                        )}
                        {activeTab === 'Records' && (
                            <div className='w-[50vh]'>
                                <label className="block mb-2">Number of Records to Export:</label>
                                <input
                                    type="number"
                                    value={recordCount}
                                    onChange={(e) => setRecordCount(e.target.value)}
                                    className="w-full p-2 rounded bg-gray-700 text-black"
                                    placeholder="Enter number of records"
                                />
                            </div>
                        )}
                        {activeTab === 'Date' && (
                            <div className='w-[50vh]'>
                                <label className="block mb-2">Date Range:</label>
                                <div className="flex gap-4">
                                    <input
                                        type="date"
                                        value={dateRange.startDate}
                                        onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
                                        className="w-full p-2 rounded bg-gray-700 text-black"
                                    />
                                    <input
                                        type="date"
                                        value={dateRange.endDate}
                                        onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
                                        className="w-full p-2 rounded bg-gray-700 text-black"
                                    />
                                </div>
                            </div>
                        )}

                        {activeTab === 'Package' && (
                            <div className='text-black w-[50vh]'>
                                <label className="block mb-2 text-white">Select Package to Export:</label>


                                <Select
                                    value={selectedPackage}
                                    onChange={handlePackageChange}
                                    options={packages}
                                    placeholder="Select Package..."
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Export and Cancel Buttons */}
                <div className="flex justify-end gap-4 mt-6">
                    <button
                        onClick={() => setExportModal(false)}
                        className="px-4 py-2 rounded bg-danger hover:bg-red-700"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        className="px-4 py-2 rounded bg-success hover:bg-green-700"
                    >
                        Export
                    </button>

                    {isDropdownOpen && (
                        <div className="absolute right-120 mt-12 w-48 bg-white rounded-md shadow-lg z-10">
                            <button
                                onClick={() => handleExportClick('pdf')}
                                className="block w-full px-4 py-2 text-left text-black rounded-md hover:text-white hover:bg-black"
                            >
                                Export as PDF
                            </button>
                            <button
                                onClick={() => handleExportClick('excel')}
                                className="block w-full px-4 py-2 text-left text-black rounded-md hover:text-white hover:bg-black hover:scale-105"
                            >
                                Export as Excel
                            </button>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default ExportModal;
